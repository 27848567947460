import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

export const selectStudentsTableData = createSelector(
  [(state: RootState) => state.studentsTab.data],
  (data) => {
    if (data.status !== "success") {
      return data.status
    }

    return data.data
  },
)

export const selectSavedFilters = createSelector(
  [(state: RootState) => state.studentsTab.savedFilters],
  (filters) => filters,
)
