import { SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"

const DragIcon = ({
  onMouseEnter,
  onMouseLeave,
  size = 16,
}: {
  onMouseEnter: () => void
  onMouseLeave: () => void
  size?: number
}) => {
  return (
    <SvgIcon
      component={appIcons.dotsGrid}
      inheritViewBox
      sx={{
        fill: appTheme.palette.icon.black,
        width: `${size}px`,
        height: `${size}px`,
        cursor: "grab",
      }}
      onMouseEnter={() => {
        onMouseEnter()
      }}
      onMouseLeave={() => {
        onMouseLeave()
      }}
    />
  )
}

export default DragIcon
