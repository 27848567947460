type StaleCourseModel = {
  syllabusId: string
  unitId: string
  courseId: string
  currentVersion: string
  newVersion: string
}

export function compareStaleCoursesModels(
  model1: StaleCourseModel,
  model2: StaleCourseModel,
) {
  return (
    model1.syllabusId === model2.syllabusId &&
    model1.unitId === model2.unitId &&
    model1.courseId === model2.courseId &&
    model1.currentVersion === model2.currentVersion &&
    model1.newVersion === model2.newVersion
  )
}

export default StaleCourseModel
