import { FilterName, SearchAdvanceFilter } from "@features/search/searchSlice"
import { Box, Button, Chip, Popover, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { MainPageFilterContainer } from "../main/filter/filterContainer"
import SearchTextField from "./searchTextField"
import { domainToDisplayName } from "@utils/domainUtils"

export function SearchComponent(props: {
  onSearchTextChange: (text: string) => void
  searchText: string
  inputFilters: SearchAdvanceFilter[]
  onInputFiltersChange: (filters: SearchAdvanceFilter[]) => void
  searchPlaceholder?: string
  filterTitle?: string
  getOptionLabel?: (option: string) => string
  debounce?: boolean
}) {
  const {
    onSearchTextChange,
    searchText,
    inputFilters,
    onInputFiltersChange,
    searchPlaceholder,
    filterTitle,
    getOptionLabel,
    debounce,
  } = props

  const numberOfFilters = inputFilters
    .map((filter) => filter.selectedValues.length)
    .reduce((a, b) => a + b, 0)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const advanceFilterPopoverId = open ? "simple-popover" : undefined
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  function onAdvanceFilterApplyClicked(filters: SearchAdvanceFilter[]) {
    setAnchorEl(null)
    onInputFiltersChange(filters)
  }

  function onRemoveFilter(filterName: string, filterValue: string) {
    const newFilters = inputFilters.map((filter) => {
      if (filter.filterName === filterName) {
        return {
          ...filter,
          selectedValues: filter.selectedValues.filter(
            (value) => value !== filterValue,
          ),
        }
      }
      return filter
    })

    onInputFiltersChange(newFilters)
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        width="100%"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <SearchTextField
          searchText={searchText}
          onSearchTextChange={onSearchTextChange}
          placeholder={searchPlaceholder ?? "Search"}
          debounce={debounce === undefined ? true : debounce}
        />
        {inputFilters.length > 0 && (
          <Box marginLeft="8px">
            <Button
              onClick={handleClick}
              variant="text"
              style={{
                width: "fit-content",
              }}
            >
              <SvgIcon
                component={appIcons.funnel}
                inheritViewBox
                sx={{
                  fill: "none",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              />
              <Typography variant="body1">Filter</Typography>
              {numberOfFilters > 0 && (
                <Typography
                  marginLeft="4px"
                  variant="body1"
                >{`(${numberOfFilters})`}</Typography>
              )}
            </Button>
          </Box>
        )}
        <Popover
          id={advanceFilterPopoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{ marginTop: "4px" }}
        >
          <MainPageFilterContainer
            inputFilters={inputFilters}
            title={filterTitle ?? "Add filter"}
            onApplyClicked={onAdvanceFilterApplyClicked}
            onClose={() => setAnchorEl(null)}
            getOptionLabel={getOptionLabel}
          />
        </Popover>
      </Box>
      {inputFilters.flatMap((filter) => filter.selectedValues).length > 0 && (
        <Box display="flex" marginTop="16px" gap="4px">
          {inputFilters.flatMap((filter) =>
            filter.selectedValues.map((value) => {
              const filterName = filter.filterName
              let label = getOptionLabel ? getOptionLabel(value) : value
              if (filterName === FilterName.Domains) {
                label = domainToDisplayName(value)
              }
              return (
                <Chip
                  key={value}
                  onDelete={() => onRemoveFilter(filter.filterName, value)}
                  label={label}
                  color="info"
                  size="small"
                  sx={{
                    width: "fit-content",
                    "& .MuiChip-deleteIcon": {
                      margin: "0 4px",
                    },
                  }}
                />
              )
            }),
          )}
        </Box>
      )}
    </Box>
  )
}
