import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { CampusClient } from "@clients/campusClient"
import { FeatureFlag, FeatureFlagClient } from "@clients/featureFlagClient"
import { TutorClient, TutorDto } from "@clients/tutorClient"
import { UsersClient } from "@clients/usersClient"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { fetchLoggedInUserFeatureFlags, Role } from "@features/login/loginSlice"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { UserDto } from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"

type OffboardStuffPopup = {
  user: UserDto
  requestStatus: "idle" | "pending" | "error"
}

type EditRolesPopup = {
  user: UserDto
  selectedRoles: Role[]
  requestStatus: "idle" | "pending" | "error"
  tutorProperties?: TutorProperties
}

type OnboardStaffForm = {
  email: string
  firstName: string
  lastName: string
  country?: string
  photoUrl?: string
  additionalRoles?: Role[]
  tutorProperties?: TutorProperties
}

type EditUserPopup = {
  user: UserDto
  requestStatus: "idle" | "pending" | "error"
}

type EditFeatureFlagsPopup = {
  userId: string
  flags: FeatureFlag[]
}

export type OnboardStaffPopup = {
  form: OnboardStaffForm
  requestStatus: "idle" | "pending" | "error"
}

type AccountManagementState = {
  accounts: PendingDataState<UserDto[]>
  domainSubjects: PendingDataState<Record<string, string[]>>
  search: string
  offboardStaffPopup?: OffboardStuffPopup
  editRolesPopup?: EditRolesPopup
  onboardStaffPopup?: OnboardStaffPopup
  editUserPopup?: EditUserPopup
  editFeatureFlagsPopup?: EditFeatureFlagsPopup
  tutorsProperties: PendingDataState<Record<string, TutorProperties>>
}

const initialState: AccountManagementState = {
  accounts: {
    status: "idle",
  },
  domainSubjects: {
    status: "idle",
  },
  tutorsProperties: {
    status: "idle",
  },
  search: "",
}

type TutorProperties = TutorDto["properties"]

export const accountManagementSlice = createSlice({
  name: "accountManagement",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload
    },
    offboardStaffRequested: (
      state,
      action: PayloadAction<{ userId: string }>,
    ) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      state.offboardStaffPopup = {
        user: user,
        requestStatus: "idle",
      }
    },
    offboardStaffPopupClosed: (state) => {
      state.offboardStaffPopup = undefined
    },
    editRolesRequested: (state, action: PayloadAction<{ userId: string }>) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      const tutorProperties =
        state.tutorsProperties.status === "success" &&
        state.tutorsProperties.data[user.id]

      state.editRolesPopup = {
        user: user,
        selectedRoles: user.roles as Role[],
        requestStatus: "idle",
        tutorProperties: tutorProperties || undefined,
      }
    },
    editUserRequested: (state, action: PayloadAction<{ userId: string }>) => {
      if (state.accounts.status !== "success") {
        return
      }
      const user = state.accounts.data.find(
        (u) => u.id === action.payload.userId,
      )
      if (!user) {
        return
      }
      state.editUserPopup = {
        user: user,
        requestStatus: "idle",
      }
    },
    roleCheckboxToggled: (state, action: PayloadAction<{ role: Role }>) => {
      if (state.editRolesPopup === undefined) {
        return
      }
      if (state.editRolesPopup.selectedRoles.includes(action.payload.role)) {
        state.editRolesPopup.selectedRoles =
          state.editRolesPopup.selectedRoles.filter(
            (r) => r !== action.payload.role,
          )
      } else {
        state.editRolesPopup.selectedRoles = [
          ...state.editRolesPopup.selectedRoles,
          action.payload.role,
        ]
      }
    },
    editRolesPopupTutorProperties: (
      state,
      action: PayloadAction<{ tutorProperties: TutorProperties }>,
    ) => {
      if (state.editRolesPopup) {
        state.editRolesPopup.tutorProperties = action.payload.tutorProperties
      }
    },
    editRolesPopupClosed: (state) => {
      state.editRolesPopup = undefined
    },
    editFeatureFlagsPopupClosed: (state) => {
      state.editFeatureFlagsPopup = undefined
    },
    onboardStaffRequested: (state) => {
      state.onboardStaffPopup = {
        form: {
          email: "",
          firstName: "",
          lastName: "",
        },
        requestStatus: "idle",
      }
    },
    onboardStaffPopupClosed: (state) => {
      state.onboardStaffPopup = undefined
    },
    editUserPopupClosed: (state) => {
      state.editUserPopup = undefined
    },
    onboardStaffFormChanged: <K extends keyof OnboardStaffForm>(
      state: AccountManagementState,
      action: PayloadAction<{
        key: K
        value: OnboardStaffForm[K]
      }>,
    ) => {
      if (!state.onboardStaffPopup) {
        return
      }
      state.onboardStaffPopup.form[action.payload.key] = action.payload.value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersWithRoles.pending, (state, action) => {
        state.accounts = {
          status: "pending",
        }
      })
      .addCase(fetchUsersWithRoles.fulfilled, (state, action) => {
        state.accounts = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(fetchUsersWithRoles.rejected, (state, action) => {
        state.accounts = {
          status: "error",
          error: action.error.message,
        }
      })
      .addCase(fetchTutorsProperties.pending, (state) => {
        state.tutorsProperties.status = "pending"
      })
      .addCase(fetchTutorsProperties.fulfilled, (state, action) => {
        state.tutorsProperties = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(fetchTutorsProperties.rejected, (state, action) => {
        state.tutorsProperties = {
          status: "error",
          error: action.error.message,
        }
      })
      .addCase(editRolesPopupAccepted.pending, (state) => {
        if (state.editRolesPopup) {
          state.editRolesPopup.requestStatus = "pending"
        }
      })
      .addCase(editRolesPopupAccepted.fulfilled, (state) => {
        if (state.editRolesPopup && state.accounts.status === "success") {
          const editRolesPopup = state.editRolesPopup
          const rolesToRemove = state.editRolesPopup.user.roles.filter(
            (r) => !editRolesPopup.selectedRoles.includes(r as Role),
          )
          const rolesToAdd = state.editRolesPopup.selectedRoles.filter(
            (r) => !editRolesPopup.user.roles.includes(r),
          )
          state.accounts.data = state.accounts.data.map((u) =>
            u.id === state.editRolesPopup?.user.id
              ? {
                  ...u,
                  roles: u.roles
                    .concat(rolesToAdd)
                    .filter((r) => !rolesToRemove.includes(r)),
                }
              : u,
          )
        }
        if (
          state.editRolesPopup?.tutorProperties &&
          state.editRolesPopup.selectedRoles.includes(Role.Tutor) &&
          state.tutorsProperties.status === "success"
        ) {
          state.tutorsProperties.data = {
            ...state.tutorsProperties.data,
            [state.editRolesPopup.user.id]:
              state.editRolesPopup.tutorProperties,
          }
        }
        state.editRolesPopup = undefined
      })
      .addCase(editRolesPopupAccepted.rejected, (state) => {
        if (state.editRolesPopup) {
          state.editRolesPopup.requestStatus = "error"
        }
      })
      .addCase(onboardStaffFormAccepted.pending, (state) => {
        if (state.onboardStaffPopup) {
          state.onboardStaffPopup.requestStatus = "pending"
        }
      })
      .addCase(onboardStaffFormAccepted.fulfilled, (state) => {
        state.onboardStaffPopup = undefined
      })
      .addCase(onboardStaffFormAccepted.rejected, (state) => {
        if (state.onboardStaffPopup) {
          state.onboardStaffPopup.requestStatus = "error"
        }
      })
      .addCase(offboardStaffConfirmed.pending, (state) => {
        if (state.offboardStaffPopup) {
          state.offboardStaffPopup.requestStatus = "pending"
        }
      })
      .addCase(offboardStaffConfirmed.fulfilled, (state) => {
        state.offboardStaffPopup = undefined
      })
      .addCase(offboardStaffConfirmed.rejected, (state) => {
        state.offboardStaffPopup = undefined
      })
      .addCase(editFeatureFlagsPopupOpened.pending, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupOpened.fulfilled, (state, action) => {
        state.editFeatureFlagsPopup = action.payload
      })
      .addCase(editFeatureFlagsPopupOpened.rejected, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupAccepted.fulfilled, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(editFeatureFlagsPopupAccepted.rejected, (state) => {
        state.editFeatureFlagsPopup = undefined
      })
      .addCase(fetchDomainSubjects.pending, (state) => {
        state.domainSubjects.status = "pending"
      })
      .addCase(fetchDomainSubjects.fulfilled, (state, action) => {
        state.domainSubjects = {
          status: "success",
          data: action.payload,
        }
      })
      .addCase(fetchDomainSubjects.rejected, (state, action) => {
        state.domainSubjects = {
          status: "error",
          error: action.error.message,
        }
      })
  },
})

export const fetchUsersWithRoles = createAppAsyncThunk(
  "accountManagement/fetchMSEmployees",
  async () => {
    return UsersClient.getUsersWithRoles()
  },
)

export const fetchTutorsProperties = createAppAsyncThunk(
  "accountManagement/fetchTutorsProperties",
  async () => {
    return TutorClient.listTutors().then((tutors) => {
      const tutorsProperties: Record<string, TutorProperties> = {}
      tutors.forEach((tutor) => {
        tutorsProperties[tutor.userClientId] = tutor.properties
      })
      return tutorsProperties
    })
  },
)

export const fetchDomainSubjects = createAppAsyncThunk(
  "accountManagement/fetchDomainSubjects",
  async () => {
    return TutorClient.listDomainSubjects()
  },
)

export const editRolesPopupAccepted = createAppAsyncThunk(
  "accountManagement/editRoles",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.editRolesPopup
    if (!state) {
      return
    }

    const rolesToRemove = state.user.roles.filter(
      (r) => !state.selectedRoles.includes(r as Role),
    )
    const rolesToAdd = state.selectedRoles.filter(
      (r) => !state.user.roles.includes(r),
    )

    let tutorPropertiesRequest: Promise<TutorDto> | undefined
    const isTutorSelected = state.selectedRoles.includes(Role.Tutor)
    if (isTutorSelected && state.tutorProperties) {
      tutorPropertiesRequest = TutorClient.createOrUpdateTutorProperties(
        state.user.id,
        state.tutorProperties,
      )
    }

    const requests = [
      rolesToAdd.length > 0
        ? CampusClient.put(`user/employees/${state.user.id}/roles`, {
            roles: rolesToAdd,
          })
        : undefined,
      rolesToRemove.length > 0
        ? CampusClient.delete(`user/employees/${state.user.id}/roles`, {
            roles: rolesToRemove,
          })
        : undefined,
      tutorPropertiesRequest,
    ].msCompact()

    return Promise.all(requests)
  },
)

export const onboardStaffFormAccepted = createAppAsyncThunk(
  "accountManagement/onboardStaff",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.onboardStaffPopup
    if (!state) {
      return
    }

    return CampusClient.post(`add-masterschool-employee`, state.form).then(
      () => {
        thunkAPI.dispatch(fetchUsersWithRoles())
        thunkAPI.dispatch(fetchTutorsProperties())
      },
    )
  },
)

export const offboardStaffConfirmed = createAppAsyncThunk(
  "accountManagement/offboardStaff",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().accountManagement.offboardStaffPopup

    if (!state) {
      return
    }

    return CampusClient.delete(`user/employees/${state.user.id}/offboard`).then(
      () => {
        thunkAPI.dispatch(fetchUsersWithRoles())
      },
    )
  },
)

export const editFeatureFlagsPopupOpened = createAppAsyncThunk(
  "accountManagement/editFeatureFlagsPopupOpened",
  async (payload: { userId: string }, thunkAPI) => {
    const flags = await FeatureFlagClient.listUserFlags(payload.userId)
    return { userId: payload.userId, flags }
  },
)

export const editFeatureFlagsPopupAccepted = createAppAsyncThunk(
  "accountManagement/editFeatureFlagsPopupAccepted",
  async (
    payload: { userId: string; flags: { [key: string]: boolean } },
    thunkAPI,
  ) => {
    const dispatch = thunkAPI.dispatch
    const loggedInUser = selectLoggedInUser(thunkAPI.getState())
    return FeatureFlagClient.updateFlags(payload.userId, payload.flags)
      .then(() => {
        dispatch(showSuccessSnackbar("Feature flags updated successfully"))
        if (loggedInUser && loggedInUser.clientId === payload.userId) {
          dispatch(fetchLoggedInUserFeatureFlags())
        }
      })
      .catch(() =>
        dispatch(showErrorSnackbar("Failed to update feature flags")),
      )
  },
)

export const {
  setSearch,
  offboardStaffRequested,
  offboardStaffPopupClosed,
  editRolesRequested,
  editRolesPopupClosed,
  editRolesPopupTutorProperties,
  editUserRequested,
  editUserPopupClosed,
  editFeatureFlagsPopupClosed,
  roleCheckboxToggled,
  onboardStaffRequested,
  onboardStaffPopupClosed,
  onboardStaffFormChanged,
} = accountManagementSlice.actions

export default accountManagementSlice.reducer
