import { useAppDispatch, useAppSelector } from "@app/hooks"
import { SearchComponent } from "@cmp/searchComponent"
import { selectCoursesType } from "@features/coursesMenu/coursesSelectors"
import {
  courseAdvanceFilterUpdated,
  courseSearchTextUpdated,
  SearchAdvanceFilter,
  SearchComponentFilter,
  selectCoursesSearch,
} from "@features/search/searchSlice"
import { CourseType } from "@masterschool/course-builder-api"
import { ToggleButtonGroup, ToggleButton, Typography } from "@mui/material"
import { courseTypeSelected } from "@features/coursesMenu/coursesMenuSlice"
import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"
import appTheme from "../theme/appTheme"

export const CourseSearchComponent = () => {
  const dispatch = useAppDispatch()

  const searchFilters: SearchComponentFilter =
    useAppSelector(selectCoursesSearch)

  return (
    <SearchComponent
      onSearchTextChange={(text: string) =>
        dispatch(courseSearchTextUpdated(text))
      }
      searchText={searchFilters.text}
      inputFilters={searchFilters.advanceFilters}
      onInputFiltersChange={(advanceFilter: SearchAdvanceFilter[]) => {
        dispatch(courseAdvanceFilterUpdated(advanceFilter))
      }}
      searchPlaceholder="Search for a course name or description"
      filterTitle="Filter courses"
    />
  )
}

export const CourseTypeFilter = () => {
  const courseType = useAppSelector(selectCoursesType)
  const dispatch = useAppDispatch()
  const showTrackFeature = useAppSelector(selectShowTrackFeature)
  if (!showTrackFeature) {
    return null
  }

  const onTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    type: CourseType | null,
  ) => {
    if (!type) return
    dispatch(courseTypeSelected({ courseType: type }))
  }

  return (
    <ToggleButtonGroup
      value={courseType}
      exclusive
      onChange={onTypeChange}
      sx={{
        height: "32px",
      }}
    >
      <CourseTypeToggleButton
        value={CourseType.Track}
        isSelected={courseType === CourseType.Track}
      />
      <CourseTypeToggleButton
        value={CourseType.Legacy}
        isSelected={courseType === CourseType.Legacy}
      />
    </ToggleButtonGroup>
  )
}

const CourseTypeToggleButton = (props: {
  value: CourseType
  isSelected: boolean
}) => {
  return (
    <ToggleButton
      value={props.value}
      sx={{
        width: "128px",
        borderColor: appTheme.palette.other.outlineBorder,
        borderRadius: "2px",
        "&.Mui-selected": {
          backgroundColor: appTheme.palette.eTypes.sand50,
        },
      }}
    >
      <Typography
        variant={props.isSelected ? "body2_sb" : "body2"}
        color="text.primary"
      >
        {courseTypeLabel(props.value)}
      </Typography>
    </ToggleButton>
  )
}

const courseTypeLabel = (courseType: CourseType) => {
  switch (courseType) {
    case CourseType.Track:
      return "Track courses"
    case CourseType.Legacy:
      return "Legacy"
  }
}
