function formatTimestamp(timestamp: number): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(timestamp),
  )

  return formattedDate
}

export function minimalFormatTimestamp(timestamp: number): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(timestamp),
  )

  return formattedDate
}

export default formatTimestamp
