import { TrackDto } from "@masterschool/course-builder-api"
import { Stack } from "@mui/material"

import { selectLatestVersionsPublishedCourses } from "@features/coursesMenu/coursesSelectors"
import { useAppSelector } from "@app/hooks"
import DraggableList from "@cmp/draggableList"
import { useEditTrack } from "./useEditTrack"
import { AddButton } from "./AddButton"
import { TermEditCard } from "./termEditCard"

export const TrackContentEditor = (props: { initialTrack: TrackDto }) => {
  const { initialTrack } = props
  const {
    currentTrack,
    onAddTerm,
    onRemoveTerm,
    onReorderTerm,
    onChangeTermCourses,
  } = useEditTrack(initialTrack)

  const coursesUsedByTrack = currentTrack.terms.flatMap((term) =>
    term.courseDescriptors.map((cd) => cd.courseId),
  )
  const allCourses = useAppSelector(selectLatestVersionsPublishedCourses)
  const coursesNotUsedInTrack = (allCourses || []).filter(
    (course) => !coursesUsedByTrack.includes(course.id),
  )

  return (
    <Stack direction="column" width="600px" gap={2}>
      <DraggableList
        items={currentTrack.terms.map((term, index) => ({
          element: (
            <TermEditCard
              term={term}
              termNumber={index + 1}
              onRemoveTerm={() => onRemoveTerm(term.id)}
              courseCandidates={coursesNotUsedInTrack}
              onChangeTermCourses={(courseDescriptors) =>
                onChangeTermCourses(term.id, courseDescriptors)
              }
              key={term.id}
            />
          ),
          id: term.id,
        }))}
        onDragEnd={(result) => {
          const draggedElementId = result.active?.id
          const dragOverElementId = result.over?.id
          if (dragOverElementId) {
            const fromIndex = currentTrack.terms.findIndex(
              (term) => term.id === draggedElementId,
            )
            const toIndex = currentTrack.terms.findIndex(
              (term) => term.id === dragOverElementId,
            )

            onReorderTerm(fromIndex, toIndex)
          }
        }}
        id={"track-content-editor"}
        onDragStart={(_) => {}}
        onDragCancel={(_) => {}}
        onDragOver={(_) => {}}
      />

      <AddButton onClick={onAddTerm} sx={{ alignSelf: "flex-start" }}>
        {"Add Term"}
      </AddButton>
      <Stack sx={{ width: "100%", p: 4 }} />
    </Stack>
  )
}
