import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ProgramDomain } from "@masterschool/course-builder-api"

export type MultiProgramChangeDomainPopup = {
  newDomain: ProgramDomain | undefined
}

export type MultiProgramChangeScopePopup = {
  durationInMonths: number | undefined
}

export type MultiProgramChangeStartDatePopup = {
  startDate: string | undefined
}

export enum MultiProgramsPopupType {
  MultiProgramChangeDomain = "MultiProgramChangeDomain",
  MultiProgramChangeScope = "MultiProgramChangeScope",
  MultiProgramChangeStartDate = "MultiProgramChangeStartDate",
  MultiProgramDelete = "MultiProgramDelete",
}

export type PopupDataMap = {
  [MultiProgramsPopupType.MultiProgramChangeDomain]: MultiProgramChangeDomainPopup
  [MultiProgramsPopupType.MultiProgramChangeScope]: MultiProgramChangeScopePopup
  [MultiProgramsPopupType.MultiProgramChangeStartDate]: MultiProgramChangeStartDatePopup
  [MultiProgramsPopupType.MultiProgramDelete]: undefined
}

export type PresentedPopup =
  | {
      type: MultiProgramsPopupType.MultiProgramChangeDomain
      data: MultiProgramChangeDomainPopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramChangeScope
      data: MultiProgramChangeScopePopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramChangeStartDate
      data: MultiProgramChangeStartDatePopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramDelete
    }

export interface ProgramsMenuState {
  selectedProgramIds: string[]
  presentedPopup: PresentedPopup | undefined
}

const initialState: ProgramsMenuState = {
  selectedProgramIds: [],
  presentedPopup: undefined,
}

const programsMenuSlice = createSlice({
  name: "programsMenu",
  initialState,
  reducers: {
    selectedProgramsUpdated: (state, action: PayloadAction<string[]>) => {
      state.selectedProgramIds = action.payload
    },
    popupStateChanged: (
      state,
      action: PayloadAction<PresentedPopup | undefined>,
    ) => {
      state.presentedPopup = action.payload
    },
  },
})

export const { selectedProgramsUpdated, popupStateChanged } =
  programsMenuSlice.actions

export default programsMenuSlice.reducer
