import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  selectActiveCourse,
  useCanPublish,
} from "@features/courseEditor/courseEditorSelectors"
import { closedRequestedWithUnpublishedChanges } from "@features/courseEditor/courseEditorSlice"
import { Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { CourseEditorContainer } from "../../../courseEditor"
import { CourseEditorPopupHeader } from "../../../courseEditorHeader"

function CourseEditorPopup() {
  const { syllabusId, unitId, courseId } = useParams()
  const canPublish = useCanPublish()
  const course = useAppSelector(selectActiveCourse)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  async function onClose() {
    if (canPublish && (course?.version ?? "1.1") > "1.1") {
      dispatch(closedRequestedWithUnpublishedChanges())
      return
    }
    navigate({
      pathname: `/syllabus-edit/${syllabusId}`,
      search: `unitId=${unitId}`,
    })
  }

  return (
    <GenericDialog
      open={courseId !== undefined}
      size="lg"
      onClose={onClose}
      fullHeight
      disableContentPadding
      content={
        <Stack flex={1} overflow="hidden">
          {course && <CourseEditorPopupHeader course={course} />}
          <Stack
            overflow="auto"
            borderTop="1px solid"
            borderColor="other.divider"
            flex={1}
          >
            <CourseEditorContainer />
          </Stack>
        </Stack>
      }
    />
  )
}

export default CourseEditorPopup
