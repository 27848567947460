import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export const DialogCloseButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        padding: "4px",
        right: 16,
        top: 16,
        color: (theme) => theme.palette.grey[500],
      }}
      onClick={props.onClick}
    >
      <CloseIcon />
    </IconButton>
  )
}
