import { GridColDef } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { CSAT_COLUMNS } from "./csat.columns"
import { ACTIVITY_COLUMNS } from "./activity.columns"
import { ColumnKeys } from "./column.keys"
import { CONTACT_DETAILS_COLUMNS } from "./contact.details.columns"
import { REGISTRATION_COLUMNS } from "./registration.details.columns"

export const STUDENTS_TABLE_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = (
  [
    ...CONTACT_DETAILS_COLUMNS,
    ...CSAT_COLUMNS,
    ...ACTIVITY_COLUMNS,
    {
      field: ColumnKeys.LAST_INTERVIEW_SCORE,
      headerName: "Last Mock Interview Score",
      width: 150,
      valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
        return studentRow.status.proficiency.lastInterviewScore
      },
    },
    {
      field: ColumnKeys.MENTOR,
      headerName: "Mentor",
      width: 150,
      valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
        return studentRow.staff?.mentor?.name
      },
    },
    ...REGISTRATION_COLUMNS,
  ] as GridColDef<StudentAcademicStatusDTO>[]
).map((column) => ({ ...column, headerAlign: "left" }))
