import { CreateShiftRequest, ShiftDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useState } from "react"

const formatTime = (hour: number, minute: number) => {
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`
}

const times = Array.from({ length: 24 * 4 }, (_, i) => {
  const hour = Math.floor(i / 4)
  const minute = (i % 4) * 15
  return `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`
}).filter((time) => time <= "20:00" && time >= "09:00")

const availableTimezones = ["EU/Berlin"]

const dayToString = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday"
    case 1:
      return "Monday"
    case 2:
      return "Tuesday"
    case 3:
      return "Wednesday"
    case 4:
      return "Thursday"
    case 5:
      return "Friday"
    case 6:
      return "Saturday"
    default:
      return ""
  }
}

const stringDayToDayNumber = (day: string) => {
  switch (day) {
    case "Monday":
      return 1
    case "Tuesday":
      return 2
    case "Wednesday":
      return 3
    case "Thursday":
      return 4
    case "Friday":
      return 5
    case "Saturday":
      return 6
    case "Sunday":
      return 0
    default:
      throw new Error("Invalid day")
  }
}

const safeStringToNumber = (time: string | undefined) => {
  if (!time) {
    return undefined
  }

  return parseInt(time)
}

const safeInitialValuesFromInitialShift = (initialShift?: ShiftDto) => {
  return {
    name: initialShift?.name ?? "",
    days: initialShift?.days ?? [],
    startHour: safeStringToNumber(initialShift?.startTime.split(":")[0]) ?? 9,
    startMinute: safeStringToNumber(initialShift?.startTime.split(":")[1]) ?? 0,
    endHour: safeStringToNumber(initialShift?.endTime.split(":")[0]) ?? 17,
    endMinute: safeStringToNumber(initialShift?.endTime.split(":")[1]) ?? 0,
  }
}

export const CreateOrEditShiftForm = (props: {
  initialShift?: ShiftDto
  onSubmit: (createShiftRequest: CreateShiftRequest) => void
}) => {
  const { initialShift, onSubmit } = props
  const safeInitialValues = safeInitialValuesFromInitialShift(initialShift)
  const [timezone, setSelectedTimeZone] = useState("EU/Berlin")
  const [name, setName] = useState(safeInitialValues.name)
  const [days, setDays] = useState<number[]>(safeInitialValues.days)
  const [startHour, setStartHour] = useState(safeInitialValues.startHour)
  const [startMinute, setStartMinute] = useState(safeInitialValues.startMinute)
  const [endHour, setEndHour] = useState(safeInitialValues.endHour)
  const [endMinute, setEndMinute] = useState(safeInitialValues.endMinute)

  const setDaysProxy = (selectedDays: string[]) => {
    setDays(selectedDays.map((day) => stringDayToDayNumber(day)))
  }
  const insertNewShift = () => {
    const createShiftRequest = {
      startTime: formatTime(startHour, startMinute),
      endTime: formatTime(endHour, endMinute),
      timezone,
      days,
      name,
    }
    onSubmit(createShiftRequest)
    clearInsertForm()
  }

  const clearInsertForm = () => {
    setName("")
    setDays([])
    setStartHour(9)
    setStartMinute(0)
    setEndHour(17)
    setEndMinute(0)
  }
  return (
    <Box flex="1">
      <FormControl fullWidth>
        <TextField
          label="Shift name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="days-select-label">Days</InputLabel>
          <Select
            labelId="days-select-label"
            id="days-select"
            label="Days"
            multiple
            value={days.map((day) => dayToString(day))}
            onChange={(e) => setDaysProxy(e.target.value as string[])}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={4} margin="normal">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="start-time-label">Start Time</InputLabel>
              <Select
                labelId="start-time-label"
                id="start-time-select"
                value={formatTime(startHour, startMinute)}
                label="Start Time"
                onChange={(e) => {
                  const [hour, minute] = e.target.value.split(":")
                  setStartHour(Number(hour))
                  setStartMinute(Number(minute))
                  const endTime = formatTime(endHour, endMinute)
                  const startTime = formatTime(Number(hour), Number(minute))
                  if (endTime <= startTime) {
                    if (Number(hour) < 19) {
                      setEndHour(Number(hour) + 1)
                      setEndMinute(0)
                    } else {
                      setEndHour(20)
                      setEndMinute(0)
                    }
                  }
                }}
              >
                {times
                  .filter((time) => time !== "20:00")
                  .map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="end-time-label">End Time</InputLabel>
              <Select
                labelId="end-time-label"
                id="end-time-select"
                value={formatTime(endHour, endMinute)}
                label="End Time"
                onChange={(e) => {
                  const [hour, minute] = e.target.value.split(":")
                  setEndHour(Number(hour))
                  setEndMinute(Number(minute))
                }}
              >
                {times
                  .filter((endTime) => {
                    return endTime > formatTime(startHour, startMinute)
                  })
                  .map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <InputLabel id="timezone-select-label">Timezone</InputLabel>
          <Select
            labelId="timezone-select-label"
            id="timezone-select"
            value={timezone}
            label="Timezone"
            disabled={true}
            onChange={(e) => setSelectedTimeZone(e.target.value as string)}
          >
            {availableTimezones.map((timezone) => (
              <MenuItem key={timezone} value={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={
          !name || days.length === 0 || !startHour || !endHour || !timezone
        }
        onClick={insertNewShift}
        sx={{ marginTop: "16px" }}
      >
        {initialShift ? `Update shift` : `Create shift`}
      </Button>
    </Box>
  )
}
