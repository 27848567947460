import {
  PROGRAM_FILTERS_KEY,
  SYLLABUS_FILTERS_KEY,
  COURSE_FILTERS_KEY,
  SearchState,
} from "@features/search/searchSlice"
import AppObserver from "./appObserver"
import { STUDENTS_TAB_FILTERS_KEY } from "@features/students-tab/studentsTab.slice"

const localStorageObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    const prevFavoriteCourses = previousState.coursesMenu.favoriteCourses
    const nextFavoriteCourses = currentState.coursesMenu.favoriteCourses
    if (!isArrayEqual(prevFavoriteCourses, nextFavoriteCourses)) {
      localStorage.setItem(
        "favoriteCourses",
        JSON.stringify(nextFavoriteCourses),
      )
    }

    const searchState = {
      prev: previousState.search,
      curr: currentState.search,
    }
    handleFiltersUpdate(searchState, "courseFilters", COURSE_FILTERS_KEY)
    handleFiltersUpdate(searchState, "programFilters", PROGRAM_FILTERS_KEY)
    handleFiltersUpdate(searchState, "syllabusFilters", SYLLABUS_FILTERS_KEY)

    const previousCourseMenuType = previousState.coursesMenu.type
    const currentCourseMenuType = currentState.coursesMenu.type
    if (previousCourseMenuType !== currentCourseMenuType) {
      localStorage.setItem("courseMenuType", currentCourseMenuType)
    }

    const studentsTabState = {
      prev: previousState.studentsTab.savedFilters,
      curr: currentState.studentsTab.savedFilters,
    }
    if (!isArrayEqual(studentsTabState.prev, studentsTabState.curr)) {
      localStorage.setItem(
        STUDENTS_TAB_FILTERS_KEY,
        JSON.stringify(studentsTabState.curr),
      )
    }
  },
}

function handleFiltersUpdate(
  states: { prev: SearchState; curr: SearchState },
  filterType: keyof SearchState,
  storageKey: string,
) {
  const prevFilters = states.prev[filterType].advanceFilters
  const nextFilters = states.curr[filterType].advanceFilters
  if (!isArrayEqual(prevFilters, nextFilters)) {
    localStorage.setItem(storageKey, JSON.stringify(nextFilters))
  }
}

function isArrayEqual<T>(
  array1: T[] | undefined,
  array2: T[] | undefined,
): boolean {
  if (array1 === undefined && array2 === undefined) {
    return true
  } else if (array1 === undefined || array2 === undefined) {
    return false
  }

  return (
    array1?.length === array2?.length &&
    array1.every((value, index) => value === array2[index])
  )
}

export default localStorageObserver
