import { useAppSelector } from "@app/hooks"
import { CourseDescriptorDto } from "@masterschool/course-builder-api"
import { selectHasChanges } from "@features/coursesMenu/coursesSelectors"
import StatusChip from "./statusChip"
import { courseStatusTextAndColor } from "./courseStatus+Ui"

function CourseViewStatus(props: { course: CourseDescriptorDto }) {
  const { course } = props
  const hasChanges = useAppSelector(selectHasChanges(course.id))

  const statusTextAndColor = courseStatusTextAndColor(course.status)

  return (
    <StatusChip
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={hasChanges}
      isActive={undefined}
    />
  )
}

export default CourseViewStatus
