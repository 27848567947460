import { store } from "@app/store"
import { selectAccessToken } from "@features/login/loginSelectors"
import {
  Configuration,
  ConsultantAllocationsApi,
} from "@masterschool/course-builder-api"
export class ConsultantAllocationsClient {
  private static allocationsClient = new ConsultantAllocationsApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static listOnlineConsultants(consultantIds: string[]): Promise<string[]> {
    return ConsultantAllocationsClient.allocationsClient
      .consultantsAllocationsControllerListConsultantsWithActiveShift(
        { ids: consultantIds },
        this.makeHeaders(),
      )
      .then((response) => response.data)
  }
}
