import { useAppDispatch, useAppSelector } from "@app/hooks"
import { TutorDto } from "@clients/tutorClient"
import CustomTooltip from "@cmp/customTooltip"
import { fetchDomainSubjects } from "@features/account-management/accountManagementSlice"
import { ProgramDomain } from "@masterschool/course-builder-api"
import {
  CircularProgress,
  Stack,
  FormGroup,
  Box,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { useEffect } from "react"

function TutorFormFields(props: {
  tutorProperties: TutorDto["properties"] | undefined
  onChange: (value: TutorDto["properties"]) => void
}) {
  const dispatch = useAppDispatch()
  const domainSubjects = useAppSelector(
    (state) => state.accountManagement.domainSubjects,
  )
  const { tutorProperties: initialTutorProperties, onChange } = props
  const tutorProperties = initialTutorProperties || {
    domains: [],
    languages: [],
    supportedSubjects: [],
  }

  const handleDomainsChange = (values: string[]) => {
    onChange({
      ...tutorProperties,
      domains: values as ProgramDomain[],
      supportedSubjects:
        values.length === 0 ? [] : tutorProperties.supportedSubjects,
    })
  }

  const handleAutocompleteChange = <Key extends keyof TutorDto["properties"]>(
    key: Key,
    values: TutorDto["properties"][Key],
  ) => {
    onChange({
      ...tutorProperties,
      [key]: values,
    })
  }

  useEffect(() => {
    dispatch(fetchDomainSubjects())
  }, [dispatch])

  if (domainSubjects.status !== "success") {
    return <CircularProgress />
  }

  const domainOptions = Object.values(ProgramDomain)
  const supportedSubjects = listSupportedSubjects(
    initialTutorProperties?.domains || [],
    domainSubjects.data,
  )

  const blockSupportedSubjects = tutorProperties.domains.length === 0

  return (
    <Stack gap={2}>
      <FormGroup
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "16px",
        }}
      >
        <TutorFieldAutocomplete
          values={tutorProperties.languages}
          options={["English", "German"]}
          label="Languages"
          onChange={(values) => handleAutocompleteChange("languages", values)}
        />
        <TutorFieldAutocomplete
          values={tutorProperties.domains}
          options={domainOptions}
          label="Domains"
          onChange={handleDomainsChange}
          valueToLabel={domainToDisplayName}
        />
      </FormGroup>
      <FormGroup>
        <CustomTooltip
          title="Please select a domain first"
          disableTooltip={!blockSupportedSubjects}
          leaveDelay={0}
        >
          <Box>
            <TutorFieldAutocomplete
              values={tutorProperties.supportedSubjects}
              options={supportedSubjects}
              label="Supported subjects"
              isDisabled={blockSupportedSubjects}
              onChange={(values) =>
                handleAutocompleteChange("supportedSubjects", values)
              }
            />
          </Box>
        </CustomTooltip>
      </FormGroup>
    </Stack>
  )
}

function TutorFieldAutocomplete({
  values,
  options,
  label,
  isDisabled = false,
  onChange,
  valueToLabel = (value) => value,
}: {
  values: string[]
  options: string[]
  label: string
  isDisabled?: boolean
  onChange: (value: string[]) => void
  valueToLabel?: (value: string) => string
}) {
  return (
    <Autocomplete
      multiple
      disabled={isDisabled}
      options={options}
      value={values}
      onChange={(_, newValue) => onChange(newValue as string[])}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {valueToLabel(option)}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          return <Chip key={key} label={valueToLabel(option)} {...tagProps} />
        })
      }
    />
  )
}

function listSupportedSubjects(
  selectedDomains: ProgramDomain[],
  domainToSubjects: Record<string, string[]>,
) {
  return selectedDomains
    .reduce(
      (acc, domain) => acc.concat(domainToSubjects[domain] ?? []),
      [] as string[],
    )
    .msUnique()
}

export default TutorFormFields
