import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { useEffect, useState } from "react"
import { CourseClient } from "../../../../clients/courseClient"

function useCourseVersionHistory(courseId: string, majorVersion: number) {
  const [versionsHistory, setVersionsHistory] = useState<
    CourseDescriptorDto[] | "pending" | "rejected"
  >("pending")

  useEffect(() => {
    CourseClient.listCourseMinorVersions(courseId, majorVersion)
      .then((versions) => {
        const sorted = versions
          .sort((a, b) => parseFloat(b.version) - parseFloat(a.version))
          .filter((c) => c.status === CourseStatus.Published)
        setVersionsHistory(sorted)
      })
      .catch(() => {
        setVersionsHistory("rejected")
      })
  }, [courseId, majorVersion])

  return versionsHistory
}

export default useCourseVersionHistory
