import { useAppSelector } from "@app/hooks"
import { ProgramManagementCalendar } from "@clients/calendarHubClient"
import { selectPrograms } from "@features/program/programSliceSelectors"
import MsCalendar from "@masterschool/ui-calendar"
import { Stack } from "@mui/material"
import { useMemo, useState } from "react"
import { buildMsCalendarGroupingsAndFiltersForProgramOwner } from "./calendarMappers"
import { useEventActions } from "./useEventActions"
import {
  useGetCalendarById,
  useGetCalendarsForProgramOwner,
} from "./useGetCalendar"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { FetchEventSnackbar } from "./FetchEventSnackbar"

type DateRange = {
  start: Date
  end: Date
}

const CalendarContainer = () => {
  const calendarsForMenu = useGetCalendarsForProgramOwner()
  const [dateRange] = useState<DateRange>(defaultDateRange)

  return (
    <CalendarWithEvent
      calendarsForMenu={calendarsForMenu}
      dateRange={dateRange}
    />
  )
}

const CalendarWithEvent = ({
  calendarsForMenu,
  dateRange,
}: {
  calendarsForMenu: ProgramManagementCalendar
  dateRange: DateRange
}) => {
  const programs = useAppSelector(selectPrograms)
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.clientId

  const flatCalendarsForMenu = useMemo(
    () => [
      ...calendarsForMenu.programSchoolCalendars,
      ...calendarsForMenu.programStaffCalendars,
      ...calendarsForMenu.studentCalendars,
    ],
    [calendarsForMenu],
  )

  const { msEvents, onChange, onDelete, addCalendarIdsToFetch, loading } =
    useEventActions([], dateRange)

  const getCalendarById = useGetCalendarById(msEvents, flatCalendarsForMenu)

  const { groupings, customFilters } = useMemo(
    () =>
      buildMsCalendarGroupingsAndFiltersForProgramOwner(
        calendarsForMenu,
        programs,
      ),
    [calendarsForMenu, programs],
  )

  return (
    <Stack width={1} height={1} px={3} pt={8}>
      {loading && <FetchEventSnackbar />}
      <Stack flex={1} overflow="auto">
        <MsCalendar
          events={msEvents}
          calendars={groupings}
          customCalendarFilters={customFilters}
          getCalendar={getCalendarById}
          onEventChange={onChange}
          supportCreateEventTypes={["live-session", "regular"]}
          userId={userId ?? ""}
          onDeleteEvent={onDelete}
          showDraftEvents
          canJoinLiveSessions={false}
          onSelectedCalendarChange={(calendarIds: string[]) => {
            const calendarIdsExcludeDraft = calendarIds.filter(
              (c) => !c.includes("draft"),
            )
            addCalendarIdsToFetch(calendarIdsExcludeDraft)
          }}
        />
      </Stack>
    </Stack>
  )
}

export default CalendarContainer

function defaultDateRange(): DateRange {
  const start = new Date()
  start.setMonth(start.getMonth() - 6)
  const end = new Date()
  end.setMonth(end.getMonth() + 6)
  return { start, end }
}
