import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  createDraftForCourse,
  duplicateCourse,
} from "@features/coursesMenu/coursesMenuSlice"
import {
  selectDependentSyllabuses,
  selectLatestCourseVersion,
} from "@features/coursesMenu/coursesSelectors"
import { editCourseConfirmationPopupClosed } from "@features/syllabusEditor/syllabusEditorSlice"
import { CourseStatus } from "@masterschool/course-builder-api"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"

function EditCourseFromSyllabusConfirmationPopup() {
  const dispatch = useAppDispatch()
  const popupState = useAppSelector(
    (state) => state.syllabusEditor.editCourseConfirmationPopup,
  )
  const dependentSyllabuses = useAppSelector(
    selectDependentSyllabuses(popupState?.courseId),
  )
  const navigate = useNavigate()
  const course = useAppSelector(selectLatestCourseVersion(popupState?.courseId))
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  if (!popupState || !course) {
    return null
  }

  return (
    <GenericDialog
      open
      size="xs"
      onClose={() => dispatch(editCourseConfirmationPopupClosed())}
      title="Edit this course?"
      subtitle={`The course is published and appears in ${dependentSyllabuses?.length} syllabuses.`}
      buttons={[
        {
          type: "secondary",
          text: "Duplicate course",
          onClick: () => {
            setIsDuplicating(true)
            dispatch(
              duplicateCourse({ courseId: course.id, version: course.version }),
            )
              .then(unwrapResult)
              .then(({ course: duplicatedCourse }) => {
                dispatch(editCourseConfirmationPopupClosed())
                navigate({
                  pathname: `${popupState.unitId}/${duplicatedCourse.id}`,
                  search: createSearchParams({
                    duplicateOriginalCourseId: course.id,
                  }).toString(),
                })
                setIsDuplicating(false)
              })
          },
          loading: isDuplicating,
        },
        {
          type: "primary",
          text: "Edit course",
          onClick: () => {
            setIsEditing(true)
            if (!course || course.status === CourseStatus.Archived) {
              setIsEditing(false)
              return
            }

            if (course.status === CourseStatus.Published) {
              dispatch(createDraftForCourse({ courseId: course.id }))
                .then(unwrapResult)
                .then((course) => {
                  setIsEditing(false)
                  if (!course) {
                    return
                  }
                  dispatch(editCourseConfirmationPopupClosed())
                  navigate(`${popupState.unitId}/${course.id}`)
                })
            } else {
              setIsEditing(false)
              dispatch(editCourseConfirmationPopupClosed())
              navigate(`${popupState.unitId}/${course.id}`)
            }
          },
          loading: isEditing,
        },
      ]}
    />
  )
}

export default EditCourseFromSyllabusConfirmationPopup
