import { RootState } from "@app/store"
import { CourseDto } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { StringCourseDtoFields, isCourse } from "./courseEditorUtils"
import { useAppSelector } from "@app/hooks"
import {
  courseValidationForPublish,
  PublishValidationStatus,
} from "./courseValidations"

const selectSteps = (state: RootState) => state.courseEditor.steps
const selectCurrentStep = (state: RootState) => state.courseEditor.currentStep

export const selectCanUndo = (state: RootState) =>
  state.courseEditor.currentStep > 0

export const selectCanRedo = (state: RootState) =>
  state.courseEditor.currentStep < state.courseEditor.steps.length - 1

export const selectActiveCourse = createSelector(
  [selectSteps, selectCurrentStep],
  (steps, currentStep) => {
    if (currentStep < 0 || currentStep >= steps.length) {
      return undefined
    }
    return steps[currentStep].value
  },
)

export const selectOriginalCourse = createSelector([selectSteps], (steps) => {
  if (steps.length === 0) {
    return undefined
  }
  return steps[0].value
})

export const selectCurrentStepHasChanges = createSelector(
  [selectOriginalCourse, selectActiveCourse],
  (originalCourse, activeCourse) => {
    if (!originalCourse || !activeCourse) {
      return false
    }
    return JSON.stringify(originalCourse) !== JSON.stringify(activeCourse)
  },
)

export const selectDraggedId = (state: RootState) =>
  state.courseEditor.draggedId

export const selectDraggedTaskId = (state: RootState) =>
  state.courseEditor.draggedTaskId

export const selectCourseGeneralInfoEditedFields = (state: RootState) =>
  state.courseEditor.generalInfoEditedFields

export const selectShowCourseInvalidErrorForField = (
  field: StringCourseDtoFields,
) =>
  createSelector(
    [selectActiveCourse, selectCourseGeneralInfoEditedFields],
    (course, courseGeneralInfoEditedFields) => {
      if (!isCourse(course) || !field) {
        return false
      }
      const value = course[field]
      const didEditField = courseGeneralInfoEditedFields[field]
      return didEditField && !value
    },
  )

export const selectCanPreview = createSelector([selectActiveCourse], (course) =>
  canPreviewCourse(course),
)

function canPreviewCourse(course: CourseDto | undefined) {
  if (!course) {
    return false
  }
  if (!!!course.name || !!!course.title || course.domains.length === 0) {
    return false
  }
  const topics = course.syllabus?.topics
  if (!topics || topics.length === 0) {
    return false
  }

  return topics.some((topic) => topic.elements.length > 0)
}

export const selectLastPublishedVersion = (state: RootState) =>
  state.courseEditor.lastPublishedVersion

export function useCanPublish() {
  const draft = useAppSelector(selectActiveCourse)
  const published = useAppSelector(selectLastPublishedVersion)
  const canPublish =
    courseValidationForPublish(draft, published ? published : undefined) ===
    PublishValidationStatus.PUBLISHABLE

  return canPublish
}

export const selectIsTopicOpen = (topicId: string) => (state: RootState) =>
  (state.courseEditor.topicsOpenState[topicId] ?? false) &&
  state.courseEditor.draggedId !== topicId
