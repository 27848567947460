import { DataGridPro, GridApiPro, useGridApiRef } from "@mui/x-data-grid-pro"
import { Box, Button, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { createProgramPopupOpened } from "@features/program/programSlice"
import { ProgramDto } from "@masterschool/course-builder-api"
import appTheme from "../../theme/appTheme"
import CreateProgramPopup from "./createProgramPopup"
import DeleteProgramPopup from "./deleteProgramPopup"
import EditProgramPopup from "./editProgramPopup"
import { ProgramSearchComponent } from "../program/components/programSearchComponent"
import { selectMainPagePrograms } from "@features/search/searchSlice"
import { programsMenuColumns } from "./programsMenuColumns"
import { ExpandedRowContent } from "./programsMenuCells"
import MultipleSelectedProgramsSnackbar from "./multipleSelectionSnackbar"
import MultiProgramPopups from "./multiProgramPopups"
import { selectedProgramsUpdated } from "@features/program/programsMenuSlice"

export type ProgramDisplayData = ProgramDto & {
  scope: ProgramScopeData
}

type ProgramScopeData = {
  type: string
  language: string
}

function ProgramsMenu() {
  const dispatch = useAppDispatch()
  const programs = useAppSelector(selectMainPagePrograms)
  const programsWithScope = mapProgramScope(programs)
  const gridApiRef = useGridApiRef()

  const unSelectRows = () => {
    if (gridApiRef.current) gridApiRef.current.setRowSelectionModel([])
  }

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingX: "24px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1140px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "80px",
          mb: "64px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h4">Programs</Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(createProgramPopupOpened())}
          >
            Add program
          </Button>
        </Box>
        <ProgramSearchComponent />
        <ProgramsTable data={programsWithScope} gridApiRef={gridApiRef} />
      </Box>
      <CreateProgramPopup />
      <DeleteProgramPopup />
      <EditProgramPopup />
      <MultipleSelectedProgramsSnackbar />
      <MultiProgramPopups onClose={unSelectRows} />
    </Box>
  )
}

function mapProgramScope(programs: ProgramDto[]): ProgramDisplayData[] {
  return programs.map((program) => ({
    ...program,
    scope: {
      type: program.type,
      language: program.language,
    },
  }))
}

function ProgramsTable({
  data,
  gridApiRef,
}: {
  data: ProgramDisplayData[]
  gridApiRef: React.MutableRefObject<GridApiPro>
}) {
  const dispatch = useAppDispatch()

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <DataGridPro
        apiRef={gridApiRef}
        rows={data}
        columns={programsMenuColumns}
        disableColumnMenu
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnReorder
        getRowId={(row) => row.id}
        onRowSelectionModelChange={(newSelection) => {
          const stringIds = newSelection.filter(
            (id) => typeof id === "string",
          ) as string[]
          dispatch(selectedProgramsUpdated(stringIds))
        }}
        getDetailPanelContent={(params) => (
          <ExpandedRowContent program={params.row} />
        )}
        getDetailPanelHeight={() => "auto"}
        getRowHeight={() => "auto"}
        initialState={{
          sorting: {
            sortModel: [{ field: "startDate", sort: "desc" }],
          },
        }}
        sx={{
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-checkboxInput": {
            p: "0px !important",
          },
          "& .MuiSvgIcon-root": {
            width: "16px !important",
            height: "16px !important",
          },
          "& .expand-cell": {
            width: "32px !important",
          },
          "& .domain-header": {
            width: "216px !important",
          },
          "& .expand-header": {
            width: "6px !important",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "0px",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

export default ProgramsMenu
