import { showSuccessSnackbar, SnackbarDuration } from "@features/ui/uiSlice"
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import {
  GridFilterModel,
  gridFilterModelSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react"
import appTheme from "../../../../theme/appTheme"
import appIcons from "@utils/appIcons"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  removeFilter,
  saveFilter,
} from "@features/students-tab/studentsTab.slice"
import { selectSavedFilters } from "@features/students-tab/studentsTab.selectors"

export const StudentTableToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "8px",
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <SaveMyFilter />
      <SelectMyFilter />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const SaveMyFilter = () => {
  const gridApiRef = useGridApiContext()
  const dispatch = useAppDispatch()
  const currentFilterModel = gridFilterModelSelector(gridApiRef)
  const [openDialog, setOpenDialog] = useState(false)
  const [filterName, setFilterName] = useState("")
  const savedFilters = useAppSelector(selectSavedFilters)
  const nameAlreadyInUse = savedFilters.some((f) => f.name === filterName)

  const handleSave = () => {
    if (filterName.trim()) {
      dispatch(saveFilter({ name: filterName, model: currentFilterModel }))
      dispatch(showSuccessSnackbar("Filter saved", SnackbarDuration.short))
      setOpenDialog(false)
      setFilterName("")
    }
  }

  return (
    <Box
      sx={{
        opacity: currentFilterModel.items.length ? 1 : 0,
        transition: "opacity 0.3s",
      }}
    >
      <Button
        onClick={() => setOpenDialog(true)}
        size="small"
        disabled={!currentFilterModel.items.length}
        startIcon={
          <SvgIcon
            component={appIcons.save}
            sx={{ fill: "none", stroke: appTheme.palette.icon.black }}
          />
        }
      >
        Save filter
      </Button>

      <GenericDialog
        open={openDialog}
        size="xs"
        onClose={() => setOpenDialog(false)}
        title={`Save filter`}
        content={
          <TextField
            autoFocus
            margin="dense"
            label="Filter name"
            fullWidth
            value={filterName}
            error={nameAlreadyInUse}
            helperText={
              nameAlreadyInUse ? "Filter with this name already exists" : ""
            }
            FormHelperTextProps={{ sx: { textTransform: "none" } }}
            onChange={(e) => setFilterName(e.target.value)}
          />
        }
        buttons={[
          {
            text: "Cancel",
            type: "secondary",
            onClick: () => setOpenDialog(false),
          },
          {
            text: "Save",
            type: "primary",
            disabled: !filterName.trim() || nameAlreadyInUse,
            onClick: handleSave,
          },
        ]}
      />
    </Box>
  )
}

const SelectMyFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState<
    { name: string; model: GridFilterModel } | undefined
  >(undefined)
  const savedFilters = useAppSelector(selectSavedFilters)
  const gridApiRef = useGridApiContext()
  const currentFilterModel = gridFilterModelSelector(gridApiRef)
  const dispatch = useAppDispatch()

  const onSelected = (filterName: string) => {
    const filter = savedFilters.find((f) => f.name === filterName)
    if (!filter) return
    setSelectedFilter(filter)
    gridApiRef.current?.setFilterModel(filter.model)
  }

  useEffect(() => {
    if (currentFilterModel !== selectedFilter?.model) {
      setSelectedFilter(undefined)
    }
  }, [currentFilterModel, selectedFilter])

  if (!savedFilters.length) return null

  return (
    <FormControl
      variant="standard"
      sx={{ minWidth: 160, alignContent: "center" }}
    >
      <Select
        displayEmpty
        value={selectedFilter?.name ?? ""}
        onChange={(e) => onSelected(e.target.value)}
        sx={{ px: 1 }}
        renderValue={(value) => (value ? value : "My filters")}
      >
        {savedFilters.map((filter) => (
          <MenuItem key={filter.name} value={filter.name}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              gap="8px"
            >
              <Typography>{filter.name}</Typography>
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(removeFilter(filter))
                }}
                sx={{
                  p: 1,
                  ":hover": { backgroundColor: appTheme.palette.grey[300] },
                }}
              >
                <SvgIcon
                  component={appIcons.trash03}
                  inheritViewBox
                  sx={{ stroke: appTheme.palette.error.main, fill: "none" }}
                />
              </Button>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
