import { CourseDescriptorDto } from "@masterschool/course-builder-api"
import { Box, Chip, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import formatTimestamp from "@utils/formatTimestamp"
import appTheme from "../../../../theme/appTheme"

function VersionButton(props: {
  course: CourseDescriptorDto
  isSelected: boolean
  onClick: () => void
  tag?: "published" | "inUse"
}) {
  const { course, isSelected, onClick, tag } = props

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        padding: "24px",
        alignItems: "center",
        width: "100%",
        gap: "24px",
        bgcolor: isSelected ? appTheme.palette.action.selected : undefined,
        ":hover": {
          bgcolor: appTheme.palette.action.hover,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "8px",
        }}
      >
        {tag && (
          <Chip
            label={(() => {
              switch (tag) {
                case "published":
                  return "Published version"
                case "inUse":
                  return "Version in use"
              }
            })()}
            sx={{
              width: "fit-content",
            }}
            size="small"
            variant={(() => {
              switch (tag) {
                case "published":
                  return "filled"
                case "inUse":
                  return "outlined"
              }
            })()}
          />
        )}
        <Box>
          <Typography variant="body2">
            {formatTimestamp(course.createdAtTimestamp)}
          </Typography>
          <Typography
            variant="body3"
            color={appTheme.palette.text.secondary}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            By user {course.createdBy}
          </Typography>
        </Box>
      </Box>
      <SvgIcon
        component={appIcons.chevronDown}
        transform="rotate(270)"
        inheritViewBox
        sx={{
          fill: appTheme.palette.icon.black,
          width: "20px",
          height: "20px",
        }}
      />
    </Box>
  )
}

export default VersionButton
