import { ProgramDomain } from "@masterschool/course-builder-api"
import { domainToColor, domainToDisplayName } from "./domainUtils"
import { Chip } from "@mui/material"

export const DomainChip = ({ domain }: { domain: string }) => {
  return (
    <Chip
      label={domainToDisplayName(domain)}
      size="small"
      sx={{
        backgroundColor: domainToColor(domain as ProgramDomain),
      }}
    />
  )
}
