import { Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

function BreadcrumbLink({
  title,
  color,
  underline,
  navigationPath,
}: {
  title: string
  color: string
  underline: "hover" | "none"
  navigationPath?: string
}) {
  if (navigationPath !== undefined) {
    return (
      <Link
        component={RouterLink}
        to={navigationPath}
        variant="body3"
        underline={underline}
        sx={{
          color: color,
          paddingTop: "0px",
          cursor: underline === "hover" ? "pointer" : "default",
          textTransform: "capitalize",
        }}
      >
        {title}
      </Link>
    )
  }
  return (
    <Link
      variant="body3"
      underline={underline}
      sx={{
        color: color,
        paddingTop: "0px",
        cursor: underline === "hover" ? "pointer" : "default",
        textTransform: "capitalize",
      }}
    >
      {title}
    </Link>
  )
}

export default BreadcrumbLink
