import { ButtonProps, Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"

export const AddButton = (props: ButtonProps) => {
  return (
    <Button
      variant="text"
      startIcon={
        <SvgIcon
          component={appIcons.plusCircle}
          sx={{ fill: "none", stroke: appTheme.palette.icon.black }}
          inheritViewBox
        />
      }
      {...props}
    />
  )
}
