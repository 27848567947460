import {
  TermDto,
  TermDtoLockMethodEnum,
  TrackDto,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { useAutoSaveTrack } from "./useAutoSaveTrack"

export const useEditTrack = (initialTrack: TrackDto) => {
  const { editedTrack, onTrackChange } = useAutoSaveTrack()
  const currentTrack: TrackDto = editedTrack || initialTrack

  const onAddTerm = () => {
    const emptyTerm: TermDto = {
      id: window.crypto.randomUUID(),
      courseDescriptors: [],
      name: "",
      lockMethod: TermDtoLockMethodEnum.ByDate,
    }
    const newTermsList = [...currentTrack.terms, emptyTerm]
    onTrackChange({
      ...currentTrack,
      terms: newTermsList,
    })
  }

  const onRemoveTerm = (termId: string) => {
    const newTerms = currentTrack.terms.filter((term) => term.id !== termId)
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  const onReorderTerm = (fromIndex: number, toIndex: number) => {
    const newTerms = [...currentTrack.terms]
    const [removedTerm] = newTerms.splice(fromIndex, 1)
    newTerms.splice(toIndex, 0, removedTerm)
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  const onChangeTermCourses = (
    termId: string,
    courseDescriptors: UnitCourseDescriptor[],
  ) => {
    const newTerms = currentTrack.terms.map((term) => {
      if (term.id === termId) {
        return { ...term, courseDescriptors }
      }
      return term
    })
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  return {
    currentTrack,
    onAddTerm,
    onRemoveTerm,
    onReorderTerm,
    onChangeTermCourses,
  }
}
