import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { RootState } from "@app/store"
import { CourseClient } from "@clients/courseClient"
import { SyllabusClient } from "@clients/syllabusClient"
import { arrayMove } from "@dnd-kit/sortable"
import {
  CourseDto,
  SprintDto,
  SyllabusDto,
  SyllabusDtoStatusEnum,
  Topic,
  UnitCourseDescriptor,
  UnitDtoLockMethodEnum,
} from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  selectEditedUnitCourses,
  selectTopicsInEditedUnit,
  selectTopicsInSprint,
  selectUnitByEditedCourseId,
} from "../../editor/syllabus/units/content-editor/unit.editor.selectors"
import { SPRINTS_TIMELINE_HEADER_ID } from "../../editor/syllabus/units/content-editor/unitSprintsTimeline"
import StaleCourseModel from "../../editor/syllabus/validations/staleCoursesPopup/staleCourseModel"
import { publishCourse } from "../courseEditor/courseEditorSlice"
import { SyllabusEditorState } from "./syllabusEditorState"
import { unitAccessConditionEdited } from "./syllabusEditorAdditionalReducers"
import { getAdjustedSprints } from "./sprintsAdjuster"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { ResourceLockingClient } from "@clients/resourceLockingClient"
import { fetchSyllabuses } from "@features/syllabus/syllabusesMenuSlice"
import { selectSyllabus } from "@features/syllabus/syllabusSelectors"

const initialState: SyllabusEditorState = {
  syllabus: undefined,
  coursesSelectionPopup: undefined,
  draggedUnitId: undefined,
  lastSavedSyllabus: undefined,
  editCourseConfirmationPopup: undefined,
  reviewStaleCoursesPopup: undefined,
}

export const syllabusEditorSlice = createSlice({
  name: "syllabusEditorSlice",
  initialState: initialState,
  reducers: {
    syllabusNameUpdated: (state, action: PayloadAction<{ name: string }>) => {
      if (!state.syllabus) {
        return
      }
      state.syllabus.name = action.payload.name
    },
    unitAdded: (state, action: PayloadAction<{ id: string }>) => {
      const id = action.payload.id
      state.syllabus?.units.push({
        id,
        name: "",
        courseDescriptors: [],
        sprints: [],
        lockMethod: UnitDtoLockMethodEnum.ByDate,
      })
    },
    unitEdited: (
      state,
      action: PayloadAction<{
        unitId: string
        key: "name" | "shortTitle" | "unitNumber" | "description"
        value: string
      }>,
    ) => {
      if (!state.syllabus) {
        return
      }
      const unit = state.syllabus.units.find(
        (unit) => unit.id === action.payload.unitId,
      )
      if (!unit) {
        return
      }

      if (action.payload.key === "unitNumber") {
        unit.unitNumber = parseInt(action.payload.value)
        return
      } else {
        unit[action.payload.key] = action.payload.value
      }
    },
    unitSprintsEdited: (
      state,
      action: PayloadAction<{
        unitId: string
        sprints: SprintDto[]
      }>,
    ) => {
      const unit = state.syllabus?.units.find(
        (unit) => unit.id === action.payload.unitId,
      )
      if (!unit) return

      unit.sprints = action.payload.sprints
    },
    unitAccessConditionEdited,
    syllabusUnitRemoved: (state, action: PayloadAction<{ unitId: string }>) => {
      if (!state.syllabus) {
        return
      }
      state.syllabus.units = state.syllabus?.units.filter(
        (unit) => unit.id !== action.payload.unitId,
      )
    },
    sprintMetadataEdited: (
      state,
      action: PayloadAction<{
        id: string
        metadata: Partial<Omit<SprintDto, "id" | "lastTopicId">>
      }>,
    ) => {
      const { id, metadata } = action.payload
      const sprint = state.syllabus?.units
        .flatMap((u) => u.sprints)
        .find((s) => s.id === id)
      if (!sprint) return

      Object.assign(sprint, metadata)
    },
    dragUnitStarted: (state, action: PayloadAction<{ id: string }>) => {
      state.draggedUnitId = action.payload.id
    },
    dragUnitEnded: (state) => {
      state.draggedUnitId = undefined
    },
    unitDraggedOver: (
      state,
      action: PayloadAction<{
        sourceIndex: number | undefined
        targetIndex: number | undefined
      }>,
    ) => {
      if (
        !state.syllabus ||
        action.payload.sourceIndex === undefined ||
        action.payload.targetIndex === undefined
      ) {
        return
      }
      state.syllabus.units = arrayMove(
        state.syllabus.units,
        action.payload.sourceIndex,
        action.payload.targetIndex,
      )
    },
    domainChanged: (state, action: PayloadAction<string>) => {
      if (!state.syllabus) {
        return
      }
      state.syllabus.domain = action.payload
    },
    editUnitCoursesClicked: (
      state,
      action: PayloadAction<{ unitId: string }>,
    ) => {
      if (!state.syllabus) {
        return
      }

      state.coursesSelectionPopup = {
        unitId: action.payload.unitId,
      }
    },
    editUnitCoursesPopupClosed: (state) => {
      state.coursesSelectionPopup = undefined
    },
    courseRemovedFromUnit: (
      state,
      action: PayloadAction<{ unitId: string; courseId: string }>,
    ) => {
      if (!state.syllabus) return

      const { unitId, courseId } = action.payload

      state.syllabus.units = state.syllabus.units.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            courseDescriptors: unit.courseDescriptors.filter(
              (descriptor) => descriptor.courseId !== courseId,
            ),
          }
        }
        return unit
      })
    },
    editCourseClicked: (
      state,
      action: PayloadAction<{ unitId: string; courseId: string }>,
    ) => {
      state.editCourseConfirmationPopup = {
        unitId: action.payload.unitId,
        courseId: action.payload.courseId,
      }
    },
    editCourseConfirmationPopupClosed: (state) => {
      state.editCourseConfirmationPopup = undefined
    },
    reviewStaleCoursesRequested: (
      state,
      action: PayloadAction<{ staleCoursesIds: string[] }>,
    ) => {
      if (
        state.reviewStaleCoursesPopup ||
        action.payload.staleCoursesIds.length === 0
      ) {
        return
      }

      state.reviewStaleCoursesPopup = {
        statuses: action.payload.staleCoursesIds.reduce(
          (acc, courseId) => ({
            ...acc,
            [courseId]: "review",
          }),
          {},
        ),
      }
    },
    reviewUpdatedCoursesClosed: (state) => {
      state.reviewStaleCoursesPopup = undefined
    },
    unmounted: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSyllabus.fulfilled, (state, action) => {
      state.syllabus = action.payload
    })
    builder.addCase(saveSyllabus.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.syllabus) return
      state.lastSavedSyllabus = action.payload.syllabus
      state.syllabus = action.payload.syllabus
    })
    builder.addCase(publishSyllabus.fulfilled, (state, action) => {
      if (!action.payload) return
      state.syllabus = action.payload
    })
    builder.addCase(createNewDraftVersion.fulfilled, (state, action) => {
      if (!action.payload) return

      state.syllabus = action.payload
    })
    builder.addCase(editUnitCourses.fulfilled, (state, action) => {
      state.coursesSelectionPopup = undefined
      if (!action.payload || !state.syllabus) return

      const updatedUnits = action.payload
      state.syllabus.units = updatedUnits
    })
    builder.addCase(editCourseVersion.fulfilled, (state, action) => {
      if (!action.payload || !state.syllabus) return

      const updatedUnit = action.payload
      state.syllabus.units = state.syllabus.units.map((unit) => {
        if (unit.id === updatedUnit.id) {
          return updatedUnit
        }
        return unit
      })
    })
    builder.addCase(archiveSyllabus.fulfilled, (state) => {
      if (!state.syllabus) return

      state.syllabus.status = SyllabusDtoStatusEnum.Archived
    })
    builder.addCase(updateStaleCourse.pending, (state, action) => {
      if (!state.reviewStaleCoursesPopup) {
        return
      }

      state.reviewStaleCoursesPopup.statuses[action.meta.arg.courseId] =
        "pending"
    })
    builder.addCase(updateStaleCourse.fulfilled, (state, action) => {
      if (!action.payload || !state.syllabus) {
        return
      }

      const updatedUnit = action.payload
      state.syllabus.units = state.syllabus.units.map((unit) => {
        if (updatedUnit.id === unit.id) {
          return updatedUnit
        }
        return unit
      })

      if (state.reviewStaleCoursesPopup) {
        state.reviewStaleCoursesPopup.statuses[action.meta.arg.courseId] =
          "updated"

        if (
          Object.values(state.reviewStaleCoursesPopup.statuses).every(
            (status) => status === "updated",
          )
        ) {
          state.reviewStaleCoursesPopup = undefined
        }
      }
    })
    builder.addCase(publishCourse.fulfilled, (state, action) => {
      const { syllabusId, unitId, duplicateOriginalCourseId } =
        action.meta.arg ?? {}

      if (duplicateOriginalCourseId) {
        return
      }

      if (!syllabusId || !unitId || !state.syllabus) {
        return
      }

      if (!action.payload || action.payload?.kind === "failure") {
        return
      }

      const publishResponse = action.payload.value
      const unit = state.syllabus.units.find((u) => u.id === unitId)

      if (!unit) {
        return
      }

      const doesUnitContainCourse = unit.courseDescriptors.some(
        (descriptor) =>
          descriptor.courseId === publishResponse.publishedVersion.id,
      )

      function canUpdateCourseVersion() {
        if (!publishResponse.previousVersion) {
          return true
        }

        const previousTopicsIds =
          publishResponse.previousVersion.syllabus.topics.map((t) => t.id)
        const currentTopicsIds =
          publishResponse.publishedVersion.syllabus.topics.map((t) => t.id)
        return (
          JSON.stringify(previousTopicsIds) === JSON.stringify(currentTopicsIds)
        )
      }

      if (!doesUnitContainCourse) {
        state.syllabus.units = state.syllabus.units.map((u) => {
          if (u.id === unitId) {
            return {
              ...u,
              courseDescriptors: [
                ...u.courseDescriptors,
                {
                  courseId: publishResponse.publishedVersion.id,
                  version: publishResponse.publishedVersion.version,
                },
              ],
            }
          }
          return u
        })
      } else {
        if (!canUpdateCourseVersion()) {
          return
        }

        // Although the syllabus will automatically be updated by the backend we also update it here directly in the
        // slice for a better user experience.
        state.syllabus.units = state.syllabus.units.map((u) => {
          if (u.id === unitId) {
            return {
              ...u,
              courseDescriptors: u.courseDescriptors.map((descriptor) => {
                if (
                  descriptor.courseId === publishResponse.publishedVersion.id
                ) {
                  return {
                    ...descriptor,
                    version: publishResponse.publishedVersion.version,
                  }
                }
                return descriptor
              }),
            }
          }
          return u
        })
      }
    })
    builder.addCase(takeControlOfSyllabus.fulfilled, (state, action) => {
      if (!state.syllabus) return

      state.syllabus = {
        ...state.syllabus,
        lock: action.payload,
      }
    })
    builder.addCase(releaseControlIfNeededOfSyllabus.fulfilled, (state) => {
      if (!state.syllabus) return

      state.syllabus = {
        ...state.syllabus,
        lock: undefined,
      }
    })
  },
})

export const fetchSyllabus = createAppAsyncThunk(
  "syllabusEditor/fetchSyllabus",
  async (syllabusMete: { syllabusId: string; version?: number }, thunkAPI) => {
    const { syllabusId, version } = syllabusMete
    const state = thunkAPI.getState()
    const programStartDate = selectSyllabus(syllabusId)(state)?.programStartDate
    return SyllabusClient.getSyllabus(syllabusId, programStartDate, version)
  },
)

export const saveSyllabus = createAppAsyncThunk<
  { syllabus: SyllabusDto | undefined; error: undefined },
  void,
  { syllabus: undefined; error: string }
>("syllabusEditor/saveSyllabus", async (_, thunkAPI) => {
  const syllabus = thunkAPI.getState().syllabusEditor.syllabus
  if (!syllabus) return { syllabus: undefined, error: undefined }

  const lastSave = thunkAPI.getState().syllabusEditor.lastSavedSyllabus
  if (JSON.stringify(syllabus) === JSON.stringify(lastSave))
    return { syllabus: lastSave, error: undefined }

  return SyllabusClient.updateSyllabus(syllabus.id, syllabus)
    .then((syllabus) => ({ syllabus, error: undefined }))
    .catch((error) =>
      thunkAPI.rejectWithValue({
        error: error.response.data.message,
      }),
    )
})

export type SaveSyllabusResponse = ReturnType<
  typeof saveSyllabus.fulfilled | typeof saveSyllabus.rejected
>

export const createNewDraftVersion = createAppAsyncThunk(
  "syllabusEditor/createNewDraftVersion",
  async (syllabusId: string, thunkAPI) => {
    return SyllabusClient.createNewDraftVersion(syllabusId).then((syllabus) =>
      thunkAPI.dispatch(fetchSyllabuses()).then(() => syllabus),
    )
  },
)

export const publishSyllabus = createAppAsyncThunk(
  "syllabusEditor/publishSyllabus",
  async (_, thunkAPI) => {
    const syllabus = thunkAPI.getState().syllabusEditor.syllabus
    if (!syllabus) return

    const publishedSyllabus = await thunkAPI.dispatch(saveSyllabus()).then(() =>
      SyllabusClient.publish(syllabus.id).then(() => ({
        ...syllabus,
        status: SyllabusDtoStatusEnum.Published,
      })),
    )

    return publishedSyllabus
  },
)

export const resetUnitSprintsToDefault = createAppAsyncThunk(
  "syllabusEditor/resetUnitSprintsToDefault",
  async (identifier: { unitId: string }, thunkAPI) => {
    const { unitId } = identifier
    const state = thunkAPI.getState()
    const syllabus = state.syllabusEditor.syllabus
    const unit = syllabus?.units.find((u) => u.id === unitId)
    if (!syllabus || !unit) return

    const unitCourses = selectEditedUnitCourses(unitId)(state)
    const sprints = defaultSprintsForCourses(unitCourses).flat()
    thunkAPI.dispatch(unitSprintsEdited({ unitId, sprints }))
  },
)

export const editSprintLastElement = createAppAsyncThunk(
  "syllabusEditor/editSprintLastElement",
  async (
    identifier: {
      unitId: string
      sprintIndex: number
      newLastTopicId: string
    },
    thunkAPI,
  ) => {
    const { unitId, sprintIndex, newLastTopicId } = identifier
    const state = thunkAPI.getState()
    const syllabus = state.syllabusEditor.syllabus
    const unit = syllabus?.units.find((u) => u.id === unitId)
    if (!unit) return
    const updatesSprints = changeSprintLastTopic(
      unit.sprints,
      sprintIndex,
      newLastTopicId,
      state,
      unitId,
    )
    thunkAPI.dispatch(unitSprintsEdited({ unitId, sprints: updatesSprints }))
  },
)

function changeSprintLastTopic(
  sprints: SprintDto[],
  index: number,
  newLastTopicId: string,
  state: RootState,
  unitId: string,
): SprintDto[] {
  if (sprints[index].lastTopicId === newLastTopicId) return sprints

  const isMinimizedToZero = newLastTopicId === sprints[index - 1]?.lastTopicId
  if (isMinimizedToZero) return sprints.filter((_, i) => i !== index)

  const isMinimizedToTopOfTimeline =
    newLastTopicId === SPRINTS_TIMELINE_HEADER_ID
  if (isMinimizedToTopOfTimeline) return sprints.filter((_, i) => i > index)

  const modifiedSprint = { ...sprints[index], lastTopicId: newLastTopicId }
  const updatedSprints = sprints.map((sprint, i) =>
    i === index ? modifiedSprint : sprint,
  )

  return removeOverriddenSprints(
    updatedSprints,
    index,
    newLastTopicId,
    state,
    unitId,
  )
}

function removeOverriddenSprints(
  sprints: SprintDto[],
  sprintIndex: number,
  newLastTopicId: string,
  state: RootState,
  unitId: string,
): SprintDto[] {
  const topicIDs = selectTopicsInEditedUnit(unitId)(state).map(
    (topic) => topic.id,
  )
  const indexOfNewLastTopic = topicIDs.indexOf(newLastTopicId)
  return sprints.filter((sprint, i) => {
    if (i <= sprintIndex) return true
    return topicIDs.indexOf(sprint.lastTopicId) > indexOfNewLastTopic
  })
}

export const splitSprint = createAppAsyncThunk(
  "syllabusEditor/splitSprint",
  async (
    identifier: {
      unitId: string
      sprintId: string
    },
    thunkAPI,
  ) => {
    const { unitId, sprintId } = identifier
    const state = thunkAPI.getState()
    const syllabus = state.syllabusEditor.syllabus
    const unit = syllabus?.units.find((u) => u.id === unitId)
    const sprint = unit?.sprints.find((s) => s.id === sprintId)
    if (!unit || !sprint) return

    const sprints = [...unit.sprints]
    const sprintIndex = sprints.indexOf(sprint)
    const currentTopics = selectTopicsInSprint(unitId, sprintIndex)(state)
    if (currentTopics.length < 2) return
    const newLastTopicId = currentTopics[currentTopics.length - 2].id

    const updatedSprint = { ...sprint, lastTopicId: newLastTopicId }
    const newSprintLastTopic = currentTopics[currentTopics.length - 1]
    const newSprint = newSprintFromTopic(newSprintLastTopic)

    sprints.splice(sprintIndex, 1, updatedSprint, newSprint)
    thunkAPI.dispatch(unitSprintsEdited({ unitId, sprints }))
  },
)

export function newSprintFromTopic(topic: Topic) {
  return {
    id: window.crypto.randomUUID(),
    title: topic.title,
    lastTopicId: topic.id,
    isPracticeWeek: false,
    isVacationWeek: false,
    contentReadyForEarlyAccess: false,
  }
}

export const deleteSyllabus = createAppAsyncThunk(
  "syllabusEditor/deleteSyllabus",
  async (id: string, thunkAPI) => {
    return SyllabusClient.deleteSyllabus(id).then((result) => {
      thunkAPI.dispatch(fetchSyllabuses())
      return result
    })
  },
)

export const archiveSyllabus = createAppAsyncThunk(
  "syllabusEditor/archiveSyllabus",
  async (identifier: { syllabusId: string }, thunkAPI) => {
    const syllabusId = identifier.syllabusId
    const syllabus = thunkAPI
      .getState()
      .syllabusesMenu.syllabuses.find((s) => s.id === syllabusId)
    if (!syllabus) return

    return SyllabusClient.archive(syllabusId).then((result) => {
      thunkAPI.dispatch(fetchSyllabuses())
      return result
    })
  },
)

export const takeControlOfSyllabus = createAppAsyncThunk(
  "syllabusEditor/takeControlOfSyllabus",
  async (identifier: { syllabusId: string }, thunkAPI) => {
    const syllabusId = identifier.syllabusId
    const syllabus = thunkAPI.getState().syllabusEditor.syllabus
    if (!syllabus) return

    return ResourceLockingClient.takeControlOfSyllabus(syllabusId)
  },
)

export const releaseControlIfNeededOfSyllabus = createAppAsyncThunk(
  "syllabusEditor/releaseControlOfSyllabus",
  async (identifier: { syllabusId: string }, thunkAPI) => {
    const syllabusId = identifier.syllabusId
    const syllabus = thunkAPI.getState().syllabusEditor.lastSavedSyllabus
    if (!syllabus) return
    const user = selectLoggedInUser(thunkAPI.getState())
    const isLockedByThisUser =
      syllabus.lock && syllabus.lock?.lockedByUserId === user?.clientId

    if (!isLockedByThisUser) return

    return ResourceLockingClient.releaseControlOfResource(syllabusId)
  },
)

export const editUnitCourses = createAppAsyncThunk(
  "syllabusEditor/editUnitCourses",
  async (selectedCourses: UnitCourseDescriptor[], thunkAPI) => {
    const state = thunkAPI.getState()
    const syllabus = state.syllabusEditor.syllabus
    const popupState = state.syllabusEditor.coursesSelectionPopup
    if (!syllabus || !popupState) return

    const updatedCourses = await selectedCourses.msCompactMapAsync((cd) =>
      getCourse(state, cd),
    )

    const currentUnit = syllabus.units.find((u) => u.id === popupState.unitId)
    const sprintsBeforeChange = currentUnit?.sprints
    const coursesBeforeChange =
      await currentUnit?.courseDescriptors.msCompactMapAsync((cd) =>
        getCourse(state, cd),
      )

    const sprints = getAdjustedSprints({
      updatedCourses,
      previousCourses: coursesBeforeChange || [],
      previousSprints: sprintsBeforeChange || [],
    })

    return syllabus.units.map((unit) => {
      if (unit.id === popupState.unitId) {
        return {
          ...unit,
          courseDescriptors: selectedCourses,
          sprints,
        }
      }
      return unit
    })
  },
)

export const removeCourseFromUnit = createAppAsyncThunk(
  "syllabusEditor/removeCourseFromUnit",
  async (ids: { unitId: string; courseId: string }, thunkAPI) => {
    const state = thunkAPI.getState()

    const syllabus = state.syllabusEditor.syllabus
    const unitBeforeChanges = syllabus?.units.find((u) => u.id === ids.unitId)
    const sprintsBeforeChange = unitBeforeChanges?.sprints
    const coursesBeforeChange =
      await unitBeforeChanges?.courseDescriptors.msCompactMapAsync((cd) =>
        getCourse(state, cd),
      )
    thunkAPI.dispatch(syllabusEditorSlice.actions.courseRemovedFromUnit(ids))

    let sprints: SprintDto[] | undefined = undefined
    thunkAPI.getState()
    const updatedUnit = syllabus?.units.find((u) => u.id === ids.unitId)
    const coursesAfterChange =
      await updatedUnit?.courseDescriptors.msCompactMapAsync((cd) =>
        getCourse(state, cd),
      )

    sprints = getAdjustedSprints({
      updatedCourses: coursesAfterChange || [],
      previousCourses: coursesBeforeChange || [],
      previousSprints: sprintsBeforeChange || [],
    })
    thunkAPI.dispatch(unitSprintsEdited({ unitId: ids.unitId, sprints }))
  },
)

export const editCourseVersion = createAppAsyncThunk(
  "syllabusEditor/editCourseVersion",
  async (
    model: { courseId: string; targetCourseVersionId: string },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState()
    const editedUnit = selectUnitByEditedCourseId(state, model.courseId)

    const targetCourseVersionContent = await CourseClient.getCourseByVersion(
      model.courseId,
      model.targetCourseVersionId,
    )

    if (!targetCourseVersionContent || !editedUnit) {
      return
    }

    const updatedSprints = await getUpdatedSprintsForCourseVersionChange(
      state,
      model.courseId,
      targetCourseVersionContent,
    )

    const updatedUnit = {
      ...editedUnit,
      sprints: updatedSprints,
      courseDescriptors: editedUnit.courseDescriptors.map((descriptor) => {
        if (descriptor.courseId === model.courseId) {
          return {
            ...descriptor,
            version: model.targetCourseVersionId,
          }
        }
        return descriptor
      }),
    }

    return updatedUnit
  },
)

export const updateStaleCourse = createAppAsyncThunk(
  "syllabusEditor/updateStaleCourse",
  async (staleCourseModel: StaleCourseModel, thunkAPI) => {
    const state = thunkAPI.getState()
    const syllabus = state.syllabusEditor.syllabus
    if (!syllabus) return

    const unitAndDescriptor = syllabus.units
      .flatMap((unit) => {
        return unit.courseDescriptors.map((descriptor) => {
          return {
            unit: unit,
            courseDescriptor: descriptor,
          }
        })
      })
      .find((ud) => ud.courseDescriptor.courseId === staleCourseModel.courseId)

    if (!unitAndDescriptor) {
      return
    }

    const newCourseVersion = await CourseClient.getLastPublishedVersion(
      staleCourseModel.courseId,
    )

    if (
      !newCourseVersion ||
      newCourseVersion.version <= unitAndDescriptor.courseDescriptor.version
    ) {
      return
    }

    const updatedSprints = await getUpdatedSprintsForCourseVersionChange(
      state,
      staleCourseModel.courseId,
      newCourseVersion,
    )

    const updatedUnit = {
      ...unitAndDescriptor.unit,
      sprints: updatedSprints,
      courseDescriptors: unitAndDescriptor.unit.courseDescriptors.map(
        (descriptor) => {
          if (
            descriptor.courseId === unitAndDescriptor.courseDescriptor.courseId
          ) {
            return {
              ...descriptor,
              version: newCourseVersion.version,
            }
          }
          return descriptor
        },
      ),
    }

    return updatedUnit
  },
)

export async function getCourse(
  state: RootState,
  cd: UnitCourseDescriptor,
): Promise<CourseDto | undefined> {
  function courseFromSyllabusesMenu(): CourseDto | undefined {
    return state.syllabusesMenu.courses[cd.courseId]?.[cd.version]
  }

  async function courseFromClient(): Promise<CourseDto | undefined> {
    return CourseClient.getCourseByVersion(cd.courseId, cd.version)
  }

  return courseFromSyllabusesMenu() ?? (await courseFromClient())
}

async function getUpdatedSprintsForCourseVersionChange(
  state: RootState,
  courseId: string,
  targetCourseVersion: CourseDto,
) {
  const editedUnit = selectUnitByEditedCourseId(state, courseId)
  if (!editedUnit) {
    throw new Error("Unit not found for course " + courseId)
  }

  const currentCourses = await editedUnit.courseDescriptors.msCompactMapAsync(
    (cd) => getCourse(state, cd),
  )
  const updatedCourses = currentCourses.map((c) =>
    c.id === courseId ? targetCourseVersion : c,
  )
  return getAdjustedSprints({
    updatedCourses: updatedCourses,
    previousCourses: currentCourses,
    previousSprints: editedUnit.sprints,
  })
}

export function defaultSprintsForCourses(courses: CourseDto[]): SprintDto[] {
  return courses
    .flatMap((c) => c.syllabus.topics)
    .map((topic) => ({
      id: window.crypto.randomUUID(),
      title: topic.title,
      lastTopicId: topic.id,
      isPracticeWeek: false,
      isVacationWeek: false,
      contentReadyForEarlyAccess: false,
    }))
}

export const {
  unitAdded,
  unitEdited,
  unitSprintsEdited,
  sprintMetadataEdited,
  syllabusUnitRemoved,
  dragUnitStarted,
  dragUnitEnded,
  unitDraggedOver,
  domainChanged,
  syllabusNameUpdated,
  editUnitCoursesClicked,
  editUnitCoursesPopupClosed,
  unitAccessConditionEdited: editUnitAccessCondition,
  editCourseConfirmationPopupClosed,
  editCourseClicked,
  reviewStaleCoursesRequested,
  reviewUpdatedCoursesClosed,
  unmounted,
} = syllabusEditorSlice.actions

export default syllabusEditorSlice.reducer
