import { ProgramDomain } from "@masterschool/course-builder-api"
import { CampusClient } from "./campusClient"

export type TutorDto = {
  id: string
  userClientId: string
  firstName: string
  lastName: string | undefined
  properties: TutorPropertiesDto
}

type TutorPropertiesDto = {
  languages: string[]
  domains: ProgramDomain[]
  supportedSubjects: string[]
}

export class TutorClient {
  static async listTutors(): Promise<TutorDto[]> {
    return CampusClient.get<TutorDto[]>(`tutor/list-tutors`)
  }

  static async listDomainSubjects(): Promise<Record<string, string[]>> {
    return CampusClient.get<Record<string, string[]>>(
      `/tutor/list-domain-subjects`,
    )
  }

  static async listEligibleStudentsClientID(): Promise<string[]> {
    return CampusClient.get<string[]>(`tutor/list-eligible-students`)
  }

  static async createOrUpdateTutorProperties(
    userClientId: string,
    properties: Partial<TutorPropertiesDto>,
  ): Promise<TutorDto> {
    return CampusClient.post<TutorDto>(`tutor/${userClientId}`, properties)
  }
}
