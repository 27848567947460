import { RootState } from "@app/store"
import { CampusUserDto } from "@clients/usersClient"
import { ProgramClassDto } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"

const selectUsersTab = createSelector(
  (state: RootState) => state.usersTab,
  (usersTab) => usersTab,
)

export type TableDataRow = CampusUserDto & {
  classData?: ProgramClassDto | undefined
  staffRoles?: string[]
}

export const selectUserSearchValue = createSelector(
  selectUsersTab,
  (usersTab) => usersTab.searchValue,
)

export const selectUsersTabData = createSelector(selectUsersTab, (usersTab) => {
  return usersTab.users.map((user, index) => {
    return {
      ...user,
      classData: index === 0 ? usersTab.firstUserClass : undefined,
      staffRoles: index === 0 ? usersTab.firstUserRoles : undefined,
    }
  })
})

export const selectFetchingState = createSelector(
  selectUsersTab,
  (usersTab) => usersTab.fetchingState,
)
