import { Snackbar, Stack, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import appTheme from "../../theme/appTheme"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import {
  MultiProgramsPopupType,
  popupStateChanged,
  PresentedPopup,
} from "@features/program/programsMenuSlice"
import { selectIsSnackbarOpen } from "@features/program/programsMenuSelector"

function MultipleSelectedProgramsSnackbar() {
  const dispatch = useAppDispatch()
  const openPopup = (popupType: MultiProgramsPopupType) => {
    dispatch(popupStateChanged(initialPopupState(popupType)))
  }
  const selectedPrograms = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )
  const isOpen = useAppSelector(selectIsSnackbarOpen)
  const message =
    selectedPrograms.length === 1
      ? "1 program selected"
      : `${selectedPrograms.length} programs selected`

  if (!isOpen) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={isOpen}
      ContentProps={{
        sx: {
          bgcolor: appTheme.palette.eTypes.evergreen,
        },
      }}
      message={
        <Stack direction="row" sx={{ gap: "24px", alignItems: "center" }}>
          <Typography variant="body2">{message}</Typography>
          <OptionsButton2
            buttonModel={{
              type: "button",
              props: {
                children: "Actions",
                variant: "outlined",
                color: "inherit",
                size: "small",
              },
            }}
            items={[
              {
                type: "menu-item",
                props: {
                  key: "change-domain",
                  children: "Change Domain",
                  onClick: () => {
                    openPopup(MultiProgramsPopupType.MultiProgramChangeDomain)
                  },
                },
              },
              {
                type: "menu-item",
                props: {
                  key: "change-dates",
                  children: "Change Dates",
                  onClick: () => {
                    openPopup(
                      MultiProgramsPopupType.MultiProgramChangeStartDate,
                    )
                  },
                },
              },
              {
                type: "menu-item",
                props: {
                  key: "change-scope",
                  children: "Change Scope",
                  onClick: () => {
                    openPopup(MultiProgramsPopupType.MultiProgramChangeScope)
                  },
                },
              },
              {
                type: "menu-item",
                props: {
                  key: "delete-programs",
                  children: "Delete Programs",
                  onClick: () => {
                    openPopup(MultiProgramsPopupType.MultiProgramDelete)
                  },
                },
              },
            ]}
            menuProps={{
              anchorOrigin: {
                vertical: -2,
                horizontal: 76,
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              sx: { zIndex: 9999 },
            }}
          />
        </Stack>
      }
    />
  )
}

function initialPopupState(popupType: MultiProgramsPopupType): PresentedPopup {
  switch (popupType) {
    case MultiProgramsPopupType.MultiProgramChangeDomain:
      return {
        type: MultiProgramsPopupType.MultiProgramChangeDomain,
        data: {
          newDomain: undefined,
        },
      }
    case MultiProgramsPopupType.MultiProgramChangeScope:
      return {
        type: MultiProgramsPopupType.MultiProgramChangeScope,
        data: {
          durationInMonths: undefined,
        },
      }
    case MultiProgramsPopupType.MultiProgramChangeStartDate:
      return {
        type: MultiProgramsPopupType.MultiProgramChangeStartDate,
        data: {
          startDate: undefined,
        },
      }
    case MultiProgramsPopupType.MultiProgramDelete:
      return {
        type: MultiProgramsPopupType.MultiProgramDelete,
      }
  }
}

export default MultipleSelectedProgramsSnackbar
