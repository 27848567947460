import { useAppSelector } from "@app/hooks"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import { Stack, Typography } from "@mui/material"
import appTheme from "../../../theme/appTheme"
import { CourseOverviewBar } from "../TrackContentView"

export const TermCoursesList = (props: { courses: UnitCourseDescriptor[] }) => {
  const { courses } = props
  return (
    <Stack direction="column" gap={1} width="100%">
      {courses.map((termCourseDescriptor) => (
        <CourseDescription
          termCourseDescriptor={termCourseDescriptor}
          key={termCourseDescriptor.courseId}
        />
      ))}
    </Stack>
  )
}

const CourseDescription = (props: {
  termCourseDescriptor: UnitCourseDescriptor
}) => {
  const { termCourseDescriptor } = props
  const course = useAppSelector(
    selectLatestPublishedCourse(termCourseDescriptor.courseId),
  )

  if (!course) {
    return (
      <Stack gap={2}>
        <Typography variant="body2" color="error">
          Unable to retrieve course data.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack
      gap={2}
      px={3}
      py={2}
      sx={{
        border: "1px solid",
        borderColor: appTheme.palette.eTypes.sand50,
        borderRadius: "8px",
      }}
    >
      <Typography variant="body2">{course.title}</Typography>
      <CourseOverviewBar course={course} />
    </Stack>
  )
}
