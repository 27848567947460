import { CreateShiftRequest, ShiftDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { ShiftCard } from "./shiftCard"
import appTheme from "../theme/appTheme"
import { CreateOrEditShiftForm } from "./createOrEditShiftForm"

export const CreateOrAttachShift = (props: {
  availableShifts: ShiftDto[]
  onAttachShift: (shift: ShiftDto) => void
  onCreateShift: (createShiftRequest: CreateShiftRequest) => void
}) => {
  const { availableShifts, onAttachShift, onCreateShift } = props
  const [selectedShift, setSelectedShift] = useState<ShiftDto>()
  const [isOnAttachMode, setIsOnAttachMode] = useState(true)

  const onAttachShiftProxy = (shift: ShiftDto) => {
    onAttachShift(shift)
    setSelectedShift(undefined)
  }

  const onSelectedShiftChange = (selectedShiftId: string) => {
    const shift = availableShifts.find((shift) => shift.id === selectedShiftId)
    if (shift) {
      setSelectedShift(shift)
    }
  }

  const attachShiftForm = (
    <Box display="flex" flexDirection="column" gap="16px" flex="1">
      <FormControl>
        <InputLabel id="shift-label">Select shift</InputLabel>
        <Select
          key={!!selectedShift ? selectedShift.id : ""}
          labelId="shift-label"
          id="shift"
          name="shift"
          value={!!selectedShift ? selectedShift.id : ""}
          label="Preferred Shift"
          onChange={(e) => onSelectedShiftChange(e.target.value as string)}
          required
        >
          {availableShifts.map((shift) => (
            <MenuItem key={shift.id} value={shift.id}>
              {shift.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box width="100%">
        {selectedShift && (
          <ShiftCard
            shift={selectedShift}
            onDeleteShift={(_) => {}}
            onEditShift={(_) => {}}
            canDelete={false}
          />
        )}
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!selectedShift}
        onClick={() => selectedShift && onAttachShiftProxy(selectedShift)}
      >
        Attach Shift
      </Button>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" flex="3">
      <Box
        bgcolor="white"
        padding="24px"
        display="flex"
        flexDirection="column"
        gap="16px"
        borderColor={appTheme.palette.eTypes.sand50}
        sx={{ borderWidth: "1px", borderStyle: "solid" }}
      >
        <Typography variant="h6" textAlign="left">
          Attach shifts
        </Typography>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Radio
              checked={isOnAttachMode}
              onChange={(e) => {
                setIsOnAttachMode(e.target.checked)
              }}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <Typography variant="body2">Choose from existing one</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Radio
              checked={!isOnAttachMode}
              onChange={(e) => {
                setIsOnAttachMode(!e.target.checked)
              }}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <Typography variant="body2">Create new</Typography>
          </Box>
        </Box>
        {isOnAttachMode ? (
          attachShiftForm
        ) : (
          <CreateOrEditShiftForm onSubmit={onCreateShift} />
        )}
      </Box>
    </Box>
  )
}
