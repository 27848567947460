import { Button, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

function AddTopicButton({
  onClick,
  shouldShowAsWeek,
}: {
  onClick: () => void
  shouldShowAsWeek: boolean
}) {
  return (
    <Button
      variant="outlined"
      size="small"
      fullWidth
      startIcon={
        <SvgIcon
          component={appIcons.plus}
          inheritViewBox
          sx={{
            fill: "none",
            width: "20px",
            height: "20px",
          }}
          stroke={appTheme.palette.icon.black}
        />
      }
      onClick={onClick}
    >
      {shouldShowAsWeek ? "Add week" : "Add topic"}
    </Button>
  )
}

export default AddTopicButton
