import { DialogCloseButton } from "@cmp/buttons/dialogCloseButton"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { Box } from "@mui/material"

export const StudentProfilePanel = (props: {
  studentId: string | null
  open: boolean
  onClose: () => void
}) => {
  const { studentId, open, onClose } = props

  const masterschoolCampusUrl = import.meta.env.VITE_CAMPUS_URL
  const iframeURL = `${masterschoolCampusUrl}/student-profile/${studentId}`

  const width = 680
  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          zIndex: open ? 99 : -1,
          backgroundColor: "#33333333",
          backdropFilter: "blur(2.5px)",
          opacity: open ? 1 : 0.01,
          transition: "all 0.3s ease-in-out",
        }}
        onClick={onClose}
      />
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: open ? 0 : `-${width}px`,
          width: `${width}px`,
          height: "100%",
          backgroundColor: "white",
          transition: "right 0.7s",
          zIndex: 100,
        }}
      >
        <DialogCloseButton onClick={onClose} />
        {studentId ? (
          <iframe
            src={iframeURL}
            width="100%"
            allow="clipboard-write"
            title={`Student Profile: ${studentId}`}
            height="100%"
            style={{
              border: "none",
              boxShadow:
                "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
            }}
          />
        ) : (
          <LoadingComponent loadedComponents="data" />
        )}
      </Box>
    </Box>
  )
}
