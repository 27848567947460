import { TrackClient } from "@clients/trackClient"
import { TrackDto } from "@masterschool/course-builder-api"
import { useState, useRef, useCallback, useEffect } from "react"

export const useAutoSaveTrack = () => {
  const [unsavedTrackChanges, setUnsavedTrackChanges] = useState<
    TrackDto | undefined
  >(undefined)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const onTrackChange = (newTrack: TrackDto) => {
    setUnsavedTrackChanges(newTrack)
  }

  const scheduleNextUpdate = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(async () => {
      try {
        if (unsavedTrackChanges) {
          TrackClient.save(unsavedTrackChanges.id, unsavedTrackChanges)
        }
      } finally {
        scheduleNextUpdate()
      }
    }, 10 * 1000)
  }, [unsavedTrackChanges])

  useEffect(() => {
    scheduleNextUpdate()
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [scheduleNextUpdate])

  return { editedTrack: unsavedTrackChanges, onTrackChange }
}
