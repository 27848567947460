import { Box } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { coursesColumns } from "./coursesColumns"
import appTheme from "../../../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import { selectMainPageCourses } from "@features/search/searchSlice"
import { useNavigate } from "react-router-dom"

export function CoursesTable() {
  const navigate = useNavigate()
  const latestVersionCourses = useAppSelector(selectMainPageCourses)

  const onOpenClicked = (courseId: string) => {
    navigate(`/course-versions/${courseId}`)
  }

  return (
    <Box
      sx={{
        maxWidth: "1240px",
        maxHeight: "100%",
        bgcolor: "eTypes.sand15",
      }}
    >
      <DataGridPro
        rows={latestVersionCourses}
        columns={coursesColumns}
        disableColumnMenu
        disableRowSelectionOnClick
        disableColumnReorder
        rowHeight={78}
        onRowClick={(gridRowParams) => {
          const courseId = gridRowParams?.row?.id || ""
          onOpenClicked(courseId)
        }}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}
