import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
} from "@masterschool/course-builder-api"
import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"
import { SxProps } from "@mui/material"
import { isDevEnv, isLocalEnv } from "@utils/envUtils"
import { useAppDispatch } from "@app/hooks"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"

function AddItemButton(props: {
  onElementClick: (itemType: CourseSyllabusElementTypeEnum) => void
  onLiveEventClick: () => void
  onPasteClicked: (element: CourseSyllabusElement) => void
  sx?: SxProps
  iconSx?: SxProps
}) {
  const { onElementClick, onLiveEventClick, onPasteClicked, sx, iconSx } = props

  const showLiveSessions = isLocalEnv() || isDevEnv()
  const dispatch = useAppDispatch()

  const items = [
    {
      text: "Lesson",
      icon: appIcons.bookOpen01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.EmptyLesson)
      },
    },
    {
      text: "Quiz",
      icon: appIcons.file02,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.Test)
      },
    },
    {
      text: "Project",
      icon: appIcons.codeSquare01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.EmptyProject)
      },
    },
    {
      text: "Survey",
      icon: appIcons.star01,
      onSelect: () => {
        onElementClick(CourseSyllabusElementTypeEnum.Survey)
      },
    },
    {
      text: "Paste item",
      icon: appIcons.copy04,
      onSelect: async () => {
        try {
          const clipboardData = await navigator.clipboard.readText()
          const element = createNewElementFromText(clipboardData)

          onPasteClicked(element)
          dispatch(
            showSuccessSnackbar("Item pasted from clipboard successfully"),
          )
        } catch (e: any) {
          dispatch(
            showErrorSnackbar("Failed to paste from clipboard: " + e.message),
          )
        }
      },
    },
  ]

  if (showLiveSessions) {
    items.push({
      text: "Live Session",
      icon: appIcons.playCircle,
      onSelect: () => {
        onLiveEventClick()
      },
    })
  }

  return (
    <OptionsButton
      button={{
        text: "Add item",
        leftIcon: appIcons.plusCircle,
        size: "small",
        iconSx: {
          fill: "none",
          ...iconSx,
        },
        sx: {
          border: "none",
          boxSizing: "border-box",
          ...sx,
        },
        variant: "text",
      }}
      items={items}
    />
  )
}

function createNewElementFromText(text: string): CourseSyllabusElement {
  const element = JSON.parse(text)
  if (!isCourseSyllabusElement(element)) {
    throw new Error("Data in clipboard is not a valid course element")
  }

  element.item.id = window.crypto.randomUUID()
  if ("tasks" in Object.keys(element.item)) {
    const lesson = element.item as { tasks: { id: string }[] }
    lesson.tasks.forEach((task) => {
      task.id = window.crypto.randomUUID()
    })
  }
  return element
}

// Sanity check to ensure that the object in the clipboard is a CourseSyllabusElement; does not
// cover all properties and types, but should be sufficient for this use case.
function isCourseSyllabusElement(obj: any): obj is CourseSyllabusElement {
  return (
    obj &&
    typeof obj === "object" &&
    "item" in obj &&
    typeof obj.item === "object" &&
    "id" in obj.item &&
    typeof obj.item.id === "string" &&
    "type" in obj &&
    Object.values(CourseSyllabusElementTypeEnum).includes(obj.type)
  )
}

export default AddItemButton
