import { Box, Chip, Stack, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { CourseMajorsTable } from "./courses-table/course-majors-table/courseMajorsTable"
import { selectCourseMajorRepresentatives } from "@features/coursesMenu/coursesSelectors"
import { useAppSelector } from "@app/hooks"
import { domainToColor, domainToDisplayName } from "@utils/domainUtils"
import {
  CourseDescriptorDto,
  ProgramDomain,
} from "@masterschool/course-builder-api"
import BreadcrumbsPath from "@cmp/breadcrumbs/breadcrumbsPath"
import { AddItemButton } from "@cmp/buttons/addItemButton"
import { capitalizeFirstLetter, uuidToShortId } from "./courses-table/utils"
import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"
import { useEffect } from "react"

function CourseMajorsContainer() {
  const { courseId } = useParams()
  const navigate = useNavigate()
  const courseMajors = useAppSelector((state) =>
    selectCourseMajorRepresentatives(state, courseId || ""),
  )
  const canView = useAppSelector(selectShowTrackFeature)

  useEffect(() => {
    if (!canView) {
      navigate("/courses")
    }
  }, [canView, navigate])

  if (!courseMajors || courseMajors.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingX: "24px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          py: "24px",
          display: "flex",
        }}
      >
        <CourseBreadcrumbs course={courseMajors[0]} />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1140px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          mt: "80px",
          mb: "64px",
        }}
      >
        <Header course={courseMajors[0]} />
        <CourseMajorsTable courseMajors={courseMajors} />
      </Box>
    </Box>
  )
}

function Header({ course }: { course: CourseDescriptorDto }) {
  const name = course.name || "missing name"
  const title = course.title || "missing title"
  const domain = course.domains[0] || ProgramDomain.WEB
  const displayCourseId = uuidToShortId(course.id)
  return (
    <Stack gap="64px">
      <Stack direction="column" gap="8px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            pb: "8px",
          }}
        >
          <Typography variant="h4">{name}</Typography>
          <AddItemButton text="Add version" onClick={() => undefined} />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          divider={
            <Typography variant="body3" color="text.secondary">
              •
            </Typography>
          }
        >
          <Chip
            key={domain}
            label={domainToDisplayName(domain)}
            size="small"
            sx={{
              backgroundColor: domainToColor(domain as ProgramDomain),
              alignSelf: "flex-start",
            }}
          />
          <Typography variant="body2">{` Course ID ${displayCourseId}`}</Typography>
          <Typography variant="body2">
            {capitalizeFirstLetter(title)}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        New programs will automatically use the last published version of the
        course.
      </Typography>
    </Stack>
  )
}

function CourseBreadcrumbs({ course }: { course: CourseDescriptorDto }) {
  return (
    <BreadcrumbsPath
      pathBreadcrumbs={{
        breadcrumbs: [
          {
            key: "courses",
            title: "courses",
            navigationPath: "/courses",
          },
          {
            key: course.id,
            title: course.name,
          },
        ],
      }}
    />
  )
}

export default CourseMajorsContainer
