import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

export const selectUserFeatureFlags = (state: RootState) =>
  state.login.featureFlags

export const selectIsUserFeatureFlagEnabled = createSelector(
  [selectUserFeatureFlags, (state: RootState, flagName: string) => flagName],
  (featureFlags, flagName) => {
    if (featureFlags.status !== "success") return false

    const flag = featureFlags.data.find((flag) => flag.flag === flagName)

    return flag?.enabled || false
  },
)

export const selectShowTrackFeature = createSelector(
  [(state) => selectIsUserFeatureFlagEnabled(state, "trackBuilder")],
  (isTrackFeatureEnabled) => isTrackFeatureEnabled,
)

export const selectIsStudentsTableEnabled = createSelector(
  [(state) => selectIsUserFeatureFlagEnabled(state, "studentsTable")],
  (isStudentsTableFeatureEnabled) => isStudentsTableFeatureEnabled,
)
