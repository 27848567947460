import AppObserver from "@app/middlewares/appObserver"
import { store } from "@app/store"
import { fetchCourse } from "./courseViewSlice"
import { CourseDto } from "@masterschool/course-builder-api"

const CourseViewObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    switch (action.type) {
      case "coursesMenu/updateTrackCourseGeneralDetails/fulfilled":
        const updatedCourse = action.payload as CourseDto
        const courseInCourseViewPage = currentState.courseView.course
        const courseInViewPageHasChanged =
          courseInCourseViewPage &&
          courseInCourseViewPage.id === updatedCourse.id

        if (courseInViewPageHasChanged) {
          store.dispatch(
            fetchCourse({
              courseId: courseInCourseViewPage.id,
              version: courseInCourseViewPage.version,
            }),
          )
          break
        }
    }
  },
}

export default CourseViewObserver
