import { ConsultantDto } from "@masterschool/course-builder-api"
import { Avatar, Badge, Box, styled, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"

const colors = [
  appTheme.palette.eTypes.lilac,
  appTheme.palette.eTypes.pink,
  appTheme.palette.eTypes.grassGreen,
  appTheme.palette.eTypes.evergreen,
]

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))

export const ConsultantCell = (props: {
  consultant: ConsultantDto
  isOnline: boolean
  index: number
}) => {
  const colorPicker = () => {
    if (!props.consultant.isAvailable) {
      return appTheme.palette.grey[400]
    }

    return colors[props.index % colors.length]
  }

  const avatar = (
    <Avatar sx={{ bgcolor: colorPicker(), width: "32px", height: "32px" }}>
      <Typography variant="body3" color="white">
        {props.consultant.name.substring(0, 2).toUpperCase()}{" "}
      </Typography>
    </Avatar>
  )
  return (
    <Box display="flex" gap="8px">
      {props.isOnline ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          {avatar}
        </StyledBadge>
      ) : (
        avatar
      )}
      <Typography
        textAlign="center"
        alignSelf="center"
        textTransform="capitalize"
        variant="body2"
      >
        {props.consultant.name}
      </Typography>
    </Box>
  )
}
