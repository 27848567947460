import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { TrackClient } from "@clients/trackClient"
import { TrackDto } from "@masterschool/course-builder-api"

interface TrackById {
  [key: string]: TrackDto
}

interface TrackState {
  byId: TrackById
  loadingState: "idle" | "loading" | "failed"
  error: {
    list: string | null
    create: string | null
    update: string | null
  }
}

const initialState: TrackState = {
  byId: {},
  loadingState: "idle",
  error: {
    list: null,
    create: null,
    update: null,
  },
}

const trackSlice = createSlice({
  name: "track",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {
        list: null,
        create: null,
        update: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTracks.pending, (state) => {
        state.loadingState = "loading"
        state.error.list = null
      })
      .addCase(fetchTracks.fulfilled, (state, action) => {
        state.loadingState = "idle"
        state.byId = action.payload.reduce((acc, track) => {
          acc[track.id] = track
          return acc
        }, {} as TrackById)
      })
      .addCase(fetchTracks.rejected, (state, action) => {
        state.error.list = action.payload as string
      })
  },
})

export const fetchTracks = createAsyncThunk(
  "tracks/fetchTracks",
  async (_, { rejectWithValue }) => {
    try {
      return await TrackClient.list()
    } catch (error) {
      return rejectWithValue((error as Error).message)
    }
  },
)

export const { clearErrors } = trackSlice.actions
export default trackSlice.reducer
