import { useAppSelector } from "@app/hooks"
import { TabIdentifier } from "@features/app-tabs/tabs.selector"
import { selectShouldShowProductionWarning } from "@features/ui/uiSelector"
import { ContainerWithNavigationSidebar } from "@masterschool/ui"
import { useState } from "react"
import AppHeader from "./header/appHeader"
import {
  MainSidebarHeader,
  mainSidebarProps,
  useMainSidebarItems,
} from "./sidebar/mainSidebar"

export function AppTabsContainer(props: {
  selectedTab: TabIdentifier | undefined
  children?: React.ReactNode
}) {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false)
  const shouldShowFrame = useAppSelector(selectShouldShowProductionWarning)
  const mainSideBarItems = useMainSidebarItems(props.selectedTab)

  return (
    <ContainerWithNavigationSidebar
      header={<MainSidebarHeader isMinimized={isSidebarMinimized} />}
      sidebarProps={mainSidebarProps}
      items={mainSideBarItems}
      minimized={isSidebarMinimized}
      onMinimizeToggle={() => setIsSidebarMinimized(!isSidebarMinimized)}
      sx={{
        height: 1,
        width: 1,
        border: shouldShowFrame ? "red solid 10px" : "none",
        bgcolor: "white",
      }}
    >
      <AppHeader />
      {props.children}
    </ContainerWithNavigationSidebar>
  )
}
