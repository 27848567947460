import { TrackDto } from "@masterschool/course-builder-api"
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { DomainChip } from "@utils/domainChip"
import { useNavigate } from "react-router-dom"

export const TracksTable = (props: { tracks: TrackDto[] }) => {
  const navigate = useNavigate()

  if (props.tracks.length === 0) {
    return <EmptyTrackTable />
  }
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Track Id</TableCell>
            <TableCell>School</TableCell>
            <TableCell> Terms </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tracks.map((track) => (
            <TableRow
              key={track.id}
              hover
              onClick={() => navigate(`/track/${track.id}`)}
            >
              <TableCell>{track.externalName}</TableCell>
              <TableCell>{"TBD"}</TableCell>

              <TableCell>
                <DomainChip domain={track.domain || ""} />
              </TableCell>
              <TableCell>{track.terms.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const EmptyTrackTable = () => {
  return (
    <Stack pt={10} alignItems="center">
      <Typography color="text.disabled" variant="body1">
        No track versions added yet
      </Typography>
    </Stack>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmptyModuleTable = () => {
  return (
    <Stack pt={10} alignItems="center">
      <Typography variant="body2_sb" pb={1}>
        Build master modules once, apply them everywhere
      </Typography>
      <Typography variant="body2">
        Maintain consistency and streamline your workflow with master modules.
      </Typography>
      <Typography>
        Create a master module once and apply it to multiple tracks.
      </Typography>
    </Stack>
  )
}
