import Droppable from "@cmp/droppable"
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import {
  forwardRef,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
} from "react"
import { createPortal } from "react-dom"
import CourseRemovalButton from "../syllabus/panel/popups/courseRemovalButton"

export interface UnitCoursesListRef {
  scrollToBottom: () => void
}

export const UnitCoursesList = forwardRef<
  UnitCoursesListRef,
  {
    selectedCourses: UnitCourseDescriptor[]
    onChangeSelectedCourses: (selectedCourses: UnitCourseDescriptor[]) => void
  }
>((props, forwardedRef) => {
  const { selectedCourses, onChangeSelectedCourses } = props
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )
  const [draggedCourseId, setDraggedCourseId] = useState<string | undefined>(
    undefined,
  )
  const draggedCourse = selectedCourses.find(
    (cd) => cd.courseId === draggedCourseId,
  )
  const ref = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    if (!ref.current) {
      return
    }

    ref.current.scrollTo({
      top: ref.current.scrollHeight,
      behavior: "smooth",
    })
  }, [])

  const onDragOver = (payload: {
    sourceIndex: number | undefined
    targetIndex: number | undefined
  }) => {
    const { sourceIndex, targetIndex } = payload
    if (sourceIndex === undefined || targetIndex === undefined) {
      return
    }
    const reorderedSelectedCourses = arrayMove(
      selectedCourses,
      sourceIndex,
      targetIndex,
    )
    onChangeSelectedCourses(reorderedSelectedCourses)
  }

  const onRemoveCourse = (courseId: string) => {
    const newSelectedCourses = selectedCourses.filter(
      (cd) => cd.courseId !== courseId,
    )
    onChangeSelectedCourses(newSelectedCourses)
  }

  useImperativeHandle(
    forwardedRef,
    () => ({
      scrollToBottom: scrollToBottom,
    }),
    [scrollToBottom],
  )

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Box
        ref={ref}
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          paddingTop: "16px",
          overflow: "auto",
        }}
      >
        <DndContext
          onDragStart={(start) => {
            setDraggedCourseId(start.active.id.toString())
          }}
          onDragEnd={() => {
            setDraggedCourseId(undefined)
          }}
          onDragCancel={() => {
            setDraggedCourseId(undefined)
          }}
          onDragOver={({ active, over }) => {
            if (!active || !over) {
              return
            }
            onDragOver({
              sourceIndex: active.data.current?.sortable?.index,
              targetIndex: over.data.current?.sortable?.index,
            })
          }}
          sensors={sensors}
        >
          <Droppable
            id={"CourseSelectionPopup"}
            items={selectedCourses.map((cd, index) => {
              return {
                element: (
                  <CourseRemovalButton
                    courseDescriptor={cd}
                    onRemoveClick={() => onRemoveCourse(cd.courseId)}
                    sx={{
                      opacity: cd.courseId === draggedCourseId ? 0 : 1,
                      padding: "0px 24px 0 24px",
                    }}
                  />
                ),
                identifier: cd.courseId,
              }
            })}
            placeholder={<></>}
          />
          {ref.current &&
            createPortal(
              <DragOverlay
                dropAnimation={{
                  duration: 300,
                  easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                }}
                style={{
                  zIndex: 9999999,
                }}
              >
                {draggedCourse ? (
                  <CourseRemovalButton
                    courseDescriptor={draggedCourse}
                    onRemoveClick={() => {}}
                    sx={{
                      transform: "scale(0.95, 1)",
                      padding: "0px 24px 0 24px",
                    }}
                  />
                ) : null}
              </DragOverlay>,
              ref.current,
            )}
        </DndContext>
      </Box>
    </div>
  )
})
