import { Box, Typography } from "@mui/material"
import appTheme from "../../theme/appTheme"

function placeholder({ text }: { text?: string }) {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px 24px",
        border: `1px dashed ${appTheme.palette.other.outlineBorder}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "default",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: appTheme.palette.text.secondary,
        }}
      >
        {text || "Drag & drop items here or click the + button below"}
      </Typography>
    </Box>
  )
}

export default placeholder
