import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ProgramClassDto } from "@masterschool/course-builder-api"
import { CampusUserDto, UsersClient } from "@clients/usersClient"
import { ProgramClassClient } from "@clients/programClassClient"
import { ProgramStaffClient } from "@clients/programStaffClient"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"

type UsersTabState = {
  users: CampusUserDto[]
  firstUserClass: ProgramClassDto | undefined | null
  firstUserRoles: { programId: string; roles: string[] }[] | undefined | null
  fetchingState: "idle" | "fetching" | "failed" | undefined
  searchValue: string
}

export const getInitialState: () => UsersTabState = () => {
  return {
    users: [],
    firstUserClass: undefined,
    firstUserRoles: undefined,
    fetchingState: undefined,
    searchValue: "",
  }
}

const usersTabSlice = createSlice({
  name: "usersTabSlice",
  initialState: getInitialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.pending, (state) => {
        state.fetchingState = "fetching"
      })
      .addCase(searchUsers.rejected, (state) => {
        state.fetchingState = "failed"
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.fetchingState = "idle"
        if (action.payload === undefined) {
          return
        }
        state.users = action.payload
        if (action.payload.length === 0) {
          state.firstUserClass = null
          state.firstUserRoles = null
        }
      })
      .addCase(fetchUserClass.fulfilled, (state, action) => {
        state.firstUserClass = action.payload
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.firstUserRoles = action.payload
        state.fetchingState = "idle"
      })
  },
})

export const searchUsers = createAppAsyncThunk(
  "usersTab/searchUsers",
  async (_, thunkAPI) => {
    const searchValue = thunkAPI.getState().usersTab.searchValue
    const trimmedSearchValue = searchValue.trim()
    if (trimmedSearchValue.length < 4) return undefined
    return UsersClient.searchUser(trimmedSearchValue).then((users) => {
      if (users.length === 0) return users
      const firstResult = users[0]
      return thunkAPI
        .dispatch(fetchUserClass({ ...firstResult }))
        .then(() => thunkAPI.dispatch(fetchUserRoles({ ...firstResult })))
        .then(() => users)
    })
  },
)

const fetchUserClass = createAsyncThunk(
  "usersTab/fetchUserClass",
  async (params: { email: string }) => {
    return ProgramClassClient.studentRegistrationStatus(params.email).then(
      (statusForFirstResult) => {
        const classId = statusForFirstResult.classId
        if (!classId) return undefined
        return ProgramClassClient.getClassById(classId)
      },
    )
  },
)

const fetchUserRoles = createAsyncThunk(
  "usersTab/fetchUserRoles",
  async (params: { clientId: string }) => {
    return ProgramStaffClient.listUserRoles(params.clientId)
  },
)

export const { setSearchValue: setUserSearchValue } = usersTabSlice.actions

export default usersTabSlice.reducer
