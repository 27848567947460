import { useAppDispatch } from "@app/hooks"

import { duplicateCourse } from "@features/coursesMenu/coursesMenuSlice"
import { PopupState, PopupType, popupItemClicked } from "@features/ui/uiSlice"
import {
  CourseDescriptorDto,
  CourseType,
} from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"

function useCoursesTableMenuActions(course: CourseDescriptorDto) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { id: courseId, version } = course

  const onOpenClicked = () => navigate(`/course-versions/${courseId}`)

  const onEditCourseDetailsClicked = () => {
    const popupModel: PopupState = {
      course,
      type: PopupType.EditCourseDetails,
    }

    if (course.type === CourseType.Legacy) {
      return
    }
    dispatch(popupItemClicked(popupModel))
  }

  const onDuplicateClicked = () => {
    dispatch(duplicateCourse({ courseId, version }))
  }

  const onArchiveClicked = () => {
    dispatch(
      popupItemClicked({
        courseId,
        type: PopupType.ArchiveCourseConfirmation,
      }),
    )
  }

  return {
    onOpenClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onEditCourseDetailsClicked,
  }
}

export default useCoursesTableMenuActions
