import {
  TabIdentifier,
  tabIdentifierToRouteDescriptor,
} from "@features/app-tabs/tabs.selector"
import { NavigationSidebarItem } from "@masterschool/ui"
import appIcons from "@utils/appIcons"

export const getTabIcons = (
  tab: TabIdentifier,
): {
  selectedIcon: NavigationSidebarItem["selectedIcon"]
  unselectedIcon: NavigationSidebarItem["unselectedIcon"]
} => {
  switch (tab) {
    case TabIdentifier.Syllabuses:
      return {
        selectedIcon: appIcons.listSolid,
        unselectedIcon: appIcons.list,
      }
    case TabIdentifier.Courses:
      return {
        selectedIcon: appIcons.bookClosedSolid,
        unselectedIcon: appIcons.bookClosed,
      }
    case TabIdentifier.Programs:
      return {
        selectedIcon: appIcons.grid01Solid,
        unselectedIcon: appIcons.grid01,
      }
    case TabIdentifier.Projects:
      return {
        selectedIcon: appIcons.codeSquare01Solid,
        unselectedIcon: appIcons.codeSquare01,
      }
    case TabIdentifier.Deeplinks:
      return {
        selectedIcon: appIcons.link01Solid,
        unselectedIcon: appIcons.link01,
      }
    case TabIdentifier.AppFeatureFlags:
      return {
        selectedIcon: appIcons.flag03Solid,
        unselectedIcon: appIcons.flag03,
      }
    case TabIdentifier.Calendar:
      return {
        selectedIcon: appIcons.calendarSolid,
        unselectedIcon: appIcons.calendar,
      }
    case TabIdentifier.SalesManagement:
      return {
        selectedIcon: appIcons.currencyDollarCircleSolid,
        unselectedIcon: appIcons.currencyDollarCircle,
      }
    case TabIdentifier.Users:
      return {
        selectedIcon: appIcons.faceSmileSolid,
        unselectedIcon: appIcons.faceSmile,
      }
    case TabIdentifier.BookSession:
      return {
        selectedIcon: appIcons.clockPlusSolid,
        unselectedIcon: appIcons.clockPlus,
      }
    case TabIdentifier.AccountManagement:
      return {
        selectedIcon: appIcons.settings02Solid,
        unselectedIcon: appIcons.settings02,
      }
    case TabIdentifier.Students:
      return {
        selectedIcon: appIcons.users01Solid,
        unselectedIcon: appIcons.users01,
      }
    case TabIdentifier.Tracks:
      return {
        selectedIcon: appIcons.markerPin05Solid,
        unselectedIcon: appIcons.markerPin05,
      }
  }
}

export const getTabTags = (
  tab: TabIdentifier,
): NavigationSidebarItem["tags"] => {
  const tabIdentifier = tabIdentifierToRouteDescriptor[tab]
  const isBeta = tabIdentifier.some((route) => route?.beta)

  return isBeta ? ["beta"] : []
}
