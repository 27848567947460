import {
  CreateMsEventDto,
  MsEventDto,
  MsOfferingCalendarDto,
  MsPrivateCalendarDto,
  ProgramManagementCalendar,
} from "@clients/calendarHubClient"
import { ProgramDto } from "@masterschool/course-builder-api"
import {
  CalendarFilter,
  CalendarFilters,
  CalendarGroupings,
  DoubleNestedCalendarGroupingSubgroup,
  MsCalendarPrivateCalendarProps,
  MsCalendarProps,
  MsEventProps,
  MsOfferingCalendarProps,
  MuiColorPalette,
} from "@masterschool/ui-calendar/dist/src/types"
import { programDisplayName } from "../../programs/program/ui/program+ui"

/*
The file is still WIP,
 we don't have yet the full API, so some values are hardcoded here just to make the app work
*/

export const mapToMsEventDto = (event: MsEventProps): CreateMsEventDto => {
  if (event.type === "unassigned") {
    console.error(`Unsupported event type: ${event.type}`)
    throw new Error(`Unsupported event type: ${event.type}`)
  }
  return {
    title: event.title,
    description: event.description || "",
    start: event.start,
    end: event.end,
    presentAsAllDay: event.allDay || false,
    type: event.type,
    participants: event.participantCalendarIds || [],
    rrule: event.recurringRule || undefined,
    rruleUntil: event.recurringUntil || undefined,
    rruleTzid: event.recurringRuleTzid || undefined,
    hasVc: event.vc?.hasVc || false,
    instructorCalendarId:
      event.type === "live-session" ? event.instructorCalendarId : undefined,
    calendarId: event.calendarId,
    recordingLinks: event.recordingLinks || [],
  }
}

export const mapToMsEventProps = (event: MsEventDto): MsEventProps => {
  return {
    id: event.id,
    title: event.title,
    description: event.description,
    start: new Date(event.start),
    end: new Date(event.end),
    type: event.type,
    allDay: event.presentAsAllDay,
    recurringId: event.recurringGroupID,
    organizerClientId: event.organizerClientId,
    recurringRule: event.rrule || "",
    recurringUntil: event.rruleUntil ? new Date(event.rruleUntil) : undefined,
    recurringRuleTzid: event.rruleTzid || undefined,
    participantCalendarIds: event.participants,
    calendarId: event.calendarId,
    color: event.color,
    vc: event.hasVc ? { hasVc: event.hasVc, url: event.vcUrl } : undefined,
    instructorCalendarId: event.instructorCalendarId,
    recordingLinks: event.recordingLinks,
  } as MsEventProps // TO DO check those typescript errors
}

export const buildMsCalendarGroupingsAndFiltersForProgramOwner = (
  calendars: ProgramManagementCalendar,
  programs: ProgramDto[],
): {
  groupings: CalendarGroupings
  customFilters: CalendarFilters
} => {
  const programToCalendars = calendars.programSchoolCalendars.reduce(
    (acc, calendar) => {
      const program = programs.find(
        (p) => p.id === calendar.offeringDetails.programId,
      )
      if (!program) {
        return acc
      }
      if (!acc[program.id]) {
        acc[program.id] = { title: programDisplayName(program), calendars: [] }
      }
      acc[program.id].calendars.push(mapToMsCalendarSchoolProps(calendar))
      return acc
    },
    {} as Record<string, DoubleNestedCalendarGroupingSubgroup>,
  )

  const groupings: CalendarGroupings = [
    {
      type: "double-nested",
      groupingsType: "classes",
      groupingsAction: "all",
      title: "Classes",
      subGroups: Object.values(programToCalendars),
    },
    {
      title: "Program Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "all",
      calendars: calendars.programStaffCalendars.map((c) =>
        mapToMsPersonalCalendarProps(c),
      ),
    },
    {
      title: "Students",
      type: "single",
      groupingsType: "students",
      groupingsAction: "all",
      calendars: calendars.studentCalendars.map((c) =>
        mapToMsPersonalCalendarProps(c, ["student-name"]),
      ),
    },
    {
      title: "Masterschool Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "invite",
      calendars: calendars.allMasterschoolStaffCalendars.map((c) =>
        mapToMsPersonalCalendarProps(c, ["staff-name"]),
      ),
    },
  ]

  return {
    groupings,
    customFilters: {
      filters: getFilters(),
      relations: "OR",
    },
  }
}

const mapToMsCalendarSchoolProps = (
  calendar: MsOfferingCalendarDto,
): MsOfferingCalendarProps => {
  return {
    id: calendar.id,
    title: calendar.offeringDetails.name,
    color: calendar.color as MuiColorPalette, //to do check this
    type: "offering",
    offeringClientId: calendar.offeringDetails.offeringClientId,
  }
}

const mapToMsPersonalCalendarProps = (
  calendar: MsPrivateCalendarDto,
  filterFields: string[] = [],
): MsCalendarPrivateCalendarProps => {
  return {
    id: calendar.id,
    title: calendar.name,
    color: calendar.color as MuiColorPalette, //to do check this
    type: "private",
    userClientId: calendar.userClientId,
    roles: calendar.roles || [],
    tags: getCalendarTags(calendar, filterFields),
  }
}

export const mapToMsCalendarProps = (
  calendar: MsPrivateCalendarDto | MsOfferingCalendarDto,
): MsCalendarPrivateCalendarProps | MsOfferingCalendarProps => {
  if ("offeringDetails" in calendar) {
    return mapToMsCalendarSchoolProps(calendar)
  } else {
    return mapToMsPersonalCalendarProps(calendar)
  }
}

const getCalendarTags = (calendar: MsPrivateCalendarDto, fields: string[]) => {
  const tags: MsCalendarProps["tags"] = {}

  fields.forEach((field) => {
    switch (field) {
      case "student-name":
      case "staff-name":
        tags[field] = [calendar.name]
        break
      default:
        break
    }
  })

  return tags
}

const getFilters = (): CalendarFilter[] => [
  {
    field: "student-name",
    isMultiSelection: true,
    isRequired: false,
    label: "Student Name",
    operators: ["equals"],
    type: "string",
  },
  {
    field: "staff-name",
    isMultiSelection: true,
    isRequired: false,
    label: "Staff Name",
    operators: ["equals"],
    type: "string",
  },
]
