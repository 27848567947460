import BackButton from "@cmp/buttons/backButton"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { TrackDto } from "@masterschool/course-builder-api"
import appTheme from "../../theme/appTheme"
import { TrackStatusChip } from "./trackStatusChip"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import {
  useGetTrackHours,
  LiveSessionHours,
  SelfLearningHours,
} from "./contentHours"
import { TrackOptionsMenu } from "./trackOptionsMenu"

export function TrackViewHeader(props: {
  track: TrackDto
  isEditMode: boolean
  onClickEdit: () => void
}) {
  const { track, isEditMode, onClickEdit } = props
  const navigate = useNavigate()

  return (
    <Stack
      direction="row"
      sx={{
        py: "20px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        bgcolor: appTheme.palette.primary.contrast,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", left: 24 }}>
        <BackButton
          onClick={() => {
            navigate(`/tracks`)
          }}
        />
      </Box>
      <Box
        sx={{
          width: "600px",
        }}
      >
        <TrackDetails track={track} />
      </Box>
      <Stack
        direction="row"
        gap={1}
        sx={{
          position: "absolute",
          right: 24,
        }}
      >
        <TrackOptionsMenu
          track={track}
          isEditMode={isEditMode}
          onClickEdit={onClickEdit}
        />
        {!isEditMode && (
          <Button
            onClick={onClickEdit}
            size="small"
            variant="contained"
            color="sand50"
          >
            Edit
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

const TrackDetails = (props: { track: TrackDto }) => {
  const { track } = props
  const { liveSessionHours, selfLearningHours } = useGetTrackHours(track)
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Typography variant="body1_sb">{track.externalName}</Typography>
        <TrackStatusChip status={track.status} />
      </Stack>
      <Stack direction="row" gap={2}>
        <AmountOfTerms amount={track.terms.length} />
        <LiveSessionHours hours={liveSessionHours} />
        <SelfLearningHours hours={selfLearningHours} />
      </Stack>
    </Stack>
  )
}
const AmountOfTerms = (props: { amount: number }) => {
  const { amount } = props
  const label = amount === 1 ? "Term" : "Terms"
  return (
    <Stack direction="row" gap={1}>
      <AppIcon
        icon={appIcons.calendar}
        props={{ sx: { width: "16px", height: "16px" } }}
      />
      <Typography variant="body3" color="textSecondary">
        {`${amount} ${label}`}
      </Typography>
    </Stack>
  )
}
