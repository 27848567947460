import { ShiftDto } from "@masterschool/course-builder-api"
import { Box, Button, SvgIcon, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"
import appIcons from "@utils/appIcons"
import { DeleteIcon } from "@cmp/buttons/deleteButton"

const dayToString = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday"
    case 1:
      return "Monday"
    case 2:
      return "Tuesday"
    case 3:
      return "Wednesday"
    case 4:
      return "Thursday"
    case 5:
      return "Friday"
    case 6:
      return "Saturday"
    default:
      return ""
  }
}
const formatDays = (days: number[]) => {
  return days.map((day) => dayToString(day).substring(0, 3)).join(", ")
}

export const ShiftCard = (props: {
  shift: ShiftDto
  canDelete: boolean
  onDeleteShift: (shiftId: string) => void
  onEditShift: (shiftId: string) => void
}) => {
  const { shift, canDelete, onDeleteShift, onEditShift } = props
  const sortedDays = shift.days.map((day) => day)
  sortedDays.sort((a, b) => a - b)
  return (
    <Box
      display="flex"
      flexShrink="0"
      key={shift.id}
      bgcolor={appTheme.palette.eTypes.sand25}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        gap="16px"
        padding="24px"
      >
        <Box
          width="36px"
          height="36px"
          borderRadius="50%"
          bgcolor={appTheme.palette.eTypes.lilac}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon
            component={appIcons.calendar}
            inheritViewBox
            sx={{
              fill: "none",
              width: "20px",
              height: "20px",
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" flex="1" gap="4px">
          <Typography width="auto" variant="body1_sb" paddingBottom="4px">
            {shift.name}
          </Typography>
          <Typography variant="body2">{formatDays(sortedDays)}</Typography>
          <Typography variant="body2">
            {shift.startTime.substring(0, 5)} - {shift.endTime.substring(0, 5)}
          </Typography>
          <Typography variant="body2">EU / Berlin</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop="-8px"
          marginRight="-8px"
        >
          {canDelete ? (
            <Box>
              <Button
                onClick={() => onDeleteShift(shift.id)}
                sx={{ padding: "0" }}
              >
                <DeleteIcon hoverColor="" />
              </Button>

              <Button
                onClick={() => onEditShift(shift.id)}
                sx={{ padding: "8px", margin: "0" }}
              >
                <SvgIcon
                  component={appIcons.edit02}
                  inheritViewBox
                  sx={{
                    fill: "none",
                    width: "16px",
                    height: "16px",
                    stroke: appTheme.palette.icon.black,
                  }}
                />
              </Button>
            </Box>
          ) : (
            <Box height="20px" />
          )}
        </Box>
      </Box>
    </Box>
  )
}
