import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"

const selectTrackState = (state: RootState) => state.track

export const selectTracksById = createSelector(
  [selectTrackState],
  (state) => state.byId,
)

export const selectAllTracks = createSelector([selectTracksById], (byId) =>
  Object.values(byId),
)

export const selectTrackById = createSelector(
  [(_, id: string) => id, (state: RootState) => state.track.byId],
  (id, byId) => byId[id] || null,
)

export const selectTrackLoadingStates = (state: RootState) =>
  state.track.loadingState

export const selectTrackErrors = (state: RootState) => state.track.error
