import GenericDialog from "@cmp/genericDialog"

import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import { Stack, Box, Typography, Button, SvgIcon } from "@mui/material"
import { CourseSearchComponent } from "../../main/courseSearchComponent"
import appTheme from "../../theme/appTheme"
import CourseSelectionButton from "../syllabus/panel/popups/courseSelectionButton"

import { useRef, useState } from "react"
import { UnitCoursesList, UnitCoursesListRef } from "./unitCoursesList"
import appIcons from "@utils/appIcons"

export const CourseSelectionPopup = (props: {
  onClose: () => void
  onSubmit: (selectedCourses: UnitCourseDescriptor[]) => void
  initialSelectedCourses: UnitCourseDescriptor[]
  availableCoursesDescriptors: UnitCourseDescriptor[]
}) => {
  const {
    onClose,
    onSubmit,
    initialSelectedCourses,
    availableCoursesDescriptors,
  } = props

  const listRef = useRef<UnitCoursesListRef>(null)
  const [editedSelectedCourses, setEditedSelectedCourses] = useState(
    initialSelectedCourses,
  )

  const onAddCourse = (courseDescriptor: UnitCourseDescriptor) => {
    setEditedSelectedCourses([...editedSelectedCourses, courseDescriptor])
  }
  const isEmptyUnit = initialSelectedCourses.length === 0

  return (
    <GenericDialog
      onClose={onClose}
      title={isEmptyUnit ? "Choose courses" : "Manage Courses"}
      open
      size="lg"
      disableContentPadding
      fullHeight
      content={
        <Stack
          direction="row"
          flex={1}
          height={1}
          borderTop="1px solid"
          borderColor="other.outlineBorder"
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                padding: "24px",
              }}
            >
              <CourseSearchComponent />
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: "100%",
              }}
            >
              {availableCoursesDescriptors.map((courseDescriptor, index) => (
                <CourseSelectionButton
                  key={courseDescriptor.courseId}
                  courseDescriptor={courseDescriptor}
                  onAddClick={() => {
                    onAddCourse(courseDescriptor)
                    listRef.current?.scrollToBottom()
                  }}
                  disabled={initialSelectedCourses
                    .map((cd: UnitCourseDescriptor) => cd.courseId)
                    .includes(courseDescriptor.courseId)}
                  sx={{
                    borderBottom:
                      index === availableCoursesDescriptors.length - 1
                        ? `1px solid ${appTheme.palette.other.divider}`
                        : undefined,
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "280px",
              maxWidth: "280px",
              backgroundColor: appTheme.palette.background.default,
              borderLeft: `1px solid ${appTheme.palette.other.outlineBorder}`,
              gap: "16px",
              padding: "24px 0px 24px 0px",
              overflowY: "hidden",
              overflowX: "visible",
            }}
          >
            <Typography
              variant="body1_sb"
              sx={{
                padding: "0px 24px 0px 24px",
              }}
            >
              {`Selected Courses (${initialSelectedCourses.length ?? 0})`}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <UnitCoursesList
                ref={listRef}
                selectedCourses={editedSelectedCourses}
                onChangeSelectedCourses={setEditedSelectedCourses}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                margin: "0px 24px",
              }}
              onClick={() => onSubmit(editedSelectedCourses)}
              startIcon={isEmptyUnit ? <PlusIcon /> : undefined}
            >
              {isEmptyUnit ? "Add to unit" : "Save changes"}
            </Button>
          </Box>
        </Stack>
      }
    />
  )
}

const PlusIcon = () => (
  <SvgIcon
    component={appIcons.plus}
    inheritViewBox
    sx={{
      stroke: "white",
    }}
  />
)
