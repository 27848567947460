import { useAppDispatch } from "@app/hooks"
import { fetchAppFeatureFlags } from "@features/app-feature-flags/appFeatureFlagsSlice"
import { fetchCourses } from "@features/coursesMenu/coursesMenuSlice"
import { fetchPrograms } from "@features/program/programSliceThunks"
import { fetchSyllabuses } from "@features/syllabus/syllabusesMenuSlice"
import { fetchTracks } from "@features/tracks/trackSlice"
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

function useFetchAppData() {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const didChangeMainLocation =
      prevLocation?.pathname.split("/")[1] !== location.pathname.split("/")[1]

    if (didChangeMainLocation) {
      dispatch(fetchCourses())
      dispatch(fetchSyllabuses())
      dispatch(fetchTracks())
      dispatch(fetchPrograms())
      dispatch(fetchAppFeatureFlags())
    }
  }, [dispatch, prevLocation, location])
}

function usePrevious<T>(value: T | undefined) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export default useFetchAppData
