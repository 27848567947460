import { GridColDef } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { Chip, Link, Tooltip } from "@mui/material"
import { cohortColor } from "../display.utils"
import { dateStrComparator } from "./column.sort.comparators"
import { ColumnKeys } from "./column.keys"
import { DomainChip } from "@utils/domainChip"
import { domainToDisplayName } from "@utils/domainUtils"
import { ProgramDomain } from "@masterschool/course-builder-api"

export const REGISTRATION_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = [
  {
    field: ColumnKeys.PROGRAM,
    headerName: "Program",
    width: 150,
    type: "singleSelect",
    valueOptions: programOptions,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      const domain = studentRow.registrationDetails?.domain
      if (!domain) return null
      return domainToDisplayName(domain)
    },
    renderCell: (params) => {
      const classData = params.row.registrationDetails
      if (!classData) return null
      const domain = classData.domain
      return <DomainChip domain={domain} />
    },
  },
  {
    field: ColumnKeys.COHORT,
    headerName: "Cohort",
    width: 150,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      const cohort = studentRow.registrationDetails?.cohort
      if (!cohort) return null
      return new Date(cohort).toLocaleString("en-US", {
        month: "long",
        year: "2-digit",
      })
    },
    getSortComparator(sortDirection) {
      return (v1, v2) => dateStrComparator(v1, v2, sortDirection)
    },
    renderCell: (params) => {
      const cohort = params.row.registrationDetails?.cohort
      return (
        <Chip
          label={params.value}
          size="small"
          sx={{
            backgroundColor: cohort ? cohortColor(cohort) : undefined,
          }}
        />
      )
    },
  },
  {
    field: ColumnKeys.REGISTERED_CLASS,
    headerName: "Class",
    width: 200,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return studentRow.registrationDetails?.className
    },
    renderCell: (params) => {
      const classData = params.row.registrationDetails
      if (!classData) return null
      const programId = classData.programId
      const classId = classData.classId
      const href = `/program/${programId}/${classId}`
      return (
        <Tooltip title={classData.className}>
          <Link href={href} target="_blank" variant="body1">
            {classData.className}
          </Link>
        </Tooltip>
      )
    },
  },
]

function programOptions() {
  return Object.values(ProgramDomain)
    .filter((domain) => !domain.includes("TRAINING"))
    .map((domain) => domainToDisplayName(domain))
}
