import { CampusClient } from "./campusClient"

export type FeatureFlag = {
  flag: string
  enabled: boolean
  source: "user" | "offering" | "program" | "cohort"
}

export class FeatureFlagClient {
  static async listFlags(): Promise<string[]> {
    return CampusClient.get<string[]>(`v1/feature-flags/list`)
  }

  static async listUserFlags(userId: string): Promise<FeatureFlag[]> {
    return CampusClient.get<Omit<FeatureFlag[], "enabled">>(
      `v1/feature-flags/user/${userId}`,
    ).then((flags) => flags.map((f) => ({ ...f, enabled: true })))
  }

  static async getLoggedInUserFlags(): Promise<Omit<FeatureFlag, "source">[]> {
    return CampusClient.get<Record<string, boolean>>(`v1/feature-flags`).then(
      (flags) =>
        Object.entries(flags).map(([flag]) => ({
          flag,
          enabled: flags[flag],
        })),
    )
  }

  static updateFlags(
    userId: string,
    featureFlags: { [key: string]: boolean },
  ): Promise<void> {
    return CampusClient.patch(`v1/feature-flags/user/${userId}`, {
      ...featureFlags,
    })
  }
}
