import {
  CourseDescriptorDto,
  TermDto,
  TrackDto,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { Box, Chip, Divider, Stack, Typography } from "@mui/material"
import appTheme from "../../theme/appTheme"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { useAppSelector } from "@app/hooks"
import appIcons from "@utils/appIcons"
import AppIcon from "@utils/appIcon"
import { IconType } from "@utils/iconType"
import {
  LiveSessionHours,
  SelfLearningHours,
  sumCoursesHours,
  useGetTermHours,
} from "./contentHours"

export const TrackContentView = (props: { track: TrackDto }) => {
  const track = props.track
  return (
    <Stack width="600px">
      <Typography variant="h4" pb={2}>
        {track.externalName}
      </Typography>
      <Typography variant="body1_sb" pb={2}>
        {track.internalName}
      </Typography>

      <Stack gap={2}>
        {track.terms.map((term, index) => (
          <Term key={term.id} term={term} index={index + 1} />
        ))}
      </Stack>
      <Stack sx={{ width: "100%", p: 4 }} />
    </Stack>
  )
}

const Term = (props: { term: TermDto; index: number }) => {
  const { term, index } = props
  const { liveSessionHours, selfLearningHours } = useGetTermHours(term)
  return (
    <Stack
      bgcolor="primary.contrast"
      border="1px solid"
      borderColor="other.outlineBorder"
      borderRadius={2}
    >
      <Stack
        direction="row"
        sx={{
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          backgroundColor: appTheme.palette.eTypes.sand15,
        }}
        px={3}
        py={1}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2" flex={1}>
            Term {index}
          </Typography>
          <Stack direction="row" gap={1}>
            <LiveSessionHours hours={liveSessionHours} />
            <SelfLearningHours hours={selfLearningHours} />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        {term.courseDescriptors.map((courseDescriptor, index) => (
          <Box key={courseDescriptor.courseId}>
            <Course
              courseDescriptor={courseDescriptor}
              key={courseDescriptor.courseId}
            />
            {index < term.courseDescriptors.length - 1 && <Divider />}
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

const Course = (props: { courseDescriptor: UnitCourseDescriptor }) => {
  const { courseDescriptor } = props
  const course = useAppSelector(
    selectLatestPublishedCourse(courseDescriptor.courseId),
  )

  if (!course) {
    return (
      <Stack gap={2}>
        <Typography variant="body2" color="error">
          Unable to retrieve course data.
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack gap={2} p={3}>
      <Typography variant="body2">{course.title}</Typography>
      <CourseOverviewBar course={course} />
    </Stack>
  )
}

export const CourseOverviewBar = (props: { course: CourseDescriptorDto }) => {
  const { course } = props
  const { selfLearningHours, liveSessionHours } = sumCoursesHours([course])
  return (
    <Stack direction="row" gap={1}>
      <IconChip icon={appIcons.playCircle} title={`${selfLearningHours}h`} />
      <IconChip icon={appIcons.bookOpen01} title={`${liveSessionHours}h`} />
    </Stack>
  )
}

const IconChip = (props: { icon: IconType; title: string }) => {
  return (
    <Chip
      size="small"
      icon={<AppIcon icon={props.icon} />}
      label={props.title}
      sx={{ px: 1.23, backgroundColor: appTheme.palette.eTypes.sand25 }}
    />
  )
}
