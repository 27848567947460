import FormDateTimePicker from "@cmp/form-components/formDateTimePicker"
import { ProgramDetailsFormModel } from "@features/program/programSlice"
import { ProgramDomain, ProgramType } from "@masterschool/course-builder-api"
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"

export default function ProgramDetailsForm(props: {
  domains: ProgramDomain[] | undefined
  allowMultipleDomains: boolean
  type: "full_time" | "part_time" | undefined
  language: string | undefined
  startDate: string | undefined
  durationInMonths: number | undefined
  onChange: (
    payload: Partial<ProgramDetailsFormModel & { domains: ProgramDomain[] }>,
  ) => void
}) {
  const { domains, type, language, startDate, durationInMonths, onChange } =
    props
  const { allowMultipleDomains } = props
  const domainOptions = Object.values(ProgramDomain)

  const domainsOnChange = allowMultipleDomains
    ? (e: any) => {
        onChange({ domains: e.target.value as ProgramDomain[] })
      }
    : (e: any) => {
        onChange({ domain: e.target.value as ProgramDomain })
      }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        mt: "16px",
      }}
    >
      <ProgramTypeControl
        value={type ?? ""}
        onChange={(type) => {
          onChange({
            type: type as ProgramType,
          })
        }}
      />
      <FormControl fullWidth required>
        <InputLabel
          sx={{
            fontSize: "17px",
          }}
        >
          Domain(s)
        </InputLabel>
        <Select
          label="Domain(s)"
          value={domains ?? []}
          onChange={domainsOnChange}
          multiple={allowMultipleDomains}
          renderValue={(selected) =>
            selected.map((domain, i) => (
              <Chip
                key={domain}
                label={domainToDisplayName(domain)}
                size="small"
                sx={{ ml: i ? 1 : 0 }}
              />
            ))
          }
        >
          {domainOptions.map((domain) => (
            <MenuItem value={domain} key={domain}>
              {domainToDisplayName(domain)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth required>
        <InputLabel
          sx={{
            fontSize: "17px",
          }}
        >
          Language
        </InputLabel>
        <Select
          label="Language"
          value={language ?? ""}
          onChange={(e) => onChange({ language: e.target.value })}
        >
          <MenuItem value="german">German 🇩🇪</MenuItem>
          <MenuItem value="english">English 🇬🇧</MenuItem>
        </Select>
      </FormControl>
      <FormDateTimePicker
        required
        label="Start date"
        value={startDate ?? null}
        onChange={(value: any) => onChange({ startDate: String(value) })}
      />
      <FormControl fullWidth required>
        <InputLabel
          sx={{
            fontSize: "17px",
          }}
        >
          Duration
        </InputLabel>
        <Select
          label="Duration"
          value={durationInMonths ?? ""}
          onChange={(e) =>
            onChange({
              durationInMonths: Number(e.target.value as string),
            })
          }
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((months) => (
            <MenuItem value={months} key={months}>
              {`${months} months`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const ProgramTypeControl = (props: {
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <FormControl fullWidth required>
      <RadioGroup
        row
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value)
        }}
        sx={{ gap: "40px" }}
      >
        <FormControlLabel
          value="full_time"
          control={<Radio />}
          label="Full-time"
        />
        <FormControlLabel
          value="part_time"
          control={<Radio />}
          label="Part-time"
        />
      </RadioGroup>
    </FormControl>
  )
}
