import { useAppDispatch, useAppSelector } from "@app/hooks"
import FormDateTimePicker from "@cmp/form-components/formDateTimePicker"
import GenericDialog from "@cmp/genericDialog"
import {
  deleteMultipleProgramsConfirmed,
  editMultipleProgramProperties,
} from "@features/program/programSliceThunks"
import {
  MultiProgramChangeDomainPopup,
  MultiProgramChangeScopePopup,
  MultiProgramChangeStartDatePopup,
  MultiProgramsPopupType,
  popupStateChanged,
} from "@features/program/programsMenuSlice"
import { selectMainPagePrograms } from "@features/search/searchSlice"
import { ProgramDomain } from "@masterschool/course-builder-api"
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { useState } from "react"

function MultiProgramDomainChangePopup({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const updatePopup = (data: MultiProgramChangeDomainPopup) =>
    dispatch(
      popupStateChanged({
        type: MultiProgramsPopupType.MultiProgramChangeDomain,
        data,
      }),
    )

  const data = useAppSelector((state) => {
    if (
      state.programsMenu.presentedPopup?.type !==
      MultiProgramsPopupType.MultiProgramChangeDomain
    ) {
      return undefined
    }
    return state.programsMenu.presentedPopup.data
  })

  const selectedProgramIds = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )
  if (!data || selectedProgramIds.length === 0) {
    return null
  }
  const { newDomain } = data
  const handleSubmit = async () => {
    if (!newDomain || !isDomainValue(newDomain)) {
      return
    }
    setIsLoading(true)
    dispatch(
      editMultipleProgramProperties(
        selectedProgramIds.map((id) => ({
          programId: id,
          editedProperties: { domain: newDomain },
        })),
      ),
    ).finally(() => {
      setIsLoading(false)
      onClose()
    })
  }

  const title =
    selectedProgramIds.length === 1
      ? "Move program to a different domain"
      : `Move ${selectedProgramIds.length} programs to another domain`
  const domainOptions = Object.values(ProgramDomain)

  return (
    <GenericDialog
      title={title}
      open={Boolean(data)}
      onClose={onClose}
      size="xs"
      content={
        <Box sx={{ pt: "8px" }}>
          <FormControl fullWidth required>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Domain
            </InputLabel>
            <Select
              label="Domain"
              value={newDomain || ""}
              onChange={(e) => {
                const value = e.target.value
                if (!isDomainValue(value)) {
                  return
                }
                updatePopup({ newDomain: value })
              }}
              renderValue={(domain) => (
                <Chip
                  key={domain}
                  label={domainToDisplayName(domain)}
                  size="small"
                />
              )}
            >
              {domainOptions.map((domain) => (
                <MenuItem value={domain} key={domain}>
                  {domainToDisplayName(domain)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      }
      buttons={[
        {
          type: "primary",
          text: "Move programs",
          onClick: handleSubmit,
          loading: isLoading,
          disabled: !newDomain || !isDomainValue(newDomain),
        },
      ]}
    />
  )
}

function MultiProgramScopeChangePopup({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch()
  const updatePopup = (data: MultiProgramChangeScopePopup) =>
    dispatch(
      popupStateChanged({
        type: MultiProgramsPopupType.MultiProgramChangeScope,
        data,
      }),
    )

  const [isLoading, setIsLoading] = useState(false)
  const data = useAppSelector((state) => {
    if (
      state.programsMenu.presentedPopup?.type !==
      MultiProgramsPopupType.MultiProgramChangeScope
    ) {
      return undefined
    }
    return state.programsMenu.presentedPopup.data
  })

  const selectedProgramIds = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )

  if (!data || selectedProgramIds.length === 0) {
    return null
  }
  const { durationInMonths } = data

  const durationOptions = Array.from({ length: 12 }, (_, i) => i + 1)
  const title =
    selectedProgramIds.length === 1
      ? "Change scope for 1 program"
      : `Change scope for ${selectedProgramIds.length} programs`

  const handleSubmit = async () => {
    setIsLoading(true)
    dispatch(
      editMultipleProgramProperties(
        selectedProgramIds.map((id) => ({
          programId: id,
          editedProperties: { durationInMonths },
        })),
      ),
    ).finally(() => {
      setIsLoading(false)
      onClose()
    })
  }

  return (
    <GenericDialog
      title={title}
      open={Boolean(data)}
      onClose={onClose}
      size="xs"
      content={
        <Box sx={{ pt: "8px" }}>
          <FormControl fullWidth required>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Scope
            </InputLabel>
            <Select
              label="Scope"
              value={durationInMonths || ""}
              onChange={(e) => {
                const value = e.target.value
                if (typeof value !== "number") {
                  return
                }
                updatePopup({
                  durationInMonths: value,
                })
              }}
              renderValue={(durationInMonths) => (
                <Chip
                  key={durationInMonths}
                  label={`${durationInMonths} months`}
                  size="small"
                />
              )}
            >
              {durationOptions.map((durationInMonths) => (
                <MenuItem value={durationInMonths} key={durationInMonths}>
                  {`${durationInMonths} months`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      }
      buttons={[
        {
          type: "primary",
          text: "Change scope",
          onClick: handleSubmit,
          loading: isLoading,
          disabled: !durationInMonths,
        },
      ]}
    />
  )
}

function MultiProgramStartDateChangePopup({
  onClose,
}: {
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const updatePopup = (data: MultiProgramChangeStartDatePopup) =>
    dispatch(
      popupStateChanged({
        type: MultiProgramsPopupType.MultiProgramChangeStartDate,
        data,
      }),
    )

  const [isLoading, setIsLoading] = useState(false)
  const data = useAppSelector((state) => {
    if (
      state.programsMenu.presentedPopup?.type !==
      MultiProgramsPopupType.MultiProgramChangeStartDate
    ) {
      return undefined
    }
    return state.programsMenu.presentedPopup.data
  })
  const selectedProgramIds = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )
  if (!data || selectedProgramIds.length === 0) {
    return null
  }
  const { startDate } = data
  const title =
    selectedProgramIds.length === 1
      ? "Set new start date for 1 program"
      : `Set new start date for ${selectedProgramIds.length} programs`

  const handleSubmit = async () => {
    setIsLoading(true)
    dispatch(
      editMultipleProgramProperties(
        selectedProgramIds.map((id) => ({
          programId: id,
          editedProperties: { startDate },
        })),
      ),
    ).finally(() => {
      setIsLoading(false)
      onClose()
    })
  }

  return (
    <GenericDialog
      title={title}
      open={Boolean(data)}
      onClose={onClose}
      size="xs"
      content={
        <FormDateTimePicker
          required
          label="Start date"
          value={startDate ?? null}
          onChange={(value: any) => {
            updatePopup({
              startDate: value,
            })
          }}
        />
      }
      buttons={[
        {
          type: "primary",
          text: "Set date",
          onClick: handleSubmit,
          loading: isLoading,
          disabled: !startDate,
        },
      ]}
    />
  )
}

function MultiProgramDeletionPopup({ onClose }: { onClose: () => void }) {
  const selectedProgramIds = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )
  const programs = useAppSelector(selectMainPagePrograms)
  const isOpen = useAppSelector(
    (state) =>
      state.programsMenu.presentedPopup?.type ===
      MultiProgramsPopupType.MultiProgramDelete,
  )
  const doProgramsHaveClasses = selectedProgramIds.some((id) => {
    const program = programs.find((program) => program.id === id)
    return (program?.classesIds || []).length > 0
  })

  if (selectedProgramIds.length === 0) {
    return null
  }

  return doProgramsHaveClasses ? (
    <RemoveAllClassesNeededPopup onClose={onClose} isOpen={isOpen} />
  ) : (
    <DeleteProgramConfirmationPopup onClose={onClose} isOpen={isOpen} />
  )
}

function RemoveAllClassesNeededPopup({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <GenericDialog
      onClose={onClose}
      open={isOpen}
      size="xs"
      title="Can’t delete programs"
      subtitle="Remove all classes from the programs to delete them."
      buttons={[
        {
          type: "primary",
          text: "Got it",
          onClick: onClose,
        },
      ]}
    />
  )
}

function DeleteProgramConfirmationPopup({
  onClose,
  isOpen,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const selectedProgramIds = useAppSelector(
    (state) => state.programsMenu.selectedProgramIds,
  )
  const amountOfPrograms = selectedProgramIds.length
  const dispatch = useAppDispatch()
  const onConfirm = () =>
    dispatch(deleteMultipleProgramsConfirmed(selectedProgramIds))
  const title =
    amountOfPrograms > 1
      ? `Delete ${selectedProgramIds.length} programs`
      : "Delete program"

  return (
    <GenericDialog
      open={isOpen}
      onClose={onClose}
      title={title}
      content="This can't be undone."
      size="xs"
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "danger",
          text: title,
          onClick: onConfirm,
        },
      ]}
    />
  )
}

export default function MultiProgramPopups({
  onClose,
}: {
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(popupStateChanged(undefined))
    onClose()
  }
  return (
    <>
      <MultiProgramDomainChangePopup onClose={handleClose} />
      <MultiProgramScopeChangePopup onClose={handleClose} />
      <MultiProgramStartDateChangePopup onClose={handleClose} />
      <MultiProgramDeletionPopup onClose={handleClose} />
    </>
  )
}

function isDomainValue(value: string): value is ProgramDomain {
  return Object.values(ProgramDomain).includes(value as ProgramDomain)
}
