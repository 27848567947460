import { Box } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { coursesColumns } from "./courseMajorsColumns"
import appTheme from "../../../theme/appTheme"
import { CourseDescriptorDto } from "@masterschool/course-builder-api"

export function CourseMajorsTable({
  courseMajors,
}: {
  courseMajors: CourseDescriptorDto[]
}) {
  return (
    <Box
      sx={{
        maxWidth: "1240px",
        maxHeight: "100%",
        bgcolor: "eTypes.sand15",
      }}
    >
      <DataGridPro
        rows={courseMajors}
        columns={coursesColumns}
        rowHeight={78}
        disableColumnMenu
        disableRowSelectionOnClick
        disableColumnReorder
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}
