import { GridColDef } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { Box, Tooltip, Typography } from "@mui/material"
import { emojiForCSATFeedback } from "../display.utils"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import clsx from "clsx"
import { numberSorter } from "./column.sort.comparators"
import { ColumnKeys } from "./column.keys"

export const CSAT_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = [
  {
    field: ColumnKeys.LAST_CSAT_SCORE,
    headerName: "Last CSAT Score",
    type: "number",
    width: 150,
    valueGetter: (value, row) => row.status.satisfaction.csat?.score,
    getSortComparator(sortDirection) {
      return (v1, v2) => numberSorter(v1, v2, sortDirection)
    },
    cellClassName: (params) =>
      clsx("cell", {
        "is-flagged": params.value < 4,
      }),
    renderCell: (params) => {
      const value = params.row.status.satisfaction.csat
      if (!value) return null
      const timeString = new Date(value.submittedAt).toLocaleString()
      return (
        <Tooltip placement="right-end" title={`Submitted at ` + timeString}>
          <Box
            display="flex"
            sx={{
              height: "100%",
              px: "10px",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              {params.value} {emojiForCSATFeedback(params.value)}
            </Typography>
          </Box>
        </Tooltip>
      )
    },
  },
  {
    field: ColumnKeys.LAST_NPS_SCORE,
    headerName: "Last NPS Score",
    width: 150,
    type: "number",
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return studentRow.status.satisfaction.nps?.score
    },
    getSortComparator(sortDirection) {
      return (v1, v2) => numberSorter(v1, v2, sortDirection)
    },
    cellClassName: (params) =>
      clsx("cell", {
        "is-flagged": params.value < 8,
      }),
    renderCell: (params) => {
      const value = params.row.status.satisfaction.nps
      if (!value) return null
      const timeString = new Date(value.submittedAt).toLocaleString()
      return (
        <Tooltip placement="right-end" title={`Submitted at ` + timeString}>
          <Box
            display="flex"
            sx={{
              height: "100%",
              px: "10px",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{params.value}</Typography>
            <AppIcon
              icon={appIcons.star01}
              props={{ sx: { fill: "orange", width: "20px", height: "20px" } }}
            />
          </Box>
        </Tooltip>
      )
    },
  },
]
