import { Chip } from "@mui/material"

export function ProgramStatusChip(props: {
  startDate: Date
  durationInMonths: number
}) {
  const { startDate, durationInMonths } = props
  const getLabel = () => {
    const endDate = new Date(startDate)
    endDate.setMonth(endDate.getMonth() + durationInMonths)
    const now = new Date()
    if (now < startDate) {
      return "Upcoming"
    }
    if (now > endDate) {
      return "Ended"
    }
    return "Active"
  }
  const label = getLabel()
  const color = statusToColor(label)
  return <Chip label={label} size="small" variant="outlined" color={color} />
}

function statusToColor(status: "Upcoming" | "Ended" | "Active") {
  switch (status) {
    case "Upcoming":
      return "brightBlue"
    case "Active":
      return "success"
    case "Ended":
      return "default"
  }
}
