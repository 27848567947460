import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "@app/hooks"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { StudentProfilePanel } from "./studentProfilePanel"
import { StudentsTableGrid } from "./students-grid/studentsTable.grid"
import { selectStudentsTableData } from "@features/students-tab/studentsTab.selectors"
import { fetchStudentsData } from "@features/students-tab/studentsTab.slice"

export function StudentsTabView() {
  const dispatch = useAppDispatch()
  const data = useAppSelector(selectStudentsTableData)
  const [studentProfilePanelOpen, setStudentProfilePanelOpen] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
    null,
  )
  useEffect(() => {
    if (data === "idle") {
      dispatch(fetchStudentsData())
    }
  }, [data, dispatch])

  const isLoading = data === "pending"
  const isDataReady = typeof data !== "string"

  const onOpenProfileClicked = (studentId: string) => {
    setSelectedStudentId(studentId)
    setStudentProfilePanelOpen(true)
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
        paddingX: 3,
        paddingTop: "80px",
      }}
    >
      <StudentProfilePanel
        studentId={selectedStudentId}
        open={studentProfilePanelOpen}
        onClose={() => setStudentProfilePanelOpen(false)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          gap: "16px",
          overflowX: "hidden",
        }}
      >
        <TitleSection isLoading={isLoading} />
        <StudentsTableGrid
          data={isDataReady ? data : []}
          onOpenProfileClicked={onOpenProfileClicked}
        />
      </Box>
    </Box>
  )
}

const TitleSection = (props: { isLoading: boolean }) => {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="h4">Students</Typography>
      {props.isLoading && <LoadingComponent loadedComponents="data" />}
    </Box>
  )
}
