import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { ClientResponse, CourseClient } from "@clients/courseClient"
import { arrayMove } from "@dnd-kit/sortable"
import {
  CourseDto,
  CourseStatus,
  CourseItemValidation,
  CourseSyllabusElement,
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCourse } from "../syllabusEditor/syllabusEditorSlice"
import { CourseEditingStep } from "./courseEditorUtils"
import {
  pushNewStep,
  topicEntryAdded,
  topicEntryEdited,
  flattenValidations,
  moveTopic,
  moveElement,
  moveElementToTopic,
  moveElementToEndOfTopic,
  moveLiveEventToEndOfTopic,
} from "./courseEditorSliceHelper"
import { decrementMinor, parseStringVersion } from "@utils/versionUtils"

type EditingStepStack = Array<CourseEditingStep>

export interface CourseEditorState {
  steps: EditingStepStack
  currentStep: number
  lastPublishedVersion: CourseDto | undefined
  lastUndoRedoId: string | undefined
  draggedId: string | undefined // topic or element
  draggedTaskId: string | undefined
  isDraggingTasksEnabled: boolean
  showUnpublishedChangesWarning: boolean
  showPublishDialogConfirmation: boolean
  showDiscardChangesConfirmation: boolean
  showDiscardDraftConfirmation: boolean
  save: {
    status: "idle" | "pending" | "success" | "error"
    lastSavedStepId: string | undefined
    userRequested: boolean
  }
  publishValidations:
    | "idle"
    | "pending"
    | {
        [key: string]: string[]
      }
  generalInfoEditedFields: {
    [key: string]: boolean
  }
  topicsOpenState: {
    [key: string]: boolean
  }
  highlightedItem:
    | {
        elementId: string | undefined
        topicId: string
      }
    | undefined
}

const initialState: CourseEditorState = {
  steps: [],
  currentStep: 0,
  lastPublishedVersion: undefined,
  lastUndoRedoId: undefined,
  draggedId: undefined,
  draggedTaskId: undefined,
  isDraggingTasksEnabled: false,
  showUnpublishedChangesWarning: false,
  showPublishDialogConfirmation: false,
  showDiscardChangesConfirmation: false,
  showDiscardDraftConfirmation: false,
  save: {
    status: "idle",
    lastSavedStepId: undefined,
    userRequested: false,
  },
  publishValidations: "idle",
  generalInfoEditedFields: {},
  topicsOpenState: {},
  highlightedItem: undefined,
}

export const courseEditorSlice = createSlice({
  name: "courseEditor",
  initialState,
  reducers: {
    courseEdited: <K extends keyof CourseDto>(
      state: CourseEditorState,
      action: PayloadAction<{
        editStepId: string
        key: K
        value: CourseDto[K]
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const updatedCourse: CourseDto = {
        ...course,
        [action.payload.key]: action.payload.value,
      }
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Update course ${action.payload.key}`,
      })
      state.generalInfoEditedFields[action.payload.key] = true
    },
    topicAdded: (
      state,
      action: PayloadAction<{
        editStepId: string
        topic: Topic
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const updatedCourse: CourseDto = {
        ...course,
        syllabus: {
          ...course.syllabus,
          topics: course.syllabus.topics.concat(action.payload.topic),
        },
      }
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Add topic ${action.payload.topic.id}`,
      })
      state.topicsOpenState[action.payload.topic.id] = true
      state.highlightedItem = {
        topicId: action.payload.topic.id,
        elementId: undefined,
      }
    },
    topicRemoved: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const topicTitle = course.syllabus.topics.find(
        (t) => t.id === action.payload.topicId,
      )?.title
      const updatedCourse: CourseDto = {
        ...course,
        syllabus: {
          ...course.syllabus,
          topics: course.syllabus.topics.filter(
            (t) => t.id !== action.payload.topicId,
          ),
        },
      }
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Remove topic ${
          topicTitle && topicTitle.length > 0
            ? topicTitle
            : action.payload.topicId
        }`,
      })
    },
    topicEdited: <K extends keyof Topic>(
      state: CourseEditorState,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        key: K
        value: Topic[K]
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const topic = course.syllabus.topics.find(
        (t) => t.id === action.payload.topicId,
      )
      if (!topic) {
        return
      }
      const updatedTopic: Topic = {
        ...topic,
        [action.payload.key]: action.payload.value,
      }
      const updatedCourse: CourseDto = {
        ...course,
        syllabus: {
          ...course.syllabus,
          topics: course.syllabus.topics.map((t) =>
            t.id === action.payload.topicId ? updatedTopic : t,
          ),
        },
      }
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Edit topic ${action.payload.key} to ${action.payload.value}`,
      })
    },
    elementAdded: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        element: CourseSyllabusElement
      }>,
    ) => {
      topicEntryAdded(state, {
        editStepId: action.payload.editStepId,
        topicId: action.payload.topicId,
        element: action.payload.element,
      })
    },
    liveEventAdded: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        liveEvent: CourseSyllabusLiveEvent
      }>,
    ) => {
      topicEntryAdded(state, {
        editStepId: action.payload.editStepId,
        topicId: action.payload.topicId,
        liveEvent: action.payload.liveEvent,
      })
    },
    elementRemoved: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        elementId: string
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const topic = course.syllabus.topics.find(
        (t) => t.id === action.payload.topicId,
      )
      if (!topic) {
        return
      }

      const removedElement = topic.elements.find(
        (e) => e.item.id === action.payload.elementId,
      )

      const removedLiveEvent = topic.liveEvents.find(
        (le) => le.id === action.payload.elementId,
      )

      const updatedTopic = {
        ...topic,
        elements: removedElement
          ? topic.elements.filter((e) => e.item.id !== action.payload.elementId)
          : topic.elements,
        liveEvents: removedLiveEvent
          ? topic.liveEvents.filter((le) => le.id !== action.payload.elementId)
          : topic.liveEvents,
      }

      const updatedCourse: CourseDto = {
        ...course,
        syllabus: {
          ...course.syllabus,
          topics: course.syllabus.topics.map((t) =>
            t.id === action.payload.topicId ? updatedTopic : t,
          ),
        },
      }
      const entryCaption = removedLiveEvent
        ? `Live Event ${
            removedLiveEvent.title ?? removedLiveEvent.id
          } removed from ${topic.id}`
        : `Element ${
            removedElement?.item.title ?? removedElement?.item.id
          } removed from ${topic.id}`

      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: entryCaption,
      })
    },
    elementEdited: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        element: CourseSyllabusElement
      }>,
    ) => {
      topicEntryEdited(state, {
        editStepId: action.payload.editStepId,
        topicId: action.payload.topicId,
        element: action.payload.element,
      })
    },
    liveEventEdited: (
      state,
      action: PayloadAction<{
        editStepId: string
        topicId: string
        liveEvent: CourseSyllabusLiveEvent
      }>,
    ) => {
      topicEntryEdited(state, {
        editStepId: action.payload.editStepId,
        topicId: action.payload.topicId,
        liveEvent: action.payload.liveEvent,
      })
    },
    undo: (state, action: PayloadAction<{ id: string }>) => {
      if (state.currentStep === 0) {
        return
      }
      state.currentStep -= 1
      state.lastUndoRedoId = action.payload.id
    },
    redo: (state, action: PayloadAction<{ id: string }>) => {
      if (state.currentStep === state.steps.length - 1) {
        return
      }
      state.currentStep += 1
      state.lastUndoRedoId = action.payload.id
    },
    dragStarted: (state, action: PayloadAction<{ id: string }>) => {
      state.draggedId = action.payload.id
    },
    dragEnded: (state) => {
      state.draggedId = undefined
    },
    elementDraggedOver: (
      state,
      action: PayloadAction<{
        editStepId: string
        active: {
          containerId: string | undefined
          index: number | undefined
        }
        over: {
          containerId: string | undefined
          index: number | undefined
        }
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const { active, over } = action.payload
      if (active.index === undefined || over.index === undefined) {
        return
      }

      let updatedCourse = {
        ...course,
      }

      if (active.containerId === course.id) {
        updatedCourse = moveTopic(course, active.index, over.index)
      } else if (
        active.containerId &&
        active.containerId === over.containerId
      ) {
        updatedCourse = moveElement(
          course,
          active.containerId,
          active.index,
          over.index,
        )
      } else if (active.containerId && over.containerId) {
        updatedCourse = moveElementToTopic(
          course,
          active.containerId,
          over.containerId,
          active.index,
          over.index,
        )
      } else {
        return
      }

      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Drag element`,
      })
    },
    taskDraggedOver: (
      state,
      action: PayloadAction<{
        editStepId: string
        lessonId: string
        sourceIndex: number
        targetIndex: number
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const topic = course.syllabus.topics.find((t) =>
        t.elements.some((e) => e.item.id === action.payload.lessonId),
      )
      const lesson = topic?.elements.find(
        (e) => e.item.id === action.payload.lessonId,
      )

      if (!topic || !lesson) {
        return
      }

      const castedLesson = lesson.item as {
        tasks: any[]
      }

      if (!Array.isArray(castedLesson.tasks)) {
        return
      }

      const updatedLesson = {
        ...castedLesson,
        tasks: arrayMove(
          castedLesson.tasks,
          action.payload.sourceIndex,
          action.payload.targetIndex,
        ),
      }
      const updatedTopic = {
        ...topic,
        elements: topic.elements.map((e) =>
          e.item.id === action.payload.lessonId
            ? {
                ...e,
                item: updatedLesson,
              }
            : e,
        ),
      } as Topic
      const updatedCourse = {
        ...course,
        syllabus: {
          ...course.syllabus,
          topics: course.syllabus.topics.map((t) =>
            t.id === topic.id ? updatedTopic : t,
          ),
        },
      }
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Drag task`,
      })
    },
    dragTaskStarted: (state, action: PayloadAction<{ id: string }>) => {
      state.draggedTaskId = action.payload.id
    },
    dragTaskEnded: (state) => {
      state.draggedTaskId = undefined
    },
    setDraggingTasksEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDraggingTasksEnabled = action.payload
    },
    publishConfirmationDialogClosed: (state) => {
      state.showPublishDialogConfirmation = false
    },
    closedRequestedWithUnpublishedChanges: (state) => {
      state.showUnpublishedChangesWarning = true
    },
    unpublishedChangesPopupClosed: (state) => {
      state.showUnpublishedChangesWarning = false
    },
    discardChangesRequested: (state) => {
      state.showDiscardChangesConfirmation = true
    },
    discardChangesConfirmationClosed: (state) => {
      state.showDiscardChangesConfirmation = false
    },
    discardChangesApproved: (state) => {
      state.steps.splice(1, state.steps.length)
      state.currentStep = 0
      state.showDiscardChangesConfirmation = false
      state.save = {
        status: "idle",
        lastSavedStepId: undefined,
        userRequested: false,
      }
    },
    discardDraftRequested: (state) => {
      state.showDiscardDraftConfirmation = true
    },
    discardDraftConfirmationClosed: (state) => {
      state.showDiscardDraftConfirmation = false
    },
    elementMovedToTopic: (
      state,
      action: PayloadAction<{
        editStepId: string
        sourceTopicId: string
        targetTopicId: string
        elementId: string
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const updatedCourse = moveElementToEndOfTopic(
        course,
        action.payload.sourceTopicId,
        action.payload.elementId,
        action.payload.targetTopicId,
      )
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Move element from ${action.payload.sourceTopicId} to ${action.payload.targetTopicId}`,
      })
      state.topicsOpenState[action.payload.targetTopicId] = true
      state.highlightedItem = {
        topicId: action.payload.targetTopicId,
        elementId: action.payload.elementId,
      }
    },
    liveEventElementMovedToTopic: (
      state,
      action: PayloadAction<{
        editStepId: string
        sourceTopicId: string
        targetTopicId: string
        liveEventId: string
      }>,
    ) => {
      const course = state.steps[state.currentStep].value
      const updatedCourse = moveLiveEventToEndOfTopic(
        course,
        action.payload.sourceTopicId,
        action.payload.liveEventId,
        action.payload.targetTopicId,
      )
      pushNewStep(state, {
        stepId: action.payload.editStepId,
        value: updatedCourse,
        caption: `Move live event from ${action.payload.sourceTopicId} to ${action.payload.targetTopicId}`,
      })
      state.topicsOpenState[action.payload.targetTopicId] = true
    },
    topicOpenStateChanged: (
      state,
      action: PayloadAction<{
        topicId: string
      }>,
    ) => {
      state.topicsOpenState[action.payload.topicId] =
        !state.topicsOpenState[action.payload.topicId]
    },
    unmounted: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourse.pending, (state, action) => {
        return initialState
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        state.steps.splice(0, state.steps.length)
        const { course, lastPublished } = action.payload
        pushNewStep(state, {
          stepId: course.id,
          value: course,
          caption: `Fetch course ${course.name}`,
        })
        const firstTopicId = course.syllabus.topics[0]?.id
        state.topicsOpenState = course.syllabus.topics
          .map((t) => t.id)
          .reduce((acc, id) => {
            acc[id] = id === firstTopicId
            return acc
          }, {} as { [key: string]: boolean })
        state.lastPublishedVersion = lastPublished
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.steps.splice(0, state.steps.length)
      })
      .addCase(silentSaveCourse.pending, (state, action) => {
        state.save.status = "pending"
        state.save.userRequested = action.meta.arg.userRequested
      })
      .addCase(silentSaveCourse.fulfilled, (state, action) => {
        state.save.status = "success"
        state.save.lastSavedStepId = state.steps[state.currentStep]?.stepId
      })
      .addCase(silentSaveCourse.rejected, (state, action) => {
        state.steps.splice(0, state.steps.length)
      })
      .addCase(validateCourseForPreview.pending, (state) => {
        state.publishValidations = "pending"
      })
      .addCase(validateCourseForPreview.fulfilled, (state, action) => {
        state.publishValidations = action.payload
      })
      .addCase(validateCourseForPreview.rejected, (state) => {
        state.publishValidations = "idle"
      })
      .addCase(validateCourse.pending, (state, action) => {
        state.publishValidations = "pending"
        state.showUnpublishedChangesWarning = false
      })
      .addCase(validateCourse.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }
        state.publishValidations = flattenValidations(action.payload)
        if (Object.keys(state.publishValidations).length === 0) {
          state.showPublishDialogConfirmation = true
        }
      })
      .addCase(validateCourse.rejected, (state, action) => {
        state.publishValidations = "idle"
      })
      .addCase(publishCourse.pending, (state, action) => {
        state.publishValidations = "pending"
      })
      .addCase(publishCourse.fulfilled, (state, action) => {
        if (!action.payload) {
          return
        }

        if (action.payload.kind === "failure") {
          state.publishValidations = flattenValidations(action.payload.value)
          return
        }

        state.steps.splice(0, state.steps.length)
        const course = action.payload.value.publishedVersion

        if (!course) return

        pushNewStep(state, {
          stepId: course.id,
          value: course,
          caption: `publish course ${course.name}`,
        })

        state.showPublishDialogConfirmation = false
        state.showUnpublishedChangesWarning = false
      })
      .addCase(publishCourse.rejected, (state, action) => {
        state.publishValidations = "idle"
      })
      .addCase(discardDraftConfirmed.fulfilled, (state, action) => {
        return initialState
      })
      .addCase(discardDraftConfirmed.rejected, (state, action) => {
        state.showDiscardChangesConfirmation = false
      })
  },
})

export const fetchCourse = createAsyncThunk(
  "courseEditor/fetchCourse",
  async (courseMeta: { courseId: string; version: string }, thunkAPI) => {
    const course = await CourseClient.getCourseByVersion(
      courseMeta.courseId,
      courseMeta.version,
    )
    if (course.status === CourseStatus.Published) {
      return { course, lastPublished: course }
    }

    const lastPublished = await CourseClient.getLastPublishedVersion(
      courseMeta.courseId,
    )

    return { course, lastPublished }
  },
)

export const silentSaveCourse = createAppAsyncThunk(
  "courseEditor/silentSaveCourse",
  async (requestMeta: { userRequested: boolean }, thunkAPI) => {
    const currentStep =
      thunkAPI.getState().courseEditor.steps[
        thunkAPI.getState().courseEditor.currentStep
      ]
    const lastSavedStepId =
      thunkAPI.getState().courseEditor.save.lastSavedStepId

    if (!currentStep || lastSavedStepId === currentStep.stepId) {
      return
    }

    if (currentStep.value.status !== CourseStatus.Draft) {
      return
    }

    return CourseClient.updateCourse(
      {
        ...currentStep.value,
      },
      currentStep.value.id,
    ).catch((e) => {
      console.error(e)
    })
  },
)

export const publishCourse = createAppAsyncThunk(
  "courseEditor/publish",
  async (
    syllabusContext:
      | {
          syllabusId: string
          unitId: string
          duplicateOriginalCourseId: string | undefined
        }
      | undefined,
    thunkAPI,
  ) => {
    const lastSavedStepId =
      thunkAPI.getState().courseEditor.save.lastSavedStepId

    const currentStep =
      thunkAPI.getState().courseEditor.steps[
        thunkAPI.getState().courseEditor.currentStep
      ]

    if (!currentStep || currentStep?.value.status === CourseStatus.Published) {
      return
    }

    const previousVersion =
      currentStep.value.version > "1.1"
        ? await getCourse(thunkAPI.getState(), {
            courseId: currentStep.value.id,
            version: decrementMinor(currentStep.value.version),
          })
        : undefined

    function mapPublishCourseResponse(
      res: ClientResponse<CourseDto, CourseItemValidation>,
    ) {
      switch (res.kind) {
        case "success":
          return {
            kind: "success" as const,
            value: {
              originalCourseId: syllabusContext?.duplicateOriginalCourseId,
              previousVersion: previousVersion,
              publishedVersion: res.value,
            },
          }
        case "failure":
          return {
            kind: "failure" as const,
            value: res.value,
          }
      }
    }

    if (
      lastSavedStepId &&
      currentStep?.value &&
      lastSavedStepId === currentStep.stepId
    ) {
      return CourseClient.publishCourse(currentStep.value.id).then(
        mapPublishCourseResponse,
      )
    }

    return CourseClient.updateCourse(
      {
        ...currentStep?.value,
        status: CourseStatus.Draft,
      },
      currentStep?.value.id,
    )
      .then((course) => CourseClient.publishCourse(course.id))
      .then(mapPublishCourseResponse)
  },
)

export const validateCourse = createAppAsyncThunk(
  "courseEditor/validateCourse",
  async (_, thunkAPI) => {
    const currentStep =
      thunkAPI.getState().courseEditor.steps[
        thunkAPI.getState().courseEditor.currentStep
      ]

    if (!currentStep) {
      return
    }

    if (
      thunkAPI.getState().courseEditor.save.lastSavedStepId ===
      currentStep.stepId
    ) {
      return CourseClient.getValidations(currentStep.value.id)
    }

    return CourseClient.updateCourse(
      {
        ...currentStep?.value,
        status: CourseStatus.Draft,
      },
      currentStep?.value.id,
    ).then((course) => CourseClient.getValidations(course.id))
  },
)

export const validateCourseForPreview = createAppAsyncThunk<
  {
    [key: string]: string[]
  },
  { courseId: string },
  undefined
>("courseEditor/validateCourseForPreview", async ({ courseId }, thunkAPI) => {
  const { currentStep: currentStepIndex, steps } =
    thunkAPI.getState().courseEditor
  const currentStep = steps[currentStepIndex]
  if (currentStep && currentStep.value.id === courseId) {
    await CourseClient.updateCourse(
      {
        ...currentStep?.value,
        status: CourseStatus.Draft,
      },
      currentStep?.value.id,
    )
  }
  return CourseClient.getValidations(courseId).then((res) =>
    flattenValidations(res),
  )
})

export const discardDraftConfirmed = createAppAsyncThunk(
  "courseEditor/discardDraftConfirmed",
  async (_, thunkAPI) => {
    const currentStep =
      thunkAPI.getState().courseEditor.steps[
        thunkAPI.getState().courseEditor.currentStep
      ]

    if (!currentStep) {
      return
    }
    const { id, version } = currentStep.value
    const { major } = parseStringVersion(version)

    return CourseClient.discardDraft(id, major)
  },
)

export default courseEditorSlice.reducer

export const {
  undo,
  redo,
  courseEdited,
  topicAdded,
  topicEdited,
  topicRemoved,
  elementAdded,
  liveEventAdded,
  elementEdited,
  liveEventEdited,
  elementRemoved,
  dragStarted,
  dragEnded,
  elementDraggedOver,
  dragTaskStarted,
  dragTaskEnded,
  taskDraggedOver,
  setDraggingTasksEnabled,
  publishConfirmationDialogClosed,
  closedRequestedWithUnpublishedChanges,
  unpublishedChangesPopupClosed,
  discardChangesRequested,
  discardChangesConfirmationClosed,
  discardChangesApproved,
  discardDraftRequested,
  discardDraftConfirmationClosed,
  elementMovedToTopic,
  liveEventElementMovedToTopic,
  topicOpenStateChanged,
  unmounted,
} = courseEditorSlice.actions
