import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import appTheme from "../theme/appTheme"
import useFetchAppData from "@utils/hooks/fetchAppData"
import SnackbarPresenter from "../main/snackbars/snackbarPresenter"
import PopupPresenter from "../popups/infra/popupPresenter"

export function AppContainer() {
  useFetchAppData()

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <Box
        sx={{
          maxHeight: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Outlet />
      </Box>
      <SnackbarPresenter />
      <PopupPresenter />
    </Box>
  )
}
