import { useAppSelector } from "@app/hooks"
import { Box, Typography } from "@mui/material"
import { TracksTable } from "./tracksTable"
import { selectAllTracks } from "@features/tracks/trackSelectors"

export const TracksContainer = () => {
  const allTracks = useAppSelector(selectAllTracks)

  return (
    <Box>
      <TracksTable tracks={allTracks} />
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModuleExplanationText = () => {
  return (
    <Typography variant="body2">
      Use master modules to maintain consistency and streamline your workflow.
      Any changes made to a master module will apply to all instances where it
      is used.
    </Typography>
  )
}
