import { CircularProgress, Snackbar, Stack, Typography } from "@mui/material"

export const FetchEventSnackbar = () => {
  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      ContentProps={{
        sx: { bgcolor: "eTypes.burgundy" },
      }}
      message={
        <Stack gap={2} direction="row" alignItems="center">
          <Typography variant="body1" color="white">
            Fetching events...
          </Typography>
          <CircularProgress color="inherit" size="16px" />
        </Stack>
      }
    />
  )
}
