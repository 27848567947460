import {
  MenuList,
  MenuItem,
  Box,
  Typography,
  SvgIcon,
  BoxProps,
  Chip,
  Stack,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import formatTimestamp from "@utils/formatTimestamp"
import appTheme from "../../theme/appTheme"

export type VersionItem<T extends number | string> = {
  id: string
  version: T
  createdAtTimestamp: number
  createdBy?: string
}

export const VersionsMenu = <T extends number | string>(props: {
  versionsHistory: VersionItem<T>[]
  onVersionSelected: (version: T) => void
  selectedVersion: T | undefined
  lastPublishedVersion?: T
  versionInUseBySyllabus?: T | undefined
  width?: BoxProps["width"]
}) => {
  const {
    versionsHistory,
    onVersionSelected,
    selectedVersion,
    lastPublishedVersion,
    versionInUseBySyllabus,
    width,
  } = props

  const border = `1px solid ${appTheme.palette.other.outlineBorder}`

  return (
    <MenuList
      sx={{
        width: width ?? "320px",
        minWidth: "80px",
        borderRight: "1px solid",
        borderColor: "other.outlineBorder",
        overflow: "auto",
        py: 0,
      }}
    >
      {versionsHistory.map((version, index) => {
        return (
          <MenuItem
            key={version.id + version.version}
            onClick={(e) => {
              onVersionSelected(version.version)
            }}
            style={{
              borderBottom: border,
              padding: 0,
              backgroundColor:
                selectedVersion === version.version
                  ? appTheme.palette.action.selected
                  : "inherit",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              padding="24px"
              pr="8px"
            >
              <Box display="flex" flexDirection="column" textAlign="start">
                <VersionChip
                  version={version.version}
                  lastPublishedVersion={lastPublishedVersion}
                  versionInUseBySyllabus={versionInUseBySyllabus}
                />
                <Typography variant="body2">
                  {formatTimestamp(version.createdAtTimestamp)}
                </Typography>
                {version.createdBy && (
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    maxWidth={"60px"}
                    noWrap
                    textOverflow={"ellipsis"}
                  >
                    By {version.createdBy}
                  </Typography>
                )}
              </Box>
              <SvgIcon
                component={appIcons.chevronRight}
                inheritViewBox
                sx={{
                  fill: "none",
                  stroke: appTheme.palette.icon.black,
                  width: "20px",
                  height: "20px",
                }}
              />
            </Box>
          </MenuItem>
        )
      })}
    </MenuList>
  )
}

function VersionChip<T extends number | string>(props: {
  version: T
  lastPublishedVersion: T | undefined
  versionInUseBySyllabus: T | undefined
}) {
  const { version, lastPublishedVersion, versionInUseBySyllabus } = props
  return (
    <Stack direction="row" gap={1} flexWrap="wrap" sx={{ mb: 1 }}>
      {version === lastPublishedVersion && <LastPublishedVersionChip />}
      {version === versionInUseBySyllabus && <VersionInUseChip />}
    </Stack>
  )
}

function VersionInUseChip() {
  return (
    <Chip
      label="Version in use"
      size="small"
      variant="outlined"
      sx={{
        width: "fit-content",
      }}
    />
  )
}

function LastPublishedVersionChip() {
  return (
    <Chip
      label="Published version"
      size="small"
      sx={{
        width: "fit-content",
      }}
    />
  )
}
