import { ShiftDto } from "@masterschool/course-builder-api"
import { Box, Chip, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"

export const ShiftCell = (shifts: ShiftDto[]) => {
  return (
    <Box display="flex">
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="4px"
        flexWrap="wrap"
      >
        {shifts.length > 0 ? (
          shifts.map((shift) => {
            return <Chip key={shift.id} label={shift.name} />
          })
        ) : (
          <Typography variant="body2" color={appTheme.palette.text.secondary}>
            No shifts{" "}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
