import { Breadcrumbs, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"
import BreadcrumbLink from "./breadcrumbLink"
import { BreadcrumbsModel } from "./breadcrumbsModel"

function BreadcrumbsPath({
  pathBreadcrumbs,
}: {
  pathBreadcrumbs: BreadcrumbsModel
}) {
  return (
    <Breadcrumbs
      separator={
        <SvgIcon
          inheritViewBox
          component={appIcons.arrowSmRight01}
          sx={{
            stroke: appTheme.palette.icon.black,
            fill: "none",
            width: "16px",
            height: "16px",
          }}
        />
      }
      sx={{
        justifyContent: "center",
        alignItems: "center",
        "& li": {
          display: "flex",
        },
      }}
    >
      {pathBreadcrumbs.breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbLink
          title={breadcrumb.title}
          key={breadcrumb.key}
          color={breadcrumb.navigationPath ? "text.primary" : "text.secondary"}
          underline={breadcrumb.navigationPath ? "hover" : "none"}
          navigationPath={breadcrumb.navigationPath}
        />
      ))}
    </Breadcrumbs>
  )
}

export default BreadcrumbsPath
