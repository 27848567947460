import { RootState } from "@app/store"
import { Role } from "@features/login/loginSlice"
import ProgramsMenu from "../../programs/menu/programsMenu"
import { SyllabusMainPage } from "../../main/syllabuses-main-page/syllabusesMainPage"
import { CoursesMainPage } from "../../main/coursesMainPage"
import { SalesManagement } from "../../sales-management/salesManagement"
import { SyllabusViewContainer } from "../../view/syllabusViewContainer"
import { CourseViewContainer } from "../../editor/courseViewContainer"
import { SyllabusEditorContainer } from "../../editor/syllabus/syllabusEditor"
import { CourseEditorContainer } from "../../editor/courseEditor"
import { ProjectsTabView } from "../../projects/projectsTabView"
import ProgramViewContainer from "../../programs/program/components/programContainer"
import { DeeplinksView } from "../../main/deeplinks/deeplinksView"
import CalendarContainer from "../../main/calendar/CalendarContainer"
import AppFeatureFlags from "@features/app-feature-flags/appFeatureFlags"
import AccountManagement from "../../account-management/accountManagement"
import { ShiftManagement } from "../../sales-management/shiftsManagement"
import { createSelector } from "@reduxjs/toolkit"
import {
  selectIsDeveloper,
  selectLoggedInUser,
} from "@features/login/loginSelectors"
import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"
import { UsersTab } from "../../tab-views/users-tab/usersTab"
import { BookSessionWithTutor } from "@features/bookSessionWithTutor/bookSessionWithTutor"
import { StudentsTabView } from "../../tab-views/students-tab/studentsTabView"
import { selectIsStudentsTableEnabled } from "@features/login/featureFlagSelectors"
import { TracksMainPage } from "../../main/tracks/tracksMainPage"
import { TrackPage } from "../../main/tracks/trackPage"
import CourseMajorsContainer from "../../main/courseMajorsContainer"

export const enum TabIdentifier {
  Syllabuses = "Syllabuses",
  Courses = "Courses",
  Tracks = "Tracks",
  Programs = "Programs",
  Projects = "Projects",
  Deeplinks = "Deeplinks",
  AppFeatureFlags = "AppFeatureFlags",
  Calendar = "Calendar",
  SalesManagement = "SalesManagement",
  Users = "Users",
  BookSession = "BookSession",
  AccountManagement = "AccountManagement",
  Students = "Students",
}

export const tabIdentifierToTitle: Record<TabIdentifier, string> = {
  Programs: "Programs",
  Syllabuses: "Syllabuses",
  Courses: "Courses",
  Tracks: "Tracks",
  Projects: "Projects",
  AccountManagement: "Employees & Faculty",
  Deeplinks: "Deep Links",
  AppFeatureFlags: "App Feature Flags",
  Calendar: "Calendar",
  SalesManagement: "Sales Management",
  Users: "Users",
  BookSession: "Book a session",
  Students: "Students",
}

export const tabIdentifierToRouteDescriptor: Record<
  TabIdentifier,
  {
    path: string
    hideMainSideBar: boolean
    element: JSX.Element
    beta?: boolean
  }[]
> = {
  Programs: [
    { path: "programs", hideMainSideBar: false, element: <ProgramsMenu /> },
    {
      path: "program/:programId",
      hideMainSideBar: true,
      element: <ProgramViewContainer />,
    },
    {
      path: "program/:programId/:classId",
      hideMainSideBar: true,
      element: <ProgramViewContainer />,
    },
  ],
  Students: [
    {
      path: "students",
      hideMainSideBar: false,
      element: <StudentsTabView />,
      beta: true,
    },
  ],
  Syllabuses: [
    {
      path: "syllabuses",
      hideMainSideBar: false,
      element: <SyllabusMainPage />,
    },
    {
      path: "syllabus/:syllabusId",
      hideMainSideBar: true,
      element: <SyllabusViewContainer />,
    },
    {
      path: "syllabus-edit/:syllabusId",
      hideMainSideBar: true,
      element: <SyllabusEditorContainer />,
    },
    {
      path: "syllabus-edit/:syllabusId/:unitId/:courseId",
      hideMainSideBar: true,
      element: <SyllabusEditorContainer />,
    },
  ],
  Courses: [
    { path: "courses", hideMainSideBar: false, element: <CoursesMainPage /> },
    {
      path: "course-versions/:courseId",
      hideMainSideBar: false,
      element: <CourseMajorsContainer />,
    },
    {
      path: "course/:courseId",
      hideMainSideBar: true,
      element: <CourseViewContainer />,
    },
    {
      path: "course-edit/:courseId",
      hideMainSideBar: true,
      element: <CourseEditorContainer />,
    },
  ],
  Tracks: [
    { path: "tracks", hideMainSideBar: false, element: <TracksMainPage /> },
    {
      path: "track/:trackId",
      hideMainSideBar: true,
      element: <TrackPage />,
    },
  ],
  Projects: [
    { path: "projects", hideMainSideBar: false, element: <ProjectsTabView /> },
  ],
  AccountManagement: [
    {
      path: "account-management",
      hideMainSideBar: false,
      element: <AccountManagement />,
    },
  ],
  Deeplinks: [
    { path: "deep-links", hideMainSideBar: false, element: <DeeplinksView /> },
  ],
  AppFeatureFlags: [
    {
      path: "app-feature-flags",
      hideMainSideBar: false,
      element: <AppFeatureFlags />,
    },
  ],
  Calendar: [
    {
      path: "calendar",
      hideMainSideBar: false,
      element: <CalendarContainer />,
    },
  ],
  BookSession: [
    {
      path: "book-a-session",
      hideMainSideBar: false,
      element: <BookSessionWithTutor />,
    },
  ],
  SalesManagement: [
    {
      path: "sales-management",
      hideMainSideBar: false,
      element: <SalesManagement />,
    },
    {
      path: "sales-management/shifts/:consultantId",
      hideMainSideBar: false,
      element: <ShiftManagement />,
    },
  ],
  Users: [
    {
      path: "users",
      hideMainSideBar: false,
      element: <UsersTab />,
      beta: true,
    },
  ],
}

const rolesToTabs: Record<Role, TabIdentifier[]> = {
  program_manager: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Programs,
    TabIdentifier.Deeplinks,
    TabIdentifier.Calendar,
  ],
  ms_employee: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Calendar,
  ],
  instructor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Calendar,
  ],
  account_manager: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.AccountManagement,
    TabIdentifier.Users,
    TabIdentifier.Calendar,
  ],
  lms_admin: [], // all tabs
  school_master_admin: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Calendar,
  ],
  career_success_advisor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Programs,
    TabIdentifier.Calendar,
  ],
  mentor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Calendar,
  ],
  professional_mentor: [
    TabIdentifier.Syllabuses,
    TabIdentifier.Courses,
    TabIdentifier.Projects,
    TabIdentifier.Calendar,
  ],
  sales_team_lead: [TabIdentifier.SalesManagement, TabIdentifier.Calendar],
  sales_admin: [TabIdentifier.SalesManagement, TabIdentifier.Calendar],
  academic_support_advisor: [TabIdentifier.BookSession, TabIdentifier.Calendar],
  tutor: [],
}

const TABS_ORDER = [
  TabIdentifier.Students,
  TabIdentifier.Programs,
  TabIdentifier.Syllabuses,
  TabIdentifier.Courses,
  TabIdentifier.Tracks,
  TabIdentifier.Projects,
  TabIdentifier.Calendar,
  TabIdentifier.Deeplinks,
  TabIdentifier.AppFeatureFlags,
  TabIdentifier.SalesManagement,
  TabIdentifier.BookSession,
  TabIdentifier.AccountManagement,
  TabIdentifier.Users,
]

export const TABS_GROUPS = [
  {
    title: "School manager",
    tabs: [
      TabIdentifier.Students,
      TabIdentifier.Programs,
      TabIdentifier.Calendar,
      TabIdentifier.BookSession,
    ],
  },
  {
    title: "Content",
    tabs: [
      TabIdentifier.Courses,
      TabIdentifier.Tracks,
      TabIdentifier.Projects,
      TabIdentifier.Syllabuses,
    ],
  },
  {
    title: "Backoffice",
    tabs: [
      TabIdentifier.AccountManagement,
      TabIdentifier.Deeplinks,
      TabIdentifier.AppFeatureFlags,
      TabIdentifier.Users,
    ],
  },
  {
    title: "Sales",
    tabs: [TabIdentifier.SalesManagement],
  },
]

const selectRoles = (state: RootState) => {
  const user = selectLoggedInUser(state)
  if (!user) {
    return "unknown"
  }
  return user.roles
}

const selectTabsToShowByFeatureFlags = createSelector(
  [selectShowTrackFeature, selectIsStudentsTableEnabled, selectIsDeveloper],
  (showTracksTab, showStudentsTab, isDeveloper) => {
    const tabs: TabIdentifier[] = []
    if (showTracksTab || isDeveloper) {
      tabs.push(TabIdentifier.Tracks)
    }
    if (showStudentsTab) {
      tabs.push(TabIdentifier.Students)
    }
    return tabs
  },
)
export const tabsSelector = createSelector(
  [selectRoles, selectTabsToShowByFeatureFlags],
  (roles, tabsShownByFeatureFlags) => {
    if (roles === "unknown") {
      return "unknown"
    }

    const tabsForRole = roles.flatMap((role) => rolesToTabs[role])

    return TABS_ORDER.filter(
      (tab) =>
        roles.includes(Role.LmsAdmin) ||
        tabsForRole.includes(tab) ||
        tabsShownByFeatureFlags.includes(tab),
    )
  },
)

export const tabGroupsSelector = createSelector(
  [tabsSelector],
  (visibleTabsForUser) => {
    return TABS_GROUPS.map((group) => ({
      title: group.title,
      tabs: group.tabs.filter((tab) => visibleTabsForUser.includes(tab)),
    })).filter((group) => group.tabs.length > 0)
  },
)

export const routesSelector = createSelector([tabsSelector], (orderedTabs) => {
  if (orderedTabs === "unknown") {
    return "unknown"
  }

  return orderedTabs.flatMap((tab) => tabIdentifierToRouteDescriptor[tab])
})
