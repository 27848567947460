import { CampusClient } from "./campusClient"

export type ProgramStaffMember = {
  userClientId: string
  name: string
  email: string
  roles: string[]
  properties: StaffMemberProperties | undefined
}

export type StaffMemberProperties = { assignedClasses: string[] }

export class ProgramStaffClient {
  static async listProgramStaff(
    programId: string,
  ): Promise<ProgramStaffMember[]> {
    return CampusClient.get<ProgramStaffMember[]>(
      `program-staff/list?programId=${programId}`,
    )
  }

  static async addProgramStaffMember(
    programId: string,
    userClientId: string,
    roles: string[],
    properties: StaffMemberProperties | undefined,
  ): Promise<void> {
    return CampusClient.post(`program-staff/add`, {
      programId,
      userClientId,
      roles,
      properties,
    })
  }

  static async editProgramStaffMember(
    programId: string,
    userClientId: string,
    roles: string[],
    properties: StaffMemberProperties | undefined,
  ): Promise<void> {
    return CampusClient.put(`program-staff/edit`, {
      programId,
      userClientId,
      roles,
      properties,
    })
  }

  static async removeProgramStaffMember(
    programId: string,
    userClientId: string,
  ): Promise<void> {
    return CampusClient.delete(`program-staff`, {
      programId,
      userClientId,
    })
  }

  static async listUserRoles(userClientId: string): Promise<
    {
      programId: string
      roles: string[]
    }[]
  > {
    return CampusClient.get<
      {
        programId: string
        roles: string[]
      }[]
    >(`program-staff/user/list-roles?userId=${userClientId}`)
  }
}
