import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  selectActiveCourse,
  selectDraggedId,
} from "@features/courseEditor/courseEditorSelectors"
import {
  dragEnded,
  dragStarted,
  elementDraggedOver,
  topicAdded,
} from "@features/courseEditor/courseEditorSlice"
import { CourseDto, CourseType } from "@masterschool/course-builder-api"
import { Alert, Box, SvgIcon, Typography } from "@mui/material"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useSearchParams } from "react-router-dom"
import { findTopicByElementId } from "../topicFinderUtil"
import AddTopicButton from "./addTopicButton"
import CourseSyllabusElementView from "./courseSyllabusElementView"
import { CourseSyllabusLiveEventView } from "./courseSyllabusLiveEventsView"
import TopicView from "./topicView"
import appIcons from "@utils/appIcons"

function TopicsContainer() {
  const course = useAppSelector(selectActiveCourse)
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const closeCurrentElement = () => {
    searchParams.delete("elementId")
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  if (!course) {
    return null
  }
  const hasTopics = course.syllabus.topics.length > 0
  const shouldShowAsWeek = course.type === CourseType.Track

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "40px",
      }}
    >
      <PublishConstraintsInformation courseType={course.type} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          maxHeight: "100%",
          width: "100%",
        }}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <TopicsView course={course} shouldShowAsWeek={shouldShowAsWeek} />
      </Box>
      <Box width="100%" paddingTop={hasTopics ? "20px" : "0"}>
        <AddTopicButton
          shouldShowAsWeek={shouldShowAsWeek}
          onClick={() => {
            closeCurrentElement()
            dispatch(
              topicAdded({
                editStepId: window.crypto.randomUUID(),
                topic: {
                  id: window.crypto.randomUUID(),
                  title: "",
                  isHidden: false,
                  elements: [],
                  liveEvents: [],
                },
              }),
            )
          }}
        />
      </Box>
    </Box>
  )
}

function TopicsView(props: { course: CourseDto; shouldShowAsWeek: boolean }) {
  const { course, shouldShowAsWeek } = props
  const topics = course.syllabus.topics
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )
  const dispatch = useAppDispatch()
  const draggedId = useAppSelector(selectDraggedId)
  const draggedTopic = topics.find((t) => t.id.toString() === draggedId)
  const draggedElementTopic = findTopicByElementId(course, draggedId)
  const draggedElement = draggedElementTopic?.elements.find(
    (e) => e.item.id === draggedId,
  )
  const draggedLiveEvent = draggedElementTopic?.liveEvents.find(
    (le) => le.id === draggedId,
  )
  const highlightedTopic = useAppSelector(
    (state) => state.courseEditor.highlightedItem?.topicId,
  )

  useEffect(() => {
    if (highlightedTopic) {
      const element = document.getElementById(highlightedTopic)
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [highlightedTopic])

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event
    const activeIndex = active?.data.current?.sortable?.index
    const activeContainerId = active?.data.current?.sortable?.containerId
    const overIndex = over?.data.current?.sortable?.index
    const overContainerId = over?.data.current?.sortable?.containerId

    dispatch(
      elementDraggedOver({
        editStepId: window.crypto.randomUUID(),
        active: {
          index: activeIndex,
          containerId: activeContainerId,
        },
        over: {
          index: overIndex,
          containerId: overContainerId,
        },
      }),
    )
  }

  return (
    <DndContext
      onDragStart={(event) => {
        dispatch(dragStarted({ id: event.active.id.toString() }))
      }}
      onDragEnd={() => {
        dispatch(dragEnded())
      }}
      onDragCancel={() => {
        dispatch(dragEnded())
      }}
      onDragOver={handleDragOver}
      sensors={sensors}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          paddingTop: "3px",
        }}
      >
        <Droppable
          id={course.id}
          items={topics.map((topic, index) => {
            return {
              identifier: topic.id.toString(),
              element: (
                <TopicView
                  key={topic.id}
                  topic={topic}
                  availableTopics={topics.filter((t) => t.id !== topic.id)}
                  shouldShowAsWeek={shouldShowAsWeek}
                />
              ),
            }
          })}
          placeholder={<></>}
          style={(isDragging) => ({
            opacity: isDragging ? 0 : 1,
          })}
        />
      </Box>
      {createPortal(
        <DragOverlay
          dropAnimation={{
            duration: 300,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
          style={{
            zIndex: 9999999,
          }}
        >
          {draggedTopic ? (
            <TopicView
              topic={draggedTopic}
              shouldShowAsWeek={shouldShowAsWeek}
            />
          ) : draggedElement && draggedElementTopic ? (
            <CourseSyllabusElementView
              element={draggedElement}
              topic={draggedElementTopic}
              index={NaN}
            />
          ) : draggedLiveEvent && draggedElementTopic ? (
            <CourseSyllabusLiveEventView
              liveEvent={draggedLiveEvent}
              topic={draggedElementTopic}
              index={NaN}
            />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}

function PublishConstraintsInformation({
  courseType,
}: {
  courseType: CourseType
}) {
  switch (courseType) {
    case CourseType.Track:
      return <PublishConstraintForTrackCourse />
    case CourseType.Legacy:
      return (
        <Typography variant="body2" color="text.secondary" sx={{ mb: "8px" }}>
          Add at least one topic with at least one item to publish your course.
        </Typography>
      )
  }
}

function PublishConstraintForTrackCourse() {
  return (
    <Alert
      icon={
        <SvgIcon
          component={appIcons.infoCircle}
          inheritViewBox
          sx={{ fill: "none", width: 20, height: 20 }}
        />
      }
      severity="info"
      sx={{
        alignItems: "center",
        ".MuiAlert-icon": {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Typography variant="body1" color="text.primary">
        Each course includes a maximum of 4 weeks. Add at least 1 week with at
        least 1 item to publish your course.
      </Typography>
    </Alert>
  )
}

export default TopicsContainer
