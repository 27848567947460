import { useAppSelector } from "@app/hooks"
import { LicenseInfo } from "@mui/x-license"
import { AppTabsContainer } from "@cmp/appTabsContainer"
import { appLoaded } from "@features/login/loginSlice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes, useNavigate } from "react-router-dom"
import "./App.css"
import { Login } from "./login/login"
import { LoginProxyToken } from "./login/loginProxyToken"
import AppIconsPage from "./dev/appIconsPage"
import appIcons from "@utils/appIcons"
import AppIcon from "@utils/appIcon"
import {
  routesSelector,
  tabIdentifierToRouteDescriptor,
  tabsSelector,
} from "@features/app-tabs/tabs.selector"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { AppContainer } from "@cmp/appContainer"
import { selectUserLoginState } from "@features/login/loginSelectors"
import useSelectedTab from "@utils/hooks/appUtils"

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_KEY ?? "")

function App() {
  const dispatch = useDispatch()
  const tabs = useAppSelector(tabsSelector)
  const loginState = useAppSelector(selectUserLoginState)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(appLoaded())
  }, [dispatch])

  const selectedTab = useSelectedTab(tabs)
  useEffect(() => {
    if (loginState === "not-logged-in") {
      navigate("/login")
    } else if (!selectedTab && tabs !== "unknown") {
      navigate("/" + tabIdentifierToRouteDescriptor[tabs[0]][0].path)
    }
  }, [tabs, selectedTab, loginState, navigate])

  const loginRoutes = [
    { path: "login", element: <Login /> },
    { path: "login-proxy/:token/*", element: <LoginProxyToken /> },
  ]

  const defaultRoutes = [
    {
      path: "/*",
      hideMainSideBar: false,
      element: <LoadingComponent loadedComponents="" />,
    },
  ]

  const routesFromSelector = useAppSelector(routesSelector)
  const dynamicRoutesDescriptor =
    routesFromSelector === "unknown" ? defaultRoutes : routesFromSelector
  const devRoutes = [
    { path: "icons", element: <AppIconsPage /> },
    ...Object.keys(appIcons).map((key) => ({
      path: `icon/${key}`,
      element: <AppIcon icon={(appIcons as any)[key]} />,
    })),
  ]
  const renderDynamicRoutes = dynamicRoutesDescriptor.map((routeDescriptor) => {
    const element = routeDescriptor.hideMainSideBar ? (
      routeDescriptor.element
    ) : (
      <AppTabsContainer selectedTab={selectedTab}>
        {routeDescriptor.element}
      </AppTabsContainer>
    )
    return (
      <Route
        key={routeDescriptor.path}
        path={routeDescriptor.path}
        element={element}
      />
    )
  })

  return (
    <Routes>
      {loginRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="/" element={<AppContainer />}>
        {renderDynamicRoutes}
        {devRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  )
}

export default App
