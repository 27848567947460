import GenericDialog from "@cmp/genericDialog"
import { VersionItem, VersionsMenu } from "@cmp/version-management/versionsMenu"
import { Stack, Divider, CircularProgress } from "@mui/material"
import { useAppSelector } from "@app/hooks"
import { SyllabusView } from "../../view/syllabusView"
import {
  enrichSyllabusWithPrograms,
  selectLastPublishedSyllabus,
} from "@features/syllabus/syllabusSelectors"
import { useEffect, useState } from "react"
import { RevertButton } from "@cmp/version-management/revertButton"

import { useRevertSyllabusToVersion } from "@features/syllabus/revertVersion"
import { SyllabusClient } from "@clients/syllabusClient"
import { SyllabusDto } from "@masterschool/course-builder-api"
import { selectPrograms } from "@features/program/programSliceSelectors"
import { useSelector } from "react-redux"

export const SyllabusVersionHistoryPopup = (props: {
  syllabusId: string
  onClose: () => void
}) => {
  const { syllabusId, onClose } = props
  const [versions, setVersions] = useState<SyllabusDto[]>([])

  useEffect(() => {
    SyllabusClient.listSyllabusVersions(syllabusId).then((versions) => {
      setVersions(versions)
      setSelectedVersionId(versions[0].version)
    })
  }, [syllabusId])
  const [selectedVersionId, setSelectedVersionId] = useState<
    number | undefined
  >(undefined)
  const lastPublishedVersion = useAppSelector(
    selectLastPublishedSyllabus(syllabusId),
  )
  const selectedVersionContent = versions.find(
    (version) => version.version === selectedVersionId,
  )
  const lastVersionId = versions[0]?.version || undefined

  const resetSelectedVersion = () => setSelectedVersionId(versions[0].version)

  return (
    <GenericDialog
      open
      onClose={onClose}
      title="Version history"
      size="xl"
      fullHeight
      disableContentPadding
      content={
        <Stack overflow="auto" height={1}>
          <Divider />
          <Stack direction="row" flex={1} overflow="hidden">
            <VersionsMenu
              versionsHistory={versions.map((syllabus) =>
                toVersionItem(syllabus),
              )}
              onVersionSelected={(versionNumber) =>
                setSelectedVersionId(versionNumber)
              }
              selectedVersion={selectedVersionId}
              lastPublishedVersion={lastPublishedVersion?.version}
              width="230px"
            />
            {selectedVersionContent ? (
              <SelectedVersionPanel
                syllabusDto={selectedVersionContent}
                isLastVersion={
                  selectedVersionContent?.version === lastVersionId
                }
                resetSelection={resetSelectedVersion}
              />
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Stack>
      }
    />
  )
}

const SelectedVersionPanel = (props: {
  syllabusDto: SyllabusDto
  isLastVersion: boolean
  resetSelection: () => void
}) => {
  const { syllabusDto, isLastVersion, resetSelection } = props
  const showRevertButton = !isLastVersion
  const revertParams = useRevertSyllabusToVersion(syllabusDto?.id)
  const programs = useSelector(selectPrograms)
  const syllabus = enrichSyllabusWithPrograms(syllabusDto, programs)

  if (revertParams === "error") return <CircularProgress />
  const { revert, shouldVerifyBeforeRevert } = revertParams
  const isSyllabusLoaded = !!syllabus

  return (
    <Stack alignItems="center" flex={1}>
      <SyllabusView
        syllabus={syllabus}
        viewMode="popup"
        hideStaleCourseSnackbar={true}
        embeddedHeader={
          <Stack
            sx={{
              alignItems: "flex-end",
            }}
          >
            {showRevertButton && isSyllabusLoaded && (
              <RevertButton
                doRevert={async () => {
                  await revert(syllabus)
                  resetSelection()
                }}
                shouldVerify={shouldVerifyBeforeRevert}
              />
            )}
          </Stack>
        }
      />
    </Stack>
  )
}

const toVersionItem = (syllabus: SyllabusDto): VersionItem<number> => ({
  id: syllabus.id,
  version: syllabus.version,
  createdAtTimestamp: new Date(syllabus.createdAt).getTime(),
})
