import { useAppDispatch } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { updateTrackCourseGeneralDetails } from "@features/coursesMenu/coursesMenuSlice"
import {
  CourseDescriptorDto,
  CourseType,
  ProgramDomain,
} from "@masterschool/course-builder-api"
import { Stack, TextField, Autocomplete, Chip } from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"
import { useEffect, useState } from "react"

export const EditCourseDetailsPopup = (props: {
  course: CourseDescriptorDto
  onClose: () => void
}) => {
  const { course, onClose } = props
  const dispatch = useAppDispatch()
  const [displayName, setDisplayName] = useState(course.title || "")
  const [internalName, setInternalName] = useState(course.name || "")
  const [domains, setDomains] = useState<string[]>(course.domains || "")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (course.type === CourseType.Legacy) {
      onClose()
    }
  }, [course.type, onClose])

  const onSubmit = () => {
    setIsLoading(true)
    return dispatch(
      updateTrackCourseGeneralDetails({
        details: { displayName, internalName, domains },
        id: course.id,
      }),
    )
      .then(() => {
        onClose()
      })
      .finally(() => setIsLoading(false))
  }
  const domainOptions = Object.values(ProgramDomain)

  const hasChanged =
    displayName !== course.title ||
    internalName !== course.name ||
    !isArrayEqual(domains, course.domains)

  return (
    <GenericDialog
      title="Edit course details"
      onClose={onClose}
      size="xs"
      content={
        <Stack gap={2}>
          <TextField
            label="Display name"
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value)
            }}
          ></TextField>
          <TextField
            label="Internal name"
            value={internalName}
            onChange={(e) => {
              setInternalName(e.target.value)
            }}
          ></TextField>
          <Autocomplete
            options={domainOptions}
            value={domains[0]}
            onChange={(_, newValue) => setDomains([newValue as string])}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="School" />
            )}
            renderOption={(props, domain) => (
              <li {...props} key={domain}>
                {domainToDisplayName(domain)}
              </li>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index })
                return (
                  <Chip
                    key={key}
                    label={domainToDisplayName(option)}
                    {...tagProps}
                  />
                )
              })
            }
            getOptionLabel={domainToDisplayName}
          />
        </Stack>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          loading: isLoading,
          text: "Save",
          type: "primary",
          onClick: onSubmit,
          disabled: !hasChanged,
        },
      ]}
      open={true}
    />
  )
}

function isArrayEqual(array1: any[], array2: any[]): boolean {
  return array1.msIntersection(array2).length === array1.length
}
