import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { ConsultantClient } from "@clients/consultantClient"
import { ShiftDto, TeamLeadConsultants } from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"
import { ShiftsClient } from "@clients/shiftsClient"
import AppObserver from "@app/middlewares/appObserver"
import { ConsultantAllocationsClient } from "@clients/consultantAllocationsClient"

export type ConsultantToShifts = Record<string, ShiftDto[]>
export type SalesManagementSliceState = {
  salesTeams: PendingDataState<TeamLeadConsultants[]>
  shifts: PendingDataState<ConsultantToShifts>
  createdShifts: PendingDataState<ShiftDto[]>
  onlineConsultants: PendingDataState<string[]>
}

export const getInitialState: () => SalesManagementSliceState = () => {
  return {
    salesTeams: { status: "idle" },
    shifts: { status: "idle" },
    createdShifts: { status: "idle" },
    onlineConsultants: { status: "idle" },
  }
}

export const salesManagementSlice = createSlice({
  name: "salesManagementSlice",
  initialState: getInitialState(),
  reducers: {
    resetShifts: (state) => {
      state.shifts = { status: "idle" }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesTeams.pending, (state) => {
      state.salesTeams = { status: "pending" }
    })
    builder.addCase(
      fetchSalesTeams.fulfilled,
      (state, action: PayloadAction<TeamLeadConsultants[]>) => {
        state.salesTeams = {
          status: "success",
          data: action.payload,
        }
      },
    )
    builder.addCase(fetchSalesTeams.rejected, (state, action) => {
      state.salesTeams = {
        status: "error",
        error: action.error.message,
      }
    })
    builder.addCase(fetchShifts.pending, (state) => {
      if (state.shifts.status === "success") {
        return
      }
      state.shifts = { status: "pending" }
    })
    builder.addCase(fetchShifts.fulfilled, (state, action) => {
      if (action.payload !== "RESET_SHIFTS") {
        state.shifts = {
          status: "success",
          data: action.payload.reduce((acc, { consultantId, shifts }) => {
            acc[consultantId] = shifts
            return acc
          }, {} as ConsultantToShifts),
        }
      }
    })
    builder.addCase(fetchAvailableShifts.pending, (state) => {
      state.createdShifts = { status: "pending" }
    })
    builder.addCase(fetchAvailableShifts.fulfilled, (state, action) => {
      state.createdShifts = {
        status: "success",
        data: action.payload,
      }
    })
    builder.addCase(updateConsultantAvailability.pending, (state, action) => {
      if (state.salesTeams.status !== "success" || !state.salesTeams.data) {
        return
      }
      const { consultantId, isAvailable } = action.meta.arg
      const allConsultants = state.salesTeams.data.flatMap(
        (team) => team.consultants,
      )
      const consultant = allConsultants.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.isAvailable = isAvailable
      }
    })

    builder.addCase(updateConsultantAvailability.fulfilled, (state, action) => {
      if (state.salesTeams.status !== "success" || !state.salesTeams.data) {
        return
      }
      const consultantId = action.payload.id
      const allConsultants = state.salesTeams.data.flatMap(
        (team) => team.consultants,
      )
      const consultant = allConsultants.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        Object.assign(consultant, action.payload)
      }
    })

    builder.addCase(updateConsultantAcceptedQtfs.pending, (state, action) => {
      if (state.salesTeams.status !== "success" || !state.salesTeams.data) {
        return
      }
      const consultantId = action.meta.arg.consultantId
      const acceptedQtfs = action.meta.arg.acceptedQtfs
      const allConsultants = state.salesTeams.data.flatMap(
        (team) => team.consultants,
      )
      const consultant = allConsultants.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.acceptedQtfs = acceptedQtfs
      }
    })

    builder.addCase(updateConsultantLanguages.pending, (state, action) => {
      if (state.salesTeams.status !== "success" || !state.salesTeams.data) {
        return
      }
      const { consultantId, languages } = action.meta.arg
      const allConsultants = state.salesTeams.data.flatMap(
        (team) => team.consultants,
      )
      const consultant = allConsultants.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.languages = languages
      }
    })

    builder.addCase(updateConsultantAvailability.rejected, (state, action) => {
      if (state.salesTeams.status !== "success" || !state.salesTeams.data) {
        return
      }
      const consultantId = action.meta.arg.consultantId
      const allConsultants = state.salesTeams.data.flatMap(
        (team) => team.consultants,
      )
      const consultant = allConsultants.find(
        (consultant) => consultant.id === consultantId,
      )
      if (consultant) {
        consultant.isAvailable = !consultant.isAvailable
      }
    })
    builder.addCase(editShift.pending, (state, action) => {
      const shifts = state.shifts
      if (shifts.status !== "success" || !shifts.data) {
        return
      }
      const keys = Object.keys(shifts.data)
      keys.forEach((key) => {
        const consultantShifts = shifts.data[key]
        consultantShifts.forEach((shift) => {
          if (shift.id === action.meta.arg.id) {
            Object.assign(shift, action.meta.arg)
          }
        })
      })
    })
    builder.addCase(editShift.fulfilled, (state, action) => {
      const shifts = state.shifts
      if (shifts.status !== "success" || !shifts.data) {
        return
      }
      const keys = Object.keys(shifts.data)
      keys.forEach((key) => {
        const consultantShifts = shifts.data[key]
        consultantShifts.forEach((shift) => {
          if (shift.id === action.payload.id) {
            Object.assign(shift, action.payload)
          }
        })
      })
    })
    builder.addCase(fetchConsultantOnlineStatus.pending, (state) => {
      if (state.onlineConsultants.status !== "success") {
        state.onlineConsultants = { status: "pending" }
      }
    })
    builder.addCase(fetchConsultantOnlineStatus.fulfilled, (state, action) => {
      state.onlineConsultants = {
        status: "success",
        data: action.payload,
      }
    })
  },
})

const fetchSalesTeams = createAppAsyncThunk(
  "salesManageement/fetchConsultants",
  async () => {
    return ConsultantClient.getSalesTeams()
  },
)

export const fetchShifts = createAppAsyncThunk(
  "salesManageement/fetchShifts",
  async (_, thunkAPI) => {
    const salesTeams = thunkAPI.getState().salesManagement.salesTeams
    if (salesTeams.status !== "success" || !salesTeams.data) {
      thunkAPI.dispatch(salesManagementSlice.actions.resetShifts())
      return "RESET_SHIFTS"
    }
    const consultants = salesTeams.data.flatMap((team) => team.consultants)
    return consultants.msCompactMapAsync(async (consultant) => {
      const shifts = await ShiftsClient.getConsultantShifts(consultant.id)
      return { consultantId: consultant.id, shifts }
    })
  },
)

export const updateConsultantAvailability = createAppAsyncThunk(
  "salesManagement/updateConsultantAvailablity",
  async (params: { isAvailable: boolean; consultantId: string }) => {
    return ConsultantClient.updateConsultantAvailability(
      params.consultantId,
      params.isAvailable,
    )
  },
)

export const updateConsultantAcceptedQtfs = createAppAsyncThunk(
  "salesManagement/updateConsultantAcceptedQtfs",
  async (
    params: { acceptedQtfs: number[]; consultantId: string },
    thunkAPI,
  ) => {
    return ConsultantClient.updateConsultantAcceptedQtfs(
      params.consultantId,
      params.acceptedQtfs,
    ).catch((error) => {
      thunkAPI.dispatch(fetchSalesTeams())
    })
  },
)

export const updateConsultantLanguages = createAppAsyncThunk(
  "salesManagement/updateConsultantLanguages",
  async (params: { languages: string[]; consultantId: string }, thunkAPI) => {
    return ConsultantClient.updateConsultantLanguages(
      params.consultantId,
      params.languages,
    ).catch((error) => {
      thunkAPI.dispatch(fetchSalesTeams())
    })
  },
)

export const fetchAvailableShifts = createAppAsyncThunk(
  "salesManagement/fetchAvailableShifts",
  async () => {
    return ShiftsClient.list()
  },
)

export const updateShifts = createAppAsyncThunk(
  "salesManagement/updateShifts",
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchShifts())
    thunkApi.dispatch(fetchAvailableShifts())
  },
)

export const updateConsultants = createAppAsyncThunk(
  "salesManagement/updateConsultants",
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchSalesTeams())
  },
)

export const editShift = createAppAsyncThunk(
  "salesManagement/editShift",
  async (shiftDto: ShiftDto, thunkApi) => {
    return ShiftsClient.editShift(shiftDto).finally(() => {
      thunkApi.dispatch(fetchShifts())
    })
  },
)

export const fetchConsultantOnlineStatus = createAppAsyncThunk(
  "salesManagement/fetchConsultantOnlineStatus",
  async (_, thunkApi) => {
    const state = thunkApi.getState()
    const salesTeams =
      state.salesManagement.salesTeams.status === "success"
        ? state.salesManagement.salesTeams.data
        : []
    const allConsultants = salesTeams.flatMap((team) => team.consultants)
    const consultantIds = allConsultants.map((consultant) => consultant.id)
    return ConsultantAllocationsClient.listOnlineConsultants(consultantIds)
  },
)

export const salesManagementShiftsObserver: AppObserver = {
  didUpdate: (previousState, currentState, _, dispatch) => {
    const previousShifts = previousState.salesManagement.shifts
    const currentShifts = currentState.salesManagement.shifts
    const previousTeams = previousState.salesManagement.salesTeams
    const currentTeams = currentState.salesManagement.salesTeams
    let needsToFetchOnlineStatus = false
    if (previousTeams !== currentTeams) {
      dispatch(fetchShifts())
      needsToFetchOnlineStatus = true
    }

    if (previousShifts !== currentShifts) {
      needsToFetchOnlineStatus = true
    }

    if (needsToFetchOnlineStatus) {
      dispatch(fetchConsultantOnlineStatus())
    }
  },
}

export const consultantsObserver: AppObserver = {
  didUpdate: (previousState, currentState, _, dispatch) => {
    if (currentState.salesManagement.salesTeams.status === "idle") {
      dispatch(fetchSalesTeams())
    }

    if (currentState.salesManagement.createdShifts.status === "idle") {
      dispatch(fetchAvailableShifts())
    }
  },
}
export default salesManagementSlice.reducer
