import { ShiftDto } from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import EmptyStateContainer from "../programs/program/components/emptyStateContainer"
import appTheme from "../theme/appTheme"
import { ShiftCard } from "./shiftCard"

const ConsultantEmptyState = () => {
  return (
    <Box flex="5">
      <EmptyStateContainer
        title=""
        subtitle="This consultant has no shifts assigned"
        icon={appIcons.calendar}
        action={<Box />}
      />
    </Box>
  )
}

export const ConsultantShifts = (props: {
  shifts: ShiftDto[]
  onDeleteShift: (shiftId: string) => void
  onEditShift: (shiftId: string) => void
}) => {
  const { shifts, onDeleteShift } = props
  if (shifts.length === 0) {
    return <ConsultantEmptyState />
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      flex="5"
      justifyContent="flex-start"
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="white"
        padding="24px"
        gap="8px"
        borderColor={appTheme.palette.eTypes.sand50}
        sx={{ borderWidth: "1px", borderStyle: "solid" }}
      >
        <Typography variant="h6">Assigned Shifts ({shifts.length})</Typography>
        <Box height="16px" />
        {shifts.map((shift) => (
          <ShiftCard
            key={shift.id}
            onDeleteShift={onDeleteShift}
            onEditShift={props.onEditShift}
            shift={shift}
            canDelete={true}
          />
        ))}
      </Box>
    </Box>
  )
}
