import { RootState } from "@app/store"
import { TeamLeadConsultants } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"

export const selectSalesTeams = (
  state: RootState,
): TeamLeadConsultants[] | "pending" => {
  if (state.salesManagement.salesTeams.status !== "success") {
    return "pending"
  }

  return state.salesManagement.salesTeams.data
}

const selectOnlineConsultants = (state: RootState): string[] | "pending" => {
  const onlineConsultants = state.salesManagement.onlineConsultants
  if (onlineConsultants.status !== "success") {
    return "pending"
  }

  return onlineConsultants.data
}
export const onlineConsultantsSelector = createSelector(
  [selectSalesTeams, selectOnlineConsultants],
  (teams, onlineConsultants) => {
    if (teams === "pending" || onlineConsultants === "pending") {
      return "pending"
    }
    const allConsultants = teams.flatMap((team) => team.consultants)
    return onlineConsultants.msCompactMap((consultantId) => {
      return allConsultants.find((consultant) => consultant.id === consultantId)
    })
  },
)

const selectShifts = (state: RootState) => state.salesManagement.shifts

export const salesTeamWithShiftsSelector = createSelector(
  [selectSalesTeams, selectShifts],
  (teams, shifts) => {
    if (teams === "pending") {
      return "pending"
    }
    if (shifts.status !== "success") {
      return teams.map((team) => ({
        ...team,
        consultants: team.consultants.map((consultant) => {
          return {
            ...consultant,
            shifts: [],
          }
        }),
      }))
    }

    return teams.map((team) => ({
      ...team,
      consultants: team.consultants.map((consultant) => {
        return {
          ...consultant,
          shifts: shifts.data[consultant.id] ?? [],
        }
      }),
    }))
  },
)
export const availableShiftsSelector = createSelector(
  [salesTeamWithShiftsSelector],
  (teamsWithShifts) => {
    if (teamsWithShifts === "pending") {
      return "pending"
    }
    const allShifts = teamsWithShifts.flatMap((team) =>
      team.consultants.flatMap((consultant) => consultant.shifts),
    )
    return allShifts.msUnique((shift1, shift2) => shift1.id === shift2.id)
  },
)

export const selectConsultantShifts = createSelector(
  [
    (state: RootState) => state.salesManagement.shifts,
    (_, consultantId: string | undefined) => consultantId,
  ],
  (shifts, consultantId) => {
    if (shifts.status !== "success" || !consultantId) {
      return []
    }
    return shifts.data[consultantId] ?? []
  },
)

export const selectConsultant = createSelector(
  [
    (state: RootState) => state.salesManagement.salesTeams,
    (_, consultantId: string | undefined) => consultantId,
  ],
  (teams, consultantId) => {
    if (teams.status !== "success" || !consultantId) {
      return undefined
    }
    const consultants = teams.data.flatMap((team) => team.consultants)
    return consultants.find((consultant) => consultant.id === consultantId)
  },
)
