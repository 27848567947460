import { GridSortDirection } from "@mui/x-data-grid-pro"

function isUndefined<T>(
  value: T | undefined | null,
): value is undefined | null {
  return value === undefined || value === null
}

// This function is used to compare two values, which may be undefined, and making sure
// that undefined values are sorted so that they're at the bottom of the list.
function compareWithUndefined<T>(
  v1: T | undefined | null,
  v2: T | undefined | null,
):
  | { continueCompare: false; result: number }
  | { continueCompare: true; values: [T, T] } {
  if (isUndefined(v1) && isUndefined(v2))
    return { continueCompare: false, result: 0 }
  if (isUndefined(v2)) return { continueCompare: false, result: -1 }
  if (isUndefined(v1)) return { continueCompare: false, result: 1 }
  return { continueCompare: true, values: [v1, v2] }
}

export function dateStrComparator(
  v1: string | undefined,
  v2: string | undefined,
  order: GridSortDirection,
) {
  const check = compareWithUndefined(v1, v2)
  if (!check.continueCompare) return check.result

  const [val1, val2] = check.values
  const time1 = new Date(val1).getTime()
  const time2 = new Date(val2).getTime()
  if (isNaN(time1) || isNaN(time2)) return 0
  return order === "asc" ? time1 - time2 : time2 - time1
}

export function numberSorter(
  v1: number | undefined,
  v2: number | undefined,
  order: GridSortDirection,
) {
  const check = compareWithUndefined(v1, v2)
  if (!check.continueCompare) return check.result

  const [val1, val2] = check.values
  return order === "asc" ? val1 - val2 : val2 - val1
}
