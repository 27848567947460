import AppObserver from "@app/middlewares/appObserver"
import { selectLoggedInUser } from "./loginSelectors"
import { fetchLoggedInUserFeatureFlags } from "@features/login/loginSlice"

export const loggedInUserObserver: AppObserver = {
  didUpdate: (previousState, currentState, action, dispatch) => {
    const prevLoggedInUser = selectLoggedInUser(previousState)
    const newLoggedInUser = selectLoggedInUser(currentState)

    if (prevLoggedInUser === newLoggedInUser) {
      return
    }

    if (newLoggedInUser) {
      dispatch(fetchLoggedInUserFeatureFlags())
    }
  },
}
