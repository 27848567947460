import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../studentAcademicStatus.dto"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { ColumnKeys } from "./columns/column.keys"

export function getActionsColumn(
  onOpenProfileClicked: (studentId: string) => void,
): GridColDef<StudentAcademicStatusDTO> {
  return {
    field: ColumnKeys.ACTIONS,
    headerName: "Actions",
    width: 80,
    type: "actions",
    getActions: (params: { row: StudentAcademicStatusDTO }) => [
      <GridActionsCellItem
        icon={<AppIcon icon={appIcons.infoCircle} />}
        onClick={() => onOpenProfileClicked(params.row.studentId)}
        label="Open profile"
        showInMenu
      />,
      <GridActionsCellItem
        icon={<AppIcon icon={appIcons.users02} />}
        onClick={() =>
          window.open(
            `https://app.hubspot.com/contacts/9484219/record/0-1/${params.row.contactDetails.hubspotContactId}`,
            "_blank",
          )
        }
        label="Go to hubspot"
        showInMenu
        disabled={!params.row.contactDetails.hubspotContactId}
      />,
    ],
  }
}
