import appTheme from "../../../theme/appTheme"

export function emojiForCSATFeedback(rating: number) {
  switch (rating) {
    case 1:
      return "☹️"
    case 2:
      return "😕"
    case 3:
      return "😶"
    case 4:
      return "🙂"
    case 5:
      return "😁"
    default:
      return "😶"
  }
}

export function cohortColor(cohort: string) {
  const palette = appTheme.palette
  const colorScale = [
    palette.eTypes.lilac25,
    palette.eTypes.lightGreen,
    palette.eTypes.pink50,
    palette.other.brightYellow,
    palette.eTypes.sand50,
    palette.other.celestial,
    palette.other.tangerine,
    palette.eTypes.grassGreen15,
    palette.other.failedGrade,
    palette.other.turquoise,
  ]
  const cohortDate = new Date(cohort)
  if (isNaN(cohortDate.getTime())) return palette.eTypes.sand50

  const year = cohortDate.getFullYear()
  const month = cohortDate.getMonth()

  return colorScale[((year - 2023) * 12 + month) % colorScale.length]
}
