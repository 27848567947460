import OptionsButton from "@cmp/buttons/optionsButton"
import { TrackDto, TrackDtoStatusEnum } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

export const TrackOptionsMenu = (props: {
  track: TrackDto
  isEditMode: boolean
  onClickEdit: () => void
}) => {
  const { track, isEditMode, onClickEdit } = props

  const notImplemented = () => {}

  const items = [
    {
      text: "Edit",
      onSelect: onClickEdit,
      hidden: isEditMode,
    },
    {
      text: "Preview on campus",
      onSelect: notImplemented,
      disabled: true,
    },
    {
      text: "Duplicate",
      onSelect: notImplemented,
      disabled: true,
    },
    {
      text: "Version History",
      onSelect: notImplemented,
      disabled: true,
    },
    {
      text: "Archive",
      onSelect: notImplemented,
      disabled: true,
      hidden: track?.status !== TrackDtoStatusEnum.Archived,
    },
  ]

  return (
    <OptionsButton
      button={{
        variant: "contained",
        sx: {
          padding: "3px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        },
        leftIcon: appIcons.dots,
      }}
      items={items}
    />
  )
}
