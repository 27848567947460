import { ProgramDomain } from "@masterschool/course-builder-api"
import { stringAsCamelCase } from "./syllabusTags"
import appTheme from "../theme/appTheme"

export function domainToDisplayName(domain: string): string {
  switch (domain) {
    case "WEB":
      return "Software"
    case "DATA_SCIENCE":
      return "Data Analysis"
    default:
      return stringAsCamelCase(domain)
  }
}

export function domainToColor(domain: ProgramDomain): string {
  switch (domain) {
    case ProgramDomain.WEB:
      return appTheme.palette.eTypes.lilac25
    case ProgramDomain.DATASCIENCE:
      return appTheme.palette.eTypes.lightGreen
    case ProgramDomain.CYBER:
      return appTheme.palette.eTypes.pink50
    case ProgramDomain.COMPASS:
      return appTheme.palette.other.brightYellow
    case ProgramDomain.DIGITALSALESMANAGEMENT:
      return appTheme.palette.eTypes.sand50
    case ProgramDomain.EMPLOYEETRAINING:
      return appTheme.palette.other.celestial
    case ProgramDomain.PARTNERTRAINING:
      return appTheme.palette.other.tangerine
    default:
      return appTheme.palette.eTypes.grassGreen15
  }
}
