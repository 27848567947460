import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import { selectActiveCourse } from "@features/courseEditor/courseEditorSelectors"
import {
  unpublishedChangesPopupClosed,
  validateCourse,
} from "@features/courseEditor/courseEditorSlice"
import { CourseType } from "@masterschool/course-builder-api"
import { useNavigate, useParams } from "react-router-dom"

function UnpublishedChangesPopup() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { syllabusId } = useParams()
  const course = useAppSelector(selectActiveCourse)

  if (!course) {
    return null
  }

  return (
    <GenericDialog
      open
      size="xs"
      title="Publish changes?"
      subtitle="Your changes were saved but have not been published yet."
      onClose={() => dispatch(unpublishedChangesPopupClosed())}
      buttons={[
        {
          type: "secondary",
          text: "Publish later",
          onClick: () => {
            if (syllabusId) {
              navigate(`/syllabus-edit/${syllabusId}`)
            } else if (course.type === CourseType.Legacy) {
              navigate("/courses")
            } else {
              navigate(`/course-versions/${course.id}`)
            }
          },
        },
        {
          type: "primary",
          text: "Publish now",
          onClick: () => {
            dispatch(validateCourse())
          },
        },
      ]}
    />
  )
}

export default UnpublishedChangesPopup
