export type DeconstructedVersion = {
  major: number
  minor: number
  minorPadding: number // Store original minor version padding for formatting
}

export function parseStringVersion(
  versionString: string,
): DeconstructedVersion {
  const [majorStr, minorStr] = versionString.split(".")
  const major = parseInt(majorStr, 10)
  const minor = parseInt(minorStr, 10)

  if (isNaN(major) || isNaN(minor)) {
    throw new Error(`Invalid version string: ${versionString}`)
  }

  return {
    major,
    minor,
    minorPadding: minorStr.length,
  }
}

export function versionToString(version: DeconstructedVersion): string {
  return `${version.major}.${version.minor
    .toString()
    .padStart(version.minorPadding, "0")}`
}

export function incrementMinor(version: string): string {
  const deconstructed = parseStringVersion(version)
  deconstructed.minor += 1
  return versionToString(deconstructed)
}

export function incrementMajor(version: string): string {
  const deconstructed = parseStringVersion(version)
  deconstructed.major += 1
  deconstructed.minor = 0
  deconstructed.minorPadding = 1
  return versionToString(deconstructed)
}

export function decrementMinor(version: string): string {
  const deconstructed = parseStringVersion(version)
  if (deconstructed.minor === 1) {
    return versionToString(deconstructed)
  }
  deconstructed.minor -= 1
  return versionToString(deconstructed)
}

export function decrementMajor(version: string): string {
  const deconstructed = parseStringVersion(version)
  if (deconstructed.major === 1) {
    return versionToString(deconstructed)
  }
  deconstructed.major -= 1
  deconstructed.minor = 0
  deconstructed.minorPadding = 1
  return versionToString(deconstructed)
}
