import { useAppDispatch, useAppSelector } from "@app/hooks"
import OptionsButton from "@cmp/buttons/optionsButton"
import { Topic } from "@masterschool/course-builder-api"
import { Box, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { durationFormatting, topicDuration } from "@utils/syllabus+duration"
import { useEffect, useRef, useState } from "react"
import { selectIsTopicInvalid } from "@features/courseEditor/courseValidationsSelectors"
import {
  topicEdited,
  topicRemoved,
} from "@features/courseEditor/courseEditorSlice"
import appTheme from "../../theme/appTheme"
import useCloseElementEditor from "../elements/custom-hooks/useCloseElementEditor"
import useSelectedElement from "../elements/custom-hooks/useSelectedElement"

function TopicHeader(props: {
  topic: Topic
  onClick: () => void
  isTopicOpen: boolean
  shouldShowAsWeek: boolean
}) {
  const { topic, onClick, isTopicOpen, shouldShowAsWeek } = props
  const dispatch = useAppDispatch()
  const closeElementEditor = useCloseElementEditor()
  const selectedElement = useSelectedElement()
  const [isHovered, setIsHovered] = useState(false)
  const isInvalid = useAppSelector(selectIsTopicInvalid(topic))

  const moreButtonRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: any) => {
    if (
      moreButtonRef.current &&
      headerRef.current &&
      !moreButtonRef.current.contains(event.target) &&
      !headerRef.current.contains(event.target)
    ) {
      setIsHovered(false)
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const displayName = shouldShowAsWeek ? "week" : "topic"
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "12px",
        borderBottom: isTopicOpen
          ? `1px solid ${appTheme.palette.other.outlineBorder}`
          : `none`,
        gap: "8px",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: isTopicOpen ? "8px 8px 0 0" : "8px 8px",
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={headerRef}
    >
      <SvgIcon
        component={appIcons.chevronDown}
        inheritViewBox
        sx={{
          fill: "black",
          width: "20px",
          height: "20px",
          cursor: "pointer",
          transform: isTopicOpen ? "none" : "rotate(-90deg)",
          transition: "all .35s ease-in-out",
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
        }}
        gap="8px"
      >
        <Typography
          variant={topic.title.length > 0 ? "body1_sb" : "body1"}
          color={topic.title.length > 0 ? "text.primary" : "text.disabled"}
        >
          {topic.title.length > 0 ? topic.title : `Unnamed ${displayName}`}
        </Typography>
        {topicDuration(topic) > 0 && (
          <Typography color={appTheme.palette.text.disabled} variant="body2">
            ({durationFormatting(topicDuration(topic))})
          </Typography>
        )}
      </Box>
      <Box sx={{ opacity: isHovered ? 1 : 0 }} ref={moreButtonRef}>
        <OptionsButton
          button={{
            sx: { padding: "2px" },
            leftIcon: appIcons.dots,
          }}
          items={[
            {
              text: topic.isHidden
                ? `Unhide ${displayName}`
                : `Hide ${displayName}`,
              icon: topic.isHidden ? appIcons.eye : appIcons.eyeOff,
              onSelect: () => {
                dispatch(
                  topicEdited({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                    key: "isHidden",
                    value: !topic.isHidden,
                  }),
                )
              },
            },
            {
              text: `Delete ${displayName}`,
              icon: appIcons.trash03,
              onSelect: () => {
                if (
                  selectedElement &&
                  topic.elements
                    .map((e) => e.item.id)
                    .includes(selectedElement.item.id)
                ) {
                  closeElementEditor()
                }
                dispatch(
                  topicRemoved({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                  }),
                )
              },
            },
          ]}
        />
      </Box>
      {isInvalid && (
        <SvgIcon
          component={appIcons.alertCircle}
          inheritViewBox
          sx={{
            fill: "none",
            width: "20px",
            height: "20px",
            stroke: appTheme.palette.error.main,
          }}
        />
      )}
    </Box>
  )
}

export default TopicHeader
