import { TrackDtoStatusEnum } from "@masterschool/course-builder-api"
import { Chip } from "@mui/material"
import appTheme from "../../theme/appTheme"

export const TrackStatusChip = ({ status }: { status: TrackDtoStatusEnum }) => {
  return (
    <Chip
      label={statusToDisplayName(status)}
      size="small"
      sx={{
        backgroundColor: statusToColor(status),
      }}
    />
  )
}

function statusToDisplayName(status: TrackDtoStatusEnum): string {
  switch (status) {
    case TrackDtoStatusEnum.Draft:
      return "Drafts"
    case TrackDtoStatusEnum.Archived:
      return "Archived"
    case TrackDtoStatusEnum.Published:
      return "Published"
  }
}

function statusToColor(status: TrackDtoStatusEnum): string {
  switch (status) {
    case TrackDtoStatusEnum.Draft:
      return appTheme.palette.eTypes.sand35
    case TrackDtoStatusEnum.Archived:
      return (
        appTheme.palette.error.alert?.background || appTheme.palette.error.main
      )
    case TrackDtoStatusEnum.Published:
      return (
        appTheme.palette.success.alert?.background ||
        appTheme.palette.success.main
      )
  }
}
