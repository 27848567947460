import { useAppSelector } from "@app/hooks"
import {
  tabGroupsSelector,
  TabIdentifier,
  tabIdentifierToRouteDescriptor,
  tabIdentifierToTitle,
} from "@features/app-tabs/tabs.selector"
import { selectUserLoginState } from "@features/login/loginSelectors"
import { ContainerWithNavigationSidebarProps } from "@masterschool/ui"
import { Stack, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { getTabIcons, getTabTags } from "./mainSidebar.helper"

export const SIDEBAR_PX_WIDTH = 240
export const mainSidebarProps: ContainerWithNavigationSidebarProps["sidebarProps"] =
  {
    animationDuration: 0.5,
    isOpen: true,
    sx: {
      bgcolor: "eTypes.sand25",
    },
    width: SIDEBAR_PX_WIDTH,
  }

export const MainSidebarHeader = ({
  isMinimized,
}: {
  isMinimized: boolean
}) => (
  <Stack
    component={Link}
    sx={{
      mt: 4,
      mb: 1,
      mx: 3,
      alignItems: "flex-start",
      textDecoration: "none",
      color: "primary.main",
    }}
    to="/"
  >
    <SvgIcon
      component={
        isMinimized
          ? appIcons.masterschoolNoText
          : appIcons.masterschoolWithText
      }
      sx={{ height: 20, width: "auto" }}
      inheritViewBox
    />
    <Typography
      variant="body3"
      ml="26px"
      sx={{ visibility: isMinimized ? "hidden" : "visible" }}
    >
      BackOffice
    </Typography>
  </Stack>
)

export const useMainSidebarItems = (
  selectedTab: TabIdentifier | undefined,
): ContainerWithNavigationSidebarProps["items"] => {
  const navigate = useNavigate()

  const login = useAppSelector(selectUserLoginState)
  const tabGroups = useAppSelector(tabGroupsSelector)

  useEffect(() => {
    if (tabGroups.length === 0) {
      return
    }
    if (!selectedTab) {
      const mainRoute = tabIdentifierToRouteDescriptor[tabGroups[0].tabs[0]][0]
      navigate(mainRoute.path)
    }
  }, [selectedTab, tabGroups, navigate])

  if (login !== "logged-in") {
    return []
  }

  return tabGroups.map((group) => ({
    type: "group-item",
    title: group.title,
    items: group.tabs.map((tab) => {
      const routesDescriptor = tabIdentifierToRouteDescriptor[tab]
      const mainRoute = routesDescriptor[0]
      const icons = getTabIcons(tab)

      return {
        isSelected: selectedTab === tab,
        type: "single-item",
        label: tabIdentifierToTitle[tab],
        onClick: () => {
          navigate("/" + mainRoute.path)
        },
        selectedIcon: icons.selectedIcon,
        unselectedIcon: icons.unselectedIcon,
        tags: getTabTags(tab),
      }
    }),
  }))
}
