import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { ColumnKeys } from "./column.keys"
import { Box, Tooltip, Typography } from "@mui/material"
import { numberSorter } from "./column.sort.comparators"
import clsx from "clsx"

export const ACTIVITY_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = [
  {
    field: ColumnKeys.LAST_LEARNING_ACTIVITY,
    headerName: "Last Progress",
    width: 200,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return daysDiffFromTimestamp(
        studentRow.status.activity.lastLearningActivityTimestamp,
      )
    },
    renderCell: (params) =>
      renderDaysDiffCell(
        params,
        params.row.status.activity.lastLearningActivityTimestamp,
      ),
    getSortComparator(sortDirection) {
      return (v1, v2) => numberSorter(v1, v2, sortDirection)
    },
    cellClassName: (params) =>
      clsx("cell", {
        "is-flagged": params.value > 2,
      }),
    type: "number",
  },
  {
    field: ColumnKeys.LAST_LIVE_SESSION,
    headerName: "Last Live Lesson Attendance",
    width: 200,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return daysDiffFromTimestamp(
        studentRow.status.activity.lastLiveSessionAttendanceTimestamp,
      )
    },
    renderCell: (params) =>
      renderDaysDiffCell(
        params,
        params.row.status.activity.lastLiveSessionAttendanceTimestamp,
      ),
    getSortComparator(sortDirection) {
      return (v1, v2) => numberSorter(v1, v2, sortDirection)
    },
    cellClassName: (params) =>
      clsx("cell", {
        "is-flagged": isDaysDiffFlagged(params.value),
      }),
    type: "number",
  },
]

function renderDaysDiffCell(
  params: GridRenderCellParams<
    StudentAcademicStatusDTO,
    any,
    any,
    GridTreeNodeWithRender
  >,
  timestamp: string | null,
) {
  const days = params.value
  if (!timestamp || days === null) return null
  const lastSeenDate = new Date(timestamp)
  const daysText = days === 1 ? "day" : "days"
  const text = days > 0 ? `${days} ${daysText} ago` : "Today"
  return (
    <Tooltip title={`Last activity on ${lastSeenDate.toLocaleDateString()}`}>
      <Box
        display="flex"
        sx={{
          height: "100%",
          px: "10px",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Tooltip>
  )
}

function daysDiffFromTimestamp(timestamp: string | null): number | null {
  if (!timestamp) return null

  const timestampDate = new Date(timestamp)
  const now = new Date()

  const timestampMidnight = new Date(
    timestampDate.getFullYear(),
    timestampDate.getMonth(),
    timestampDate.getDate(),
  )
  const todayMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
  )

  const msPerDay = 1000 * 60 * 60 * 24
  const diffDays = Math.round(
    (todayMidnight.getTime() - timestampMidnight.getTime()) / msPerDay,
  )

  return diffDays
}

function isDaysDiffFlagged(days: number | null): boolean {
  if (days === null) return false

  const dayOfWeek = new Date().getDay()

  if (dayOfWeek === 0) {
    // Sunday
    return days > 4
  } else if (dayOfWeek === 6) {
    // Saturday
    return days > 3
  }
  return days > 2
}
