import { store } from "@app/store"
import { fetchConsultantOnlineStatus } from "@features/sales-management/salesManagement.slice"

export class OnlineStatusUpdater {
  public readonly updateDeltaInMinutes: number
  private intervelId: NodeJS.Timeout | undefined
  constructor(updateDeltaInMinutes: number) {
    this.updateDeltaInMinutes = updateDeltaInMinutes
  }

  public start() {
    if (this.intervelId) {
      return
    }

    this.intervelId = setInterval(() => {
      store.dispatch(fetchConsultantOnlineStatus())
    }, this.updateDeltaInMinutes * 60 * 1000)
  }

  public stop() {
    if (this.intervelId) {
      clearInterval(this.intervelId)
      this.intervelId = undefined
    }
  }
}
