import {
  CalendarHubClient,
  CreateMsEventDto,
  msEventEditableProperties,
  MsEventEditableProperties,
} from "@clients/calendarHubClient"
import { MsEventProps } from "@masterschool/ui-calendar/dist/src/types"
import { useState, useEffect, useCallback } from "react"
import {
  mapToMsEventDto as mapToMsCreateEventDto,
  mapToMsEventProps,
} from "./calendarMappers"

const AutoRefreshIntervalMS = 5 * 1000

const useFetchEvents = (
  calendarIds: string[],
  dateRange: { start: Date; end: Date },
  setEvents: (events: MsEventProps[]) => void,
) => {
  const { start: queryStartDate, end: queryEndDate } = dateRange

  const fetchEvents = useCallback(async () => {
    if (calendarIds.length === 0) {
      setEvents([])
      return
    }

    const events = await CalendarHubClient.getEvents(
      calendarIds,
      queryStartDate,
      queryEndDate,
    )

    const draftEvents = await CalendarHubClient.getDrafts(
      queryStartDate,
      queryEndDate,
    )

    setEvents([...events, ...draftEvents].map(mapToMsEventProps))
  }, [calendarIds, queryStartDate, queryEndDate, setEvents])

  return fetchEvents
}

const useHandleEventEdit = () => {
  return async (
    data: {
      oldEvent: MsEventProps
      newEvent: MsEventProps
    },
    recurringTarget?: "this" | "this-and-future" | "all",
  ) => {
    const isRecurring = data.oldEvent.recurringId
    if (isRecurring) {
      assertHaveRecurringTarget(recurringTarget)
      return updateRecurringEvent(data, recurringTarget)
    } else {
      return updateOneTimeEvent(data)
    }
  }
}

const useHandleDeleteEvent =
  () =>
  (
    event: MsEventProps,
    recurringTarget?: "this" | "this-and-future" | "all",
  ) => {
    const isRecurring = event.recurringId
    if (isRecurring) {
      assertHaveRecurringTarget(recurringTarget)
      return deleteRecurringEvent(event, recurringTarget)
    } else {
      return CalendarHubClient.delete(event.id)
    }
  }

function assertHaveRecurringTarget(
  recurringTarget: "this" | "this-and-future" | "all" | undefined,
): asserts recurringTarget is "all" {
  if (!recurringTarget) {
    throw new Error(
      "recurringTarget is required when editing a recurring event",
    )
  }
}

export const useEventActions = (
  calendarIdsFetchUpfront: string[],
  dateRange: {
    start: Date
    end: Date
  },
) => {
  const [calendarIdsForEventFetch, setCalendarIdsForEventFetch] = useState<
    string[]
  >(calendarIdsFetchUpfront)

  const [msEvents, setMsEvents] = useState<MsEventProps[]>([])
  const [loading, setLoading] = useState(false)

  const fetchEvents = useFetchEvents(
    calendarIdsForEventFetch,
    dateRange,
    setMsEvents,
  )
  const editEvents = useHandleEventEdit()
  const deleteEvent = useHandleDeleteEvent()

  const addCalendarIdsToFetch = (calendarIds: string[]) => {
    const hasNewCalendar = calendarIds.some(
      (calendarId) => !calendarIdsForEventFetch.includes(calendarId),
    )
    if (!hasNewCalendar) {
      return
    }
    const mergedListOfCalendars = [
      ...new Set([...calendarIdsForEventFetch, ...calendarIds]),
    ]

    setCalendarIdsForEventFetch(mergedListOfCalendars)
  }

  useEffect(() => {
    setLoading(true)
    fetchEvents().then(() => setLoading(false))
  }, [fetchEvents])

  useEffect(() => {
    let lastRefreshed = new Date()
    const interval = setInterval(async () => {
      if (
        new Date().getTime() - lastRefreshed.getTime() >
        AutoRefreshIntervalMS
      ) {
        await fetchEvents()
        lastRefreshed = new Date()
      }
    }, AutoRefreshIntervalMS)

    return () => clearInterval(interval)
  }, [fetchEvents])

  const handleEventAction = async (
    event: MsEventProps,
    index: number | null,
    recurringTarget?: "this" | "this-and-future" | "all",
  ) => {
    if (index === null) {
      await createNewEvent(event)
    } else {
      const oldEvent = msEvents[index]
      if (oldEvent.id !== event.id) {
        throw new Error("Event id mismatch")
      }

      await editEvents(
        {
          oldEvent,
          newEvent: event,
        },
        recurringTarget,
      )
    }
  }

  const onChange = async (
    event: MsEventProps,
    index: number | null,
    recurringTarget?: "this" | "this-and-future" | "all",
  ) => {
    await handleEventAction(event, index, recurringTarget)
    await fetchEvents()
  }

  const onDelete = async (
    event: MsEventProps,
    index: number,
    recurringTarget?: "this" | "this-and-future" | "all",
  ) => {
    const eventToDelete = msEvents[index]
    if (eventToDelete.id !== event.id) {
      throw new Error("Event id mismatch")
    }

    await deleteEvent(eventToDelete, recurringTarget)
    await fetchEvents()
  }

  return {
    msEvents,
    onChange,
    onDelete,
    addCalendarIdsToFetch,
    loading,
  }
}

async function updateOneTimeEvent(data: {
  oldEvent: MsEventProps
  newEvent: MsEventProps
}) {
  const editedProperties = getChangedFields(data.oldEvent, data.newEvent)
  return CalendarHubClient.edit(data.oldEvent.id, editedProperties)
}

async function updateRecurringEvent(
  data: { oldEvent: MsEventProps; newEvent: MsEventProps },
  recurringTarget: "this" | "this-and-future" | "all",
) {
  const target = {
    event_id: data.oldEvent.id,
    occurrence_start_time: data.oldEvent.start,
    recurring_group_id: data.oldEvent.recurringId,
    type: recurringTarget,
  }
  const editedProperties = getChangedFields(data.oldEvent, data.newEvent)
  return CalendarHubClient.editRecurring(target, editedProperties)
}

async function deleteRecurringEvent(
  event: MsEventProps,
  recurringTarget: "this" | "this-and-future" | "all",
) {
  return CalendarHubClient.deleteRecurring(
    event.id,
    event.start,
    event.recurringId || "",
    recurringTarget,
  )
}

async function createNewEvent(event: MsEventProps) {
  await CalendarHubClient.create(mapToMsCreateEventDto(event))
}

function getChangedFields(
  originalEventProps: MsEventProps,
  newEventProps: MsEventProps,
) {
  const originalEventDto = mapToMsCreateEventDto(originalEventProps)
  const newEventDto = mapToMsCreateEventDto(newEventProps)
  const changedFields = {} as Partial<MsEventEditableProperties>

  msEventEditableProperties.forEach((key) => {
    pushPropertyIfChanged(changedFields, key, originalEventDto, newEventDto)
  })

  const isRecurring = !!newEventProps.recurringRule
  if (isRecurring) {
    addTzidIfAndOnlyIfRRuleChanged(
      changedFields,
      newEventProps.recurringRuleTzid,
    )
  }

  return changedFields
}

function pushPropertyIfChanged<K extends keyof MsEventEditableProperties>(
  changedFields: Partial<MsEventEditableProperties>,
  key: K,
  originalEvent: CreateMsEventDto,
  newEvent: CreateMsEventDto,
) {
  const originalValue = originalEvent[key]
  const newValue = newEvent[key]

  switch (key) {
    case "participants":
      if (
        !arraysEqualsUnordered(
          originalEvent.participants,
          newEvent.participants,
        )
      ) {
        changedFields.participants = newEvent.participants
      }
      break
    case "start":
    case "end":
    case "rruleUntil":
      if (!isDatesEqual(originalValue, newValue)) {
        changedFields[key] = newEvent[key]
      }
      break
    default:
      if (originalValue !== newValue) {
        changedFields[key] = newEvent[key]
      }
  }
}

function arraysEqualsUnordered(a: string[], b: string[]) {
  return JSON.stringify(a.sort()) === JSON.stringify(b.sort())
}

function isDatesEqual(date1: any, date2: any) {
  const isBothDateInstances = date1 instanceof Date && date2 instanceof Date

  return isBothDateInstances
    ? date1.getTime() === date2.getTime()
    : date1 === date2
}

function addTzidIfAndOnlyIfRRuleChanged(
  changedFields: Partial<MsEventEditableProperties>,
  rruleTzid: string,
) {
  const hasRrule = !!changedFields.rrule
  const hasTimezone = !!changedFields.rruleTzid
  if (hasRrule && !hasTimezone) {
    changedFields.rruleTzid = rruleTzid
  }
  if (hasTimezone && !hasRrule) {
    // just a defensive code
    // if event created in israel and someone in germany edited it - we don't want to accidentally update the timezone.
    delete changedFields["rruleTzid"]
  }
}
