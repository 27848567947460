import { useAppSelector } from "@app/hooks"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import { Box, Stack } from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { TrackViewHeader } from "./trackViewHeader"
import { TrackContentView } from "./TrackContentView"
import { useEffect, useState } from "react"
import { TrackContentEditor } from "./track-editor/trackContentEditor"

export function TrackPage() {
  const { trackId } = useParams()
  const [searchParams, setURLSearchParams] = useSearchParams()
  const editModeFromParams = searchParams.get("edit")
  const [isEditMode, setIsEditMode] = useState(false)
  const track = useAppSelector((state) => selectTrackById(state, trackId || ""))

  useEffect(() => {
    if (editModeFromParams) {
      setIsEditMode(true)
    }
  }, [editModeFromParams])

  const onClickEdit = () => {
    setIsEditMode(true)
    setURLSearchParams({ edit: "true" })
  }

  if (!trackId) {
    return null
  }
  if (!track) {
    return <FullScreenLoadingComponent loadedComponents="" />
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "primary.contrast",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <TrackViewHeader
        track={track}
        isEditMode={isEditMode}
        onClickEdit={onClickEdit}
      />

      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
          pt: 6,
        }}
      >
        {isEditMode ? (
          <TrackContentEditor initialTrack={track} />
        ) : (
          <TrackContentView track={track} />
        )}
      </Box>
    </Stack>
  )
}
