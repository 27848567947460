import { useAppDispatch } from "@app/hooks"
import { ItemModel, OptionsButtonProps2 } from "@cmp/buttons/optionsButton2"
import ItemDescriptor from "@cmp/itemDescriptor"
import { Theme } from "@emotion/react"
import { elementMovedToTopic } from "@features/courseEditor/courseEditorSlice"
import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Survey,
  Test,
  Topic,
} from "@masterschool/course-builder-api"
import {
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  SxProps,
} from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { IconType } from "@utils/iconType"
import { elementDuration } from "@utils/syllabus+duration"
import { elementTypeDisplayName } from "@utils/syllabus+title"
import appTheme from "../theme/appTheme"
import useSelectElement from "./custom-hooks/useSelectElement"
import useEditElement from "./elements/custom-hooks/useEditElement"
import useOnDelete from "./elements/custom-hooks/useOnDelete"

function CourseElementDescriptor(props: {
  element: CourseSyllabusElement
  topic: Topic
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
  availableTopics?: Topic[]
}) {
  const dispatch = useAppDispatch()
  const removeElement = useOnDelete()
  const editElement = useEditElement()
  const selectElement = useSelectElement()

  const removeElementItem: ItemModel = {
    type: "menu-item",
    props: {
      key: "remove",
      children: (
        <>
          <ListItemIcon>
            <AppIcon icon={appIcons.trash03} />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </>
      ),
      onClick: () => {
        removeElement({
          topicId: props.topic.id,
          elementId: props.element.item.id,
        })
      },
    },
  }

  const hideElementItem: ItemModel = {
    type: "menu-item",
    props: {
      key: "hide",
      children: (
        <>
          <ListItemIcon>
            <AppIcon
              icon={
                props.element.item.isHidden ? appIcons.eye : appIcons.eyeOff
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={props.element.item.isHidden ? "Unhide" : "Hide"}
          />
        </>
      ),
      onClick: () => {
        editElement(
          {
            type: props.element.type,
            item: {
              ...props.element.item,
              isHidden: !props.element.item.isHidden,
            },
          },
          props.topic,
        )
      },
    },
  }

  const copyElementItem: ItemModel = {
    type: "menu-item",
    props: {
      key: "copy",
      children: (
        <>
          <ListItemIcon>
            <AppIcon icon={appIcons.copy04} />
          </ListItemIcon>
          <ListItemText primary={"Copy item"} />
        </>
      ),
      onClick: () => {
        window.navigator.clipboard.writeText(JSON.stringify(props.element))
      },
    },
  }

  const moveElementItem: ItemModel | undefined =
    props.availableTopics && props.availableTopics?.length > 0
      ? {
          type: "sub-menu",
          props: {
            key: "move",
            itemProps: {
              children: (
                <>
                  <ListItemIcon>
                    <AppIcon icon={appIcons.arrowRight} />
                  </ListItemIcon>
                  <ListItemText primary="Move to..." />
                </>
              ),
            },
            items: (props.availableTopics ?? []).map((topic) => {
              return {
                type: "menu-item",
                props: {
                  key: topic.id,
                  children: topic.title || (
                    <span
                      style={{
                        color: appTheme.palette.text.secondary,
                      }}
                    >
                      Untitled topic
                    </span>
                  ),
                  onClick: () => {
                    dispatch(
                      elementMovedToTopic({
                        editStepId: window.crypto.randomUUID(),
                        elementId: props.element.item.id,
                        sourceTopicId: props.topic.id,
                        targetTopicId: topic.id,
                      }),
                    )
                    selectElement(props.element.item.id)
                  },
                },
              }
            }),
          },
        }
      : undefined

  const optionsItems: ItemModel[] | undefined = props.editMode
    ? [
        removeElementItem,
        hideElementItem,
        copyElementItem,
        moveElementItem,
      ].msCompact()
    : undefined

  switch (props.element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.EmptyLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.NotionLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
    case CourseSyllabusElementTypeEnum.TypeformLesson:
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
    case CourseSyllabusElementTypeEnum.UdacityLesson:
    case CourseSyllabusElementTypeEnum.CodioLesson:
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return <LessonDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.EmptyProject:
    case CourseSyllabusElementTypeEnum.CodioProject:
    case CourseSyllabusElementTypeEnum.MasterschoolProject:
      return <ProjectDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.Survey:
      return <SurveyDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.Test:
      return <TestDescriptor {...props} optionsItems={optionsItems} />
    default:
      console.error(`Unsupported element type ${props.element.type}`)
  }
}

function LessonDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps2["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const { element } = props
  const typeName = elementTypeDisplayName(element.type)

  return (
    <ItemDescriptor
      {...props}
      title={element.item.title}
      subtitle={`Lesson ${typeName ? `· ${typeName}` : ""} · ${Math.round(
        elementDuration(props.element),
      )} min`}
      icon={appIcons.bookOpen01}
    />
  )
}

function ProjectDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps2["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const { element } = props
  const typeName = elementTypeDisplayName(element.type)

  return (
    <ItemDescriptor
      {...props}
      title={element.item.title}
      subtitle={`Project · ${typeName} · ${Math.round(
        elementDuration(props.element),
      )} min`}
      icon={appIcons.codeSquare01}
    />
  )
}

function SurveyDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps2["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  if (props.element.type !== CourseSyllabusElementTypeEnum.Survey) {
    throw new Error(`Expected Survey, got ${props.element.type}`)
  }

  const survey = props.element.item as Survey

  return (
    <ItemDescriptor
      {...props}
      title={survey.title}
      subtitle={`Survey · ${survey.estimatedDuration} min`}
      icon={appIcons.star01}
    />
  )
}

function TestDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps2["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  if (props.element.type !== CourseSyllabusElementTypeEnum.Test) {
    throw new Error(`Expected Test, got ${props.element.type}`)
  }

  const test = props.element.item as Test
  const provider = capitalizeString((props.element.item as Test).provider)

  return (
    <ItemDescriptor
      {...props}
      title={test.title}
      subtitle={`Quiz · ${provider} · ${test.estimatedDuration} min`}
      icon={appIcons.file02}
    />
  )
}

function capitalizeString(str: string | undefined) {
  if (!str) {
    return undefined
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default CourseElementDescriptor
