import { useAppSelector } from "@app/hooks"
import { selectLatestPublishedCoursesByIds } from "@features/coursesMenu/coursesSelectors"
import {
  CourseDescriptorDto,
  TermDto,
  TrackDto,
} from "@masterschool/course-builder-api"
import { Stack, Typography } from "@mui/material"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"

export const LiveSessionHours = (props: { hours: string }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <AppIcon
        icon={appIcons.playCircle}
        props={{ sx: { width: "16px", height: "16px" } }}
      />
      <Typography variant="body3" color="textSecondary">
        {`${props.hours}h`}
      </Typography>
    </Stack>
  )
}

export const SelfLearningHours = (props: { hours: string }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <AppIcon
        icon={appIcons.bookOpen01}
        props={{ sx: { width: "16px", height: "16px" } }}
      />
      <Typography variant="body3" color="textSecondary">
        {`${props.hours}h`}
      </Typography>
    </Stack>
  )
}

export const useGetTrackHours = (track: TrackDto) => {
  const courseDescriptorsIds = track.terms.flatMap((term) =>
    term.courseDescriptors.map((cd) => cd.courseId),
  )
  const courseDescriptors = useAppSelector(
    selectLatestPublishedCoursesByIds(courseDescriptorsIds),
  )
  return sumCoursesHours(courseDescriptors)
}

export const useGetTermHours = (term: TermDto) => {
  const courseDescriptors = useAppSelector(
    selectLatestPublishedCoursesByIds(
      term.courseDescriptors.map((cd) => cd.courseId),
    ),
  )
  return sumCoursesHours(courseDescriptors)
}

export function sumCoursesHours(courseDescriptors: CourseDescriptorDto[]) {
  const liveEventsDuration = courseDescriptors.reduce(
    (acc, cd) => acc + cd.liveEventsDuration,
    0,
  )
  const elementsDuration = courseDescriptors.reduce(
    (acc, cd) => acc + cd.elementsDuration,
    0,
  )
  return {
    liveSessionHours: (liveEventsDuration / 60).toFixed(2),
    selfLearningHours: (elementsDuration / 60).toFixed(2),
  }
}
