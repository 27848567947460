import { SortItemsButton } from "@cmp/buttons/sortItemsButton"
import PlusIcon from "@mui/icons-material/Add"
import { TabList } from "@mui/lab"
import TabContext from "@mui/lab/TabContext"
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  TabIdentifierEnumeration,
  createLegacyCourse,
  createTrackCourse,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
} from "@features/coursesMenu/coursesMenuSlice"
import {
  selectCoursesFetchingStatus,
  selectCoursesSortOption,
  selectCoursesType,
} from "@features/coursesMenu/coursesSelectors"
import {
  CourseSearchComponent,
  CourseTypeFilter,
} from "./courseSearchComponent"
import { CoursesDescriptorsContainer } from "./coursesDescriptorsContainer"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { ErrorComponent } from "@cmp/loaders/errorComponent"
import { CourseType, ProgramDomain } from "@masterschool/course-builder-api"
import { useState } from "react"
import GenericDialog from "@cmp/genericDialog"
import { domainToDisplayName } from "@utils/domainUtils"
import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"
import { CoursesTable } from "./courses-table/course-table/coursesTable"

export function CoursesMainPage() {
  const dispatch = useAppDispatch()
  const loadingStatus = useAppSelector(selectCoursesFetchingStatus)

  const sortOption = useAppSelector(selectCoursesSortOption)

  const tab = useAppSelector((state) => state.coursesMenu.tab)

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
          }}
        >
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              width: "100%",
              mb: "16px",
            }}
          >
            <Typography variant="h4">Course builder</Typography>
            {loadingStatus === "loading" && (
              <LoadingComponent loadedComponents="courses" />
            )}
            {loadingStatus === "error" && (
              <ErrorComponent loadedComponents="courses" />
            )}
            <CourseTypeFilter />
          </Box>
          <CourseSearchComponent />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "32px",
            }}
          >
            <TabContext value={tab}>
              <TabList
                onChange={(event, value) => {
                  dispatch(tabSelected(value))
                }}
              >
                <Tab
                  label={TabIdentifierEnumeration.All}
                  value={TabIdentifierEnumeration.All}
                />
                <Tab
                  label={TabIdentifierEnumeration.Published}
                  value={TabIdentifierEnumeration.Published}
                />
                <Tab label={"Drafts"} value={TabIdentifierEnumeration.Draft} />
                <Tab
                  label={TabIdentifierEnumeration.Favorites}
                  value={TabIdentifierEnumeration.Favorites}
                />
                <Tab
                  label={TabIdentifierEnumeration.Archived}
                  value={TabIdentifierEnumeration.Archived}
                />
              </TabList>
            </TabContext>
            <Box display="flex" gap="8px" alignItems="center">
              <SortItemsButton
                sortOption={sortOption}
                chronologicalClicked={(sortOrder) =>
                  dispatch(sortChronologicallyClicked(sortOrder))
                }
                alphabeticClicked={(sortOrder) =>
                  dispatch(sortAlphabeticallyClicked(sortOrder))
                }
              />
              <AddCourseButton />
            </Box>
          </Box>
        </Box>
        <CourseDescriptorsView />
      </Box>
    </Box>
  )
}

const CourseDescriptorsView = () => {
  const courseType = useAppSelector(selectCoursesType)
  const showTrackFeature = useAppSelector(selectShowTrackFeature)
  if (courseType === CourseType.Track && showTrackFeature) {
    return <CoursesTable />
  }
  return <CoursesDescriptorsContainer />
}

const AddCourseButton = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const courseType = useAppSelector(selectCoursesType)
  const [showCreateTrackCoursePopup, setShowCreateTrackCoursePopup] =
    useState(false)

  const onButtonClicked = () => {
    if (courseType === CourseType.Legacy) {
      return dispatch(createLegacyCourse()).then((result) => {
        if (!result.payload) return
        navigate(`/course-edit/${result.payload.id}`)
      })
    } else {
      setShowCreateTrackCoursePopup(true)
    }
  }
  return (
    <>
      {showCreateTrackCoursePopup && (
        <AddTrackCoursePopup
          onClose={() => setShowCreateTrackCoursePopup(false)}
        />
      )}
      <Button
        variant="contained"
        startIcon={<PlusIcon />}
        onClick={onButtonClicked}
        size="small"
      >
        Add course
      </Button>
    </>
  )
}

const AddTrackCoursePopup = (props: { onClose: () => void }) => {
  const { onClose } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [displayName, setDisplayName] = useState("")
  const [internalName, setInternalName] = useState("")
  const [domains, setDomains] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    setIsLoading(true)
    return dispatch(
      createTrackCourse({
        displayName,
        internalName,
        domains,
      }),
    ).then((result) => {
      if (!result.payload) return
      navigate(`/course-edit/${result.payload.id}`)
    })
  }
  const domainOptions = Object.values(ProgramDomain)

  return (
    <GenericDialog
      title="Add track course"
      onClose={onClose}
      size="xs"
      content={
        <Stack gap={2}>
          <TextField
            label="Display name"
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value)
            }}
          ></TextField>
          <TextField
            label="Internal name"
            value={internalName}
            onChange={(e) => {
              setInternalName(e.target.value)
            }}
          ></TextField>
          <Autocomplete
            options={domainOptions}
            value={domains[0]}
            onChange={(_, newValue) => setDomains([newValue as string])}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="School" />
            )}
            renderOption={(props, domain) => (
              <li {...props} key={domain}>
                {domainToDisplayName(domain)}
              </li>
            )}
            getOptionLabel={domainToDisplayName}
          />
        </Stack>
      }
      buttons={[
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
        {
          loading: isLoading,
          text: "Add new course",
          type: "primary",
          onClick: onSubmit,
        },
      ]}
      open={true}
    />
  )
}
