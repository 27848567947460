import { CampusClient } from "@clients/campusClient"
import { UserDto } from "@masterschool/course-builder-api"

export class EmailAlreadyTakenError extends Error {}
export type CampusUserDto = Omit<UserDto, "id"> & { clientId: string }

export class UsersClient {
  static getUsersWithRoles(requiredRoles?: string[]): Promise<UserDto[]> {
    let endpoint = `/user/employees`
    if (requiredRoles) {
      endpoint += `?role=${requiredRoles.join("&role=")}`
    }
    return CampusClient.get<UserDto[]>(endpoint).catch(() => {
      return [] as UserDto[]
    })
  }

  static generateImpersonationLink(userEmail: string): Promise<string> {
    return CampusClient.post<{ link: string }>("/generate-login-link", {
      email: userEmail,
    }).then((res) => {
      return res.link
    })
  }

  static editUserName(
    targetUserClientId: string,
    newDetails: Partial<Pick<UserDto, "firstName" | "lastName">>,
  ): Promise<UserDto> {
    return CampusClient.put<UserDto>(`/user/${targetUserClientId}`, newDetails)
  }

  static editUserEmail(
    targetUserClientId: string,
    newEmail: string,
  ): Promise<UserDto> {
    return CampusClient.put<UserDto>(`/user/${targetUserClientId}/email`, {
      email: newEmail,
    }).catch((err) => {
      if (err.statusCode === 409) {
        throw new EmailAlreadyTakenError()
      }
      throw new Error()
    })
  }

  static findUserById(userId: string): Promise<UserDto> {
    return CampusClient.get<UserDto>(`/user/find?id=${userId}`)
  }

  static searchUser(searchValue: string): Promise<CampusUserDto[]> {
    return CampusClient.get<CampusUserDto[]>(
      `/user/search?email=${searchValue}&name=${searchValue}`,
    )
  }
}
