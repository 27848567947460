import { GeneralNavigationSideBar } from "@cmp/sidebar/generalNavigationSidebar"
import { Typography } from "@mui/material"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import { useParams } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import { useEffect } from "react"

export enum ProgramTabIdentifier {
  Home = "home",
  Syllabus = "syllabus",
  Students = "students",
  Staff = "staff",
  Slack = "slack",
  Projects = "projects",
  Classes = "classes",
}

function ProgramSidebar() {
  const { classId } = useParams()
  const [tab, setTab] = useSpecificSearchParam("tab")
  const showClassesTab = classId === undefined
  const showStaffTab = classId === undefined

  const tabsIdentifiers = [
    ProgramTabIdentifier.Home,
    ProgramTabIdentifier.Syllabus,
    ProgramTabIdentifier.Students,
    ProgramTabIdentifier.Staff,
    ProgramTabIdentifier.Slack,
    ProgramTabIdentifier.Projects,
    ProgramTabIdentifier.Classes,
  ]
    .filter((tab) => tab !== ProgramTabIdentifier.Classes || showClassesTab)
    .filter((tab) => tab !== ProgramTabIdentifier.Staff || showStaffTab)

  const selectedTab =
    tab && tabsIdentifiers.includes(tab as ProgramTabIdentifier)
      ? tab
      : ProgramTabIdentifier.Home

  const tabs = tabsIdentifiers.map((tab) => (
    <Typography
      key={tab}
      variant={tab === selectedTab ? "body2_sb" : "body2"}
      sx={{
        display: "block",
        borderRadius: "8px",
        bgcolor: tab === selectedTab ? appTheme.palette.eTypes.sand50 : "none",
        cursor: "pointer",
        padding: "8px 16px",
        textTransform: "capitalize",
      }}
      onClick={() => setTab(tab)}
    >
      {tab}
    </Typography>
  ))

  useEffect(() => {
    if (tab && !tabsIdentifiers.includes(tab as ProgramTabIdentifier)) {
      setTab(ProgramTabIdentifier.Home)
    }
  }, [classId, tab, setTab, tabsIdentifiers])

  return (
    <GeneralNavigationSideBar
      tabs={tabs}
      sx={{
        width: "216px",
        maxWidth: "216px",
        minWidth: "216px",
        minHeight: "100%",
        padding: "16px 24px",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
      listSx={{
        pt: "16px",
      }}
    />
  )
}

export default ProgramSidebar
