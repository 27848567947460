import { memo, useState } from "react"
import {
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { createPortal } from "react-dom"

type Item = {
  element: JSX.Element
  id: string
}

export type DraggableListProps = {
  id: string
  items: Item[]
  onDragStart: (event: DragStartEvent) => void
  onDragEnd: (event: DragEndEvent) => void
  onDragCancel: (event: DragCancelEvent) => void
  onDragOver: (event: DragOverEvent) => void
}

const DraggableList = memo(
  ({
    id,
    items,
    onDragStart,
    onDragEnd,
    onDragCancel,
    onDragOver,
  }: DraggableListProps) => {
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 8,
        },
      }),
    )
    const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>(
      undefined,
    )

    return (
      <DndContext
        collisionDetection={rectIntersection}
        id={id}
        sensors={sensors}
        onDragStart={(event) => {
          setActiveId(event.active.id)
          onDragStart(event)
        }}
        onDragEnd={(event) => {
          setActiveId(undefined)
          onDragEnd(event)
        }}
        onDragCancel={(event) => {
          setActiveId(undefined)
          onDragCancel(event)
        }}
        onDragOver={onDragOver}
        autoScroll={false}
      >
        <SortableContext
          id={id}
          items={items.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {items.map((item) => (
            <SortableItem key={item.id} item={item.element} id={item.id} />
          ))}
        </SortableContext>

        {createPortal(
          <DragOverlay>
            {activeId
              ? items.find((item) => item.id === activeId)?.element
              : null}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    )
  },
)

export type SortableItemProps = {
  item: JSX.Element
  id: string
}

const SortableItem = ({ item, id }: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id })

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  }

  return (
    <div
      style={{
        ...itemStyle,
        opacity: isDragging ? 0 : 1,
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {item}
    </div>
  )
}

export default DraggableList
